import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "./ListItem";
import List from "@material-ui/core/List";
import styles from "../../assets/jss/components/headerStyle";
import { useTranslation } from "react-i18next";
import ForumIcon from "@material-ui/icons/Forum";
import Brightness4Icon from "@material-ui/icons/Brightness4";
import TwitterIcon from "@material-ui/icons/Twitter";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { ClanContext } from "../../contexts/clanContext";
import { ThemeContext } from "../../contexts/themeContext";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ListItemDefault from "@material-ui/core/ListItem";

export default function Social({ baseUrl }) {
  const { clan } = useContext(ClanContext);
  const { mode, toggleDarkTheme } = useContext(ThemeContext);
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <List className={classes.list}>
      <ListItemDefault button onClick={handleClick}>
        <ListItemText primary={t("header.sidebar.social")} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemDefault>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          {clan.forumUrl && (
            <ListItem
              to={{ pathname: clan.forumUrl }}
              target='_blank'
              text={t("header.sidebar.forums")}
              Icon={ForumIcon}
            />
          )}
          {clan.twitterUrl && (
            <ListItem
              to={{ pathname: clan.twitterUrl }}
              target='_blank'
              text={t("header.sidebar.twitter")}
              Icon={TwitterIcon}
            />
          )}
          {clan.discordUrl && (
            <ListItem
              to={{ pathname: clan.discordUrl }}
              target='_blank'
              text={t("header.sidebar.discord")}
              Icon={() => (
                <i
                  className={`${classes.socialIcons} fab fa-discord`}
                  style={{ paddingLeft: "3px" }}
                />
              )}
            />
          )}
          {clan.id && (
            <ListItem
              to={`${baseUrl}/donate`}
              text={t("header.sidebar.donate")}
              Icon={FavoriteIcon}
            />
          )}
          <ListItem
            onClick={() => toggleDarkTheme()}
            text={`${mode === "dark" ? "Light" : "Dark"} ${t(
              "header.sidebar.mode"
            )}`}
            Icon={Brightness4Icon}
          />
        </List>
      </Collapse>
    </List>
  );
}
