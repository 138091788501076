/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect, useContext, useState } from "react";
import Card from "@material-ui/core/Card";
import { CardContent, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { SubmissionContext } from "../../contexts/submissionContext";

export default function ApplicationResponses({ record }) {
  const { fetchResponses } = useContext(SubmissionContext);
  const [responses, setResponses] = useState(undefined);
  const useStyles = makeStyles(() => ({
    pieCard: {
      display: "flex",
    },
  }));

  const classes = useStyles();
  const validURL = (str) => {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  };

  useEffect(() => {
    const loadResponses = async () =>
      setResponses(await fetchResponses(record.id));
    if (responses === undefined && record && record.id) {
      loadResponses(record.id);
    }
  }, [responses, record, fetchResponses]);

  return (
    <Card className={classes.pieCard}>
      <CardContent style={{ width: "100%" }}>
        {record && (
          <Grid container>
            <Grid item xs={12}>
              <Typography variant='h6'>
                {responses && responses.length > 0
                  ? "Application"
                  : "No application"}
              </Typography>
            </Grid>
            {responses &&
              responses.map((response) => (
                <>
                  <Grid item xs={12} key={response.id}>
                    <Typography variant='body2'>
                      {response.question.question}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} key={response.id}>
                    <Typography
                      variant='body2'
                      style={{ width: "100%", wordBreak: "break-all" }}
                    >
                      {validURL(response.response) ? (
                        <a href={response.response}>{response.response}</a>
                      ) : (
                        <b>{response.response}</b>
                      )}
                    </Typography>
                  </Grid>
                </>
              ))}
          </Grid>
        )}
      </CardContent>
    </Card>
  );
}
