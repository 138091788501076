import React, { useContext, FunctionComponent, useEffect } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { useTranslation } from "react-i18next";
import ItemCard from "../../components/Items/ItemCard";
import VerticalTabs from "../../components/VerticalTabs/VerticalTabs";
import Layout from "../../components/Layout/Layout";
import { ClanContext } from "../../contexts/clanContext";
import { SubmissionContext } from "../../contexts/submissionContext";

const Items: FunctionComponent = () => {
  const { clan } = useContext(ClanContext);
  const { bossesFetched, bossesWithDrops, fetchAllBosses } = useContext(SubmissionContext);
  const [image, setImage] = React.useState(clan.clanBackgroundImage);
  const { t } = useTranslation();

  useEffect(() => {
    if (clan && clan.id && !bossesFetched) {
      fetchAllBosses();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bossesFetched]);

  return (
    <Layout
      loading={!bossesFetched}
      title={t("items.pageTitle")}
      background={image}
    >
      <VerticalTabs
        headerStyle={{ maxHeight: "2000px" }}
        tabs={bossesWithDrops
          .map((boss) => ({
            label: boss.name,
            onClick: () => setImage(boss.artwork),
            component: (
              <List>
                {boss.drops
                  .map((drop) => (
                    <ListItem key={drop.id}>
                      <ItemCard item={drop} />
                    </ListItem>
                  ))}
              </List>
            ),
          }))}
      />
    </Layout>
  );
};

export default Items;
