import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from 'react-i18next';

const CollectionLog = ({ boss, drops, bosses, tanks }) => {
  const { t } = useTranslation();
  const useStyles = makeStyles(() => ({
    media: {
      height: 45,
      backgroundSize: "contain",
      margin: "5px",
    },
  }));

  const classes = useStyles();

  return boss ? (
    <Grid container style={{ paddingLeft: "1rem" }} spacing={3}>
      {boss.drops
        .map((drop) => (
          <Grid key={drop.id} item xs={6} sm={4} md={2}>
            <Card
              style={{
                filter: `grayscale(${drops
                  ? drops.filter((x) => x.Item_id === drop.id).length > 0
                    ? 0
                    : 1
                  : 1
                  })`,
              }}
            >
              <CardMedia
                className={classes.media}
                image={drop.artwork}
                title={drop.name}
              />

              <Grid container>
                <Grid container item xs={12} justify="center">
                  <Typography variant="caption">
                    {t('lookup.collectionLog.received')}:{" "}
                    <strong style={{ textAlign: "center" }}>
                      {drops
                        ? drops.filter(
                          (x) => x.approved && x.Item_id === drop.id
                        ).length
                        : 0}
                    </strong>
                  </Typography>
                </Grid>
                <Grid container item xs={12} justify="center">
                  <Typography variant="caption">
                    {t('lookup.collectionLog.tanked')}:{" "}
                    <strong style={{ textAlign: "center" }}>
                      {tanks
                        ? tanks.filter(
                          (x) => x.approved && x.Item_id === drop.id
                        ).length
                        : 0}
                    </strong>
                  </Typography>
                </Grid>
                <Grid container item xs={12} justify="center">
                  <Typography variant="caption">
                    {t('lookup.collectionLog.points')}:{" "}
                    <strong style={{ textAlign: "center" }}>
                      {drops
                        ? drops
                          .filter((x) => x.approved && x.Item_id === drop.id)
                          .reduce(
                            (t, d) => Number(t) + Number(d.pointsAttack),
                            0
                          ) +
                        (tanks
                          ? tanks
                            .filter(
                              (x) => x.approved && x.Item_id === drop.id
                            )
                            .reduce(
                              (t, d) => Number(t) + Number(d.pointsTank),
                              0
                            )
                          : 0)
                        : 0}
                    </strong>
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        ))}
    </Grid>
  ) : (
    <Grid container style={{ paddingLeft: "1rem" }} spacing={3}>
      {bosses
        .flat()
        .filter(
          (thing, index, self) =>
            self.findIndex(
              (t) =>
                t.name === thing.name &&
                thing.id !== t.id &&
                t.Boss_id > thing.Boss_id
            ) === -1
        )
        .map((drop) => (
          <Grid key={drop.id} item xs={6} sm={4} md={2}>
            <Card
              style={{
                filter: `grayscale(${drops
                  ? drops.filter((x) => x.items.name === drop.name).length > 0
                    ? 0
                    : 1
                  : 1
                  })`,
              }}
            >
              <CardMedia
                className={classes.media}
                image={drop.artwork}
                title={drop.name}
              />

              <Grid container>
                <Grid container item xs={12} justify="center">
                  <Typography variant="caption">
                    {t('lookup.collectionLog.received')}:{" "}
                    <strong style={{ textAlign: "center" }}>
                      {drops
                        ? drops.filter(
                          (x) => x.approved && x.items.name === drop.name
                        ).length
                        : 0}
                    </strong>
                  </Typography>
                </Grid>
                <Grid container item xs={12} justify="center">
                  <Typography variant="caption">
                    {t('lookup.collectionLog.tanked')}:{" "}
                    <strong style={{ textAlign: "center" }}>
                      {tanks
                        ? tanks.filter(
                          (x) => x.approved && x.items.name === drop.name
                        ).length
                        : 0}
                    </strong>
                  </Typography>
                </Grid>
                <Grid container item xs={12} justify="center">
                  <Typography variant="caption">
                    {t('lookup.collectionLog.points')}:{" "}
                    <strong style={{ textAlign: "center" }}>
                      {drops
                        ? drops
                          .filter(
                            (x) => x.approved && x.items.name === drop.name
                          )
                          .reduce(
                            (t, d) => Number(t) + Number(d.pointsAttack),
                            0
                          ) +
                        (tanks
                          ? tanks
                            .filter(
                              (x) => x.approved && x.Item_id === drop.id
                            )
                            .reduce(
                              (t, d) => Number(t) + Number(d.pointsTank),
                              0
                            )
                          : 0)
                        : 0}
                    </strong>
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        ))}
    </Grid>
  );
};

export default CollectionLog;
