import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/components/headerStyle";
import { SubmissionContext } from "../../contexts/submissionContext";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router-dom";

export default function Search({ baseUrl, onChange }) {
    const useStyles = makeStyles(styles);
    const classes = useStyles();
    const { members, fetchMembers } = useContext(SubmissionContext);
    const [member, setMember] = useState({})
    const history = useHistory();

    return (
        <div className={classes.search}>
            <Autocomplete
                options={members}
                autoHighlight
                value={member}
                getOptionLabel={(option) => option.username || ""}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        fullWidth
                        placeholder={"Find a member..."}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                )}
                onChange={(event, member) => {
                    setMember(member)
                    if (member) {
                        history.push(`${baseUrl}/lookup/${member.username}`)
                    }
                    if (onChange) {
                        onChange()
                    }
                }}
                onInputChange={(event, value) => {
                    fetchMembers(value);
                }}
            />
        </div>
    )

}
