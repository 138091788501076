import React, { useContext } from "react";
import Link from "@material-ui/core/Link";
import VerticalTabs from "../VerticalTabs/VerticalTabs";
import Hiscore from "./Hiscores";
import { SubmissionContext } from "../../contexts/submissionContext";

const fmtMSS = (s) => (s - (s %= 60)) / 60 + (s > 9 ? ":" : ":0") + s;

const Time = ({ updateImage }) => {
  const { records, bosses } = useContext(SubmissionContext);

  const tabs = [];
  bosses.forEach((boss) => {
    records.forEach((record) => {
      if (record.Boss_id === boss.id) {
        console.log(`${boss.name} - ${record.record}`);
        tabs.push({
          label: `${boss.name} - ${record.record}`,
          onClick: () => updateImage(boss.artwork),
          component: (
            <Hiscore
              times
              key={record.id}
              label={`${boss.name} - ${record.record}`}
              headers={["Usernames", "Time"]}
              valueLabel='Time'
              page={5}
              query={{ Record_id: record.id }}
              valueAttribute={(x) => (
                <Link href={x.screenshot}>{fmtMSS(x.value)}</Link>
              )}
              usernameAttribute={(x) =>
                x.members &&
                x.members
                  .map((member) => member.username)
                  .sort()
                  .join(", ")
              }
            />
          ),
        });
      }
    });
  });
  console.log(tabs);
  return <VerticalTabs tabs={tabs} />;
};

export default Time;
