import React, { useState, createContext, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import API from "../helpers/api";
import { ThemeContext } from "./themeContext";
import { Member } from "../models";
import { useRouteMatch } from "react-router-dom";

const UserContext = createContext({
  user: {} as Member,
  setUser: (user: Member) => { },
  alts: [] as Array<Member>,
  submitted: false as boolean,
  userFetched: false as boolean,
  noClan: false as boolean,
  login: (username: string, pin: string) => { },
  logout: () => { },
  register: (username: string, pin: string, pinConfirm: string) => { },
  apply: (application: { [key: string]: any }) => { },
  addAlt: (alt: Member) => { },
  fetchLoggedInUser: () => { },
});

const UserContextConsumer = UserContext.Consumer;

interface UserContextProviderProps {
  children: JSX.Element;
}

const UserContextProvider = (props: UserContextProviderProps): JSX.Element => {
  const route = useRouteMatch("/:clanSlug");
  const { t } = useTranslation();
  const { setState } = useContext(ThemeContext);
  const { children } = props;
  const [user, setUser] = useState<Member>({});
  const [userFetched, setUserFetched] = useState(false);
  const [noClan, setNoClan] = useState(false);
  const [alts, setAlts] = useState<Array<Member>>([]);
  const [submitted, setSubmitted] = useState(false);
  const fetchAlts = async (id: number) =>
    setAlts((await API.fetchAlts(id)) || []);

  const login = async (username: string, pin: string) => {
    try {
      const response = await API.login(username, pin);
      if (response.status === 200) {
        setUser(response.data.user);
      } else {
        setUser({});
        if (
          response.response &&
          response.response.data &&
          response.response.data.failed
        ) {
          setState({ open: true, message: response.response.data.failed });
        }
      }
    } catch {
      setUser({});
    }
  };

  const logout = async () => {
    try {
      await API.logout().then(() => setUser({}));
    } catch {
      setUser({});
    }
  };

  const register = async (
    username: string,
    pin: string,
    pinConfirm: string
  ) => {
    try {
      if (username === "") {
        setState({
          open: true,
          message: "Please enter your in-game username.",
        });
      } else if (pin !== pinConfirm) {
        setState({ open: true, message: "Pins do not match." });
      } else {
        let response = await API.register(username, pin);

        if (response.status === 200) {
          response = await API.login(username, pin);

          if (response.status === 200) {
            setUser(response.user);
          }
          setState({
            open: true,
            message:
              "Successfully set pin! Please login with your newly set pin!",
          });
        } else if (
          response.response &&
          response.response.data &&
          response.response.data.usernameNotFound
        ) {
          setState({
            open: true,
            message: "In-game username not found! Are you in the clan?",
          });
        } else if (
          response.response &&
          response.response.data &&
          response.response.data.usernameSet
        ) {
          setState({
            open: true,
            message: "In-game username already registered! Try logging in!",
          });
        } else {
          setState({ open: true, message: "Incorrect password" });
        }
      }
    } catch {
      setUser({});
    }
  };

  const apply = async (application: { [key: string]: any }) => {
    try {
      API.create("Members", {
        username: application.username,
        timezone: application.timezone,
        Clan_id: application.Clan_id,
        joinDate: new Date().toISOString(),
        ironman: application.ironman,
        hardcoreIronman: application.hardcoreIronman,
        ultimateIronman: application.ultimateIronman,
        normal: application.normal,
        application: application,
      }).then(() => {
        setSubmitted(true);
      });
    } catch {
      setUser({});
    }
  };

  const addAlt = async (alt: Member) => {
    try {
      API.create("Members", alt).then(() => {
        setState({
          open: true,
          message: t("alts.addAlt.altSubmittedMessage"),
        });
      });
    } catch {
      setState({
        open: true,
        message: t("alts.addAlt.altSubmittedFailedMessage"),
      });
    }
  };

  const fetchLoggedInUser = async () => {
    try {
      setUser(await API.fetchLoggedInUser());
    } catch {
      setUser({});
    }
  };

  useEffect(() => {
    async function fetchUser() {
      try {
        setUser(await API.fetchLoggedInUser());
        setUserFetched(true);
      } catch {
        setUser({});
        setUserFetched(true);
      }
    }
    if (process.env.REACT_APP_STANDALONE_ID || route?.params?.clanSlug) {
      if (!userFetched && !user.id) {
        fetchUser();
      }
      if (user?.id && userFetched) {
        fetchAlts(user?.id);
      }
    } else {
      setNoClan(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        login,
        logout,
        register,
        apply,
        submitted,
        alts,
        addAlt,
        fetchLoggedInUser,
        userFetched,
        noClan
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserContextProvider, UserContextConsumer, UserContext };
