import React, { useContext, useEffect } from "react";
import jsonServerProvider from "ra-data-json-server";
import { Admin, HttpError } from "react-admin";
import { createBrowserHistory } from "history";
import { UserContext } from "../../contexts/userContext";
import { Layout } from "react-admin";
import Header from "../../components/Header/Header";
import ClanAdminRoutes from "../../components/Admin/roles/ClanAdmin";
import AdminRoutes from "../../components/Admin/roles/Admin";
import EventStaffRoutes from "../../components/Admin/roles/EventStaff";
import ClanMentorRoutes from "../../components/Admin/roles/ClanMentor";
import ClanMentorEventRoles from "../../components/Admin/roles/ClanMentorEvent";
import Dashboard from "../../components/Admin/Dashboard";
import { ThemeContext } from "../../contexts/themeContext";
import { ClanContext } from "../../contexts/clanContext";

const getFetchJson = (fetchMethod) => {
  const fetchJson = async (url: string, options = {}) => {
    if (!options.headers) {
      options.headers = new Headers({ Accept: "application/json" });
    }
    options.credentials = "include";
    options.headers.set("Content-Type", "application/json");
    options.withCredentials = true;

    const response = await fetch(url, { ...options });
    const text = await response.text();
    const o = {
      status: response.status,
      statusText: response.statusText,
      headers: response.headers,
      body: text,
    };
    const { status } = o;
    const { statusText } = o;
    const { headers } = o;
    const { body } = o;
    let json;
    try {
      json = JSON.parse(body);
    } catch (e) {
      // not json, no big deal
    }
    if (status < 200 || status >= 300) {
      if (status === 401 || status === 403) {
        await fetchMethod();

        const response = await fetch(url, { ...options });
        const text = await response.text();
        const o = {
          status: response.status,
          statusText: response.statusText,
          headers: response.headers,
          body: text,
        };
        const { status } = o;
        const { statusText } = o;
        const { headers } = o;
        const { body } = o;
        let json;
        try {
          json = JSON.parse(body);
        } catch (e) {
          // not json, no big deal
        }
        if (status < 200 || status >= 300) {
          return Promise.reject(
            new HttpError(
              (json && json.error && json.error.detail) || statusText,
              status,
              json
            )
          );
        }
        return Promise.resolve({
          status,
          headers,
          body,
          json,
        });
      } else {
        return Promise.reject(
          new HttpError(
            (json && json.error && json.error.detail) || statusText,
            status,
            json
          )
        );
      }
    } else {
      return Promise.resolve({
        status,
        headers,
        body,
        json,
      });
    }
  };
  return fetchJson;
};

const AdminDashboard = () => {
  const { theme, setLoading } = React.useContext(ThemeContext);
  const { user, userFetched, fetchLoggedInUser } = useContext(UserContext);
  const { clanFetched, clan } = useContext(ClanContext);
  const baseUrl =
    !process.env.REACT_APP_STANDALONE_ID && clan?.id ? `/${clan.slug}` : "";
  const history = createBrowserHistory({ basename: `${baseUrl}/admin` });
  const dataProvider = jsonServerProvider(
    process.env.REACT_APP_API_URL,
    getFetchJson(fetchLoggedInUser)
  );
  const authProvider = {
    login: () => Promise.resolve(),
    logout: () => Promise.resolve(),
    getPermissions: () => {
      if (user && clan && user.Clan_id === clan.id) {
        if (user.isClanAdmin) {
          return Promise.resolve("clan_admin");
        } else if (user.isAdmin) {
          return Promise.resolve("admin");
        } else if (user.isEventStaff || user.teacherPrograms.length > 0) {
          if (user.isEventStaff && user.teacherPrograms.length > 0) {
            return Promise.resolve("mentor_event_staff");
          } else if (user.isEventStaff) {
            return Promise.resolve("event_staff");
          } else {
            return Promise.resolve("mentor_staff");
          }
        } else {
          return Promise.reject();
        }
      } else {
        return Promise.reject();
      }
    },
    checkAuth: () => Promise.resolve(),
    checkError: ({ status }) => {
      return Promise.resolve();
    },
  };

  useEffect(() => {
    setLoading(!user && !clanFetched && !userFetched);
  }, [clanFetched, setLoading, user, userFetched]);

  return user && clanFetched && userFetched ? (
    <div style={{ marginLeft: "12px", marginTop: "-36px" }}>
      <Admin
        dashboard={(rest) => <Dashboard {...rest} user={user} />}
        dataProvider={dataProvider}
        authProvider={authProvider}
        layout={(rest) => (
          <Layout
            {...rest}
            appBar={(props) => <Header {...props} fixed />}
            sidebar={(props) => <div />}
            theme={theme}
          />
        )}
        history={history}
      >
        {(permissions) => [
          permissions === "admin"
            ? AdminRoutes(clan)
            : permissions === "clan_admin"
            ? ClanAdminRoutes(clan)
            : permissions === "event_staff"
            ? EventStaffRoutes(clan)
            : permissions === "mentor_staff"
            ? ClanMentorRoutes(clan, user.teacherPrograms)
            : permissions === "mentor_event_staff"
            ? ClanMentorEventRoles(clan, user.teacherPrograms)
            : null,
        ]}
      </Admin>
    </div>
  ) : (
    <div />
  );
};

export default AdminDashboard;
