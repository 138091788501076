import React, { useContext, useEffect } from "react";
import Card from "@material-ui/core/Card";
import { CardContent } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import Switch from "@material-ui/core/Switch";
import BossChart from "./Boss";
import { useTranslation } from "react-i18next";
import { SubmissionContext } from "../../contexts/submissionContext";

export default function BossCard({
  alts,
  setBackground = null,
  member,
  killcounts,
}) {
  const { t } = useTranslation();
  const { bosses } = useContext(SubmissionContext);
  const [combineAlts, setCombineAlts] = React.useState(false);
  const [favoriteBoss, setFavoriteBoss] = React.useState({});
  const [ehb, setEhb] = React.useState(false);

  const filteredKillcounts = killcounts
    .filter((killcount) => combineAlts || killcount.Member_id === member.id)
    .reduce((accum, killcount) => {
      if (accum.find((kc) => killcount.Boss_id === kc.Boss_id)) {
        const temp = accum.find((kc) => killcount.Boss_id === kc.Boss_id);
        temp.value += killcount.value;
        accum[accum.findIndex((kc) => killcount.Boss_id === kc.Boss_id)] = temp;
      } else {
        accum = [
          ...accum,
          {
            id: killcount.id,
            value: killcount.value,
            boss: killcount.boss,
            Boss_id: killcount.Boss_id,
            Member_id: member.id,
          },
        ];
      }
      return accum;
    }, []);

  useEffect(() => {
    if (member && member.id && killcounts.length > 0) {
      if (combineAlts) {
        setFavoriteBoss(
          killcounts.sort((a, b) =>
            (ehb ? (a.boss.ehb > 0 ? a.value / a.boss.ehb : 0) : a.value) >
            (ehb ? (b.boss.ehb > 0 ? b.value / b.boss.ehb : 0) : b.value)
              ? -1
              : 1
          )[0]?.boss || {}
        );
      } else {
        setFavoriteBoss(
          killcounts.sort((a, b) =>
            (ehb ? (a.boss.ehb > 0 ? a.value / a.boss.ehb : 0) : a.value) >
            (ehb ? (b.boss.ehb > 0 ? b.value / b.boss.ehb : 0) : b.value)
              ? -1
              : 1
          )[0]?.boss || {}
        );
      }
    }
  }, [ehb, killcounts, combineAlts, member]);

  useEffect(() => {
    if (favoriteBoss.artwork && setBackground) {
      setBackground(favoriteBoss.artwork);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favoriteBoss]);

  return (
    <Hidden smDown implementation='css'>
      <Card>
        <CardContent>
          <Grid container justify='center'>
            <Grid item xs={6} sm={6} md={6}>
              <Typography>
                {t("lookup.bossCard.boss")} ( EHB{" "}
                <Switch
                  size='small'
                  checked={ehb}
                  onChange={() => setEhb((prev) => !prev)}
                />
                )
              </Typography>
              <Typography variant='h5' component='h2'>
                {favoriteBoss.name}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <Typography>
                {t("lookup.bossCard.ehb")}
                {alts && alts.length > 0 && (
                  <span>
                    {" "}
                    ( {t("lookup.bossCard.alts")}{" "}
                    <Switch
                      size='small'
                      checked={combineAlts}
                      onChange={() => setCombineAlts((prev) => !prev)}
                    />
                    )
                  </span>
                )}{" "}
              </Typography>
              <Typography variant='h5' component='h2'>
                {filteredKillcounts
                  .reduce(
                    (totalEhb, killcount) =>
                      Number(totalEhb) +
                      (killcount.boss.ehb > 0
                        ? killcount.value / killcount.boss.ehb
                        : 0),
                    0
                  )
                  .toFixed(2)}{" "}
                <Typography>
                  (
                  {filteredKillcounts
                    .filter((killcount) =>
                      bosses
                        .filter((boss) => boss.type === "raid")
                        .map((boss) => boss.id)
                        .includes(killcount.Boss_id)
                    )
                    .reduce(
                      (totalEhb, killcount) => totalEhb + killcount.value,
                      0
                    )}{" "}
                  Raids KC)
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <BossChart ehb={ehb} killcounts={filteredKillcounts} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Hidden>
  );
}
