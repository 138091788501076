import React from "react";
import Card from "@material-ui/core/Card";
import { CardContent } from "@material-ui/core";
import { PieChart } from "react-chartkick";
import { makeStyles } from "@material-ui/core/styles";

export default function StaffGraph({ data }) {
  const useStyles = makeStyles(() => ({
    pieCard: {
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  }));

  const classes = useStyles();

  return (
    <Card className={classes.pieCard}>
      <CardContent>
        <PieChart data={data} width="350px" height="350px" />
      </CardContent>
    </Card>
  );
}
