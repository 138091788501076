import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/components/headerStyle";
import { SubmissionContext } from "../../contexts/submissionContext";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router-dom";

export default function Search({ baseUrl, onChange }) {
    const useStyles = makeStyles(styles);
    const classes = useStyles();
    const { clans, fetchClans } = useContext(SubmissionContext);
    const [clan, setClan] = useState({})
    const history = useHistory();

    return (
        <div className={classes.search}>
            <Autocomplete
                options={clans}
                autoHighlight
                value={clan}
                getOptionLabel={(option) => option.name || ""}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        fullWidth
                        placeholder={"Find a clan..."}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                )}
                onChange={(event, clan) => {
                    setClan(clan)
                    if (clan) {
                        history.push(`${baseUrl}/${clan.slug}`)
                    }
                    if (onChange) {
                        onChange()
                    }
                }}
                onInputChange={(event, value) => {
                    fetchClans(value);
                }}
            />
        </div>
    )

}
