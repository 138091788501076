import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import Hidden from "@material-ui/core/Hidden";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import { useTranslation } from "react-i18next";
import styles from "../../assets/jss/components/headerStyle";
import { UserContext } from "../../contexts/userContext";
import { ClanContext } from "../../contexts/clanContext";
import OldschoolClansLogo from "../../assets/img/shield.png";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import MemberSearch from "./MemberSearch";
import ClanSearch from "./ClanSearch";
import Submissions from "./Submissions";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SearchIcon from "@material-ui/icons/Search";
import Modal from "@material-ui/core/Modal";
import MenuIcon from "@material-ui/icons/Menu";

export default function Toolbars({ handleDrawerToggle }) {
  const { user } = useContext(UserContext);
  const { clan } = useContext(ClanContext);
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.up("sm"));
  const mobileDown = useMediaQuery(theme.breakpoints.down("sm"));
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [searchOpen, setSearchOpen] = React.useState(false);
  const handleSearchToggle = () => {
    setSearchOpen(!searchOpen);
  };
  const baseUrl =
    !process.env.REACT_APP_STANDALONE_ID && clan?.id ? `/${clan.slug}` : "";
  const brandComponent =
    !process.env.REACT_APP_STANDALONE_ID && clan.slug ? (
      <Button>
        <Link
          to={`/`}
          style={{ fontWeight: "bold", display: "flex", alignItems: "center" }}
        >
          <img
            src={OldschoolClansLogo}
            alt={`${clan.name || "Header"} Logo`}
            height='30px'
            style={{ paddingRight: "7px" }}
          />
        </Link>
        <Link
          to={`/${clan.slug}`}
          style={{ fontWeight: "bold", display: "flex", alignItems: "center" }}
        >
          <img
            src={clan.clanLogo}
            alt={`${clan.name || "Header"} Logo`}
            height='30px'
            style={{ paddingRight: "7px" }}
          />
          <Typography color='primary' variant='body1'>
            {clan.name}
          </Typography>
        </Link>
      </Button>
    ) : (
      <Button>
        <Link
          to='/'
          style={{ fontWeight: "bold", display: "flex", alignItems: "center" }}
        >
          <img
            src={clan.clanLogo || process.env.REACT_APP_OG_IMAGE}
            alt={`${clan.name || "Header"} Logo`}
            height='30px'
            style={{ paddingRight: "7px" }}
          />
          <Typography variant='body1' noWrap color='textPrimary'>
            {clan.name || process.env.REACT_APP_CLAN_NAME}
          </Typography>
        </Link>
      </Button>
    );

  return (
    <Toolbar className={classes.container}>
      <div style={{ flex: 1, display: "flex" }}>{brandComponent}</div>
      {mobile && (
        <div style={{ flex: 1 }}>
          {clan.id ? (
            <MemberSearch baseUrl={baseUrl} />
          ) : (
            <ClanSearch baseUrl={baseUrl} />
          )}
        </div>
      )}
      <div style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
        {user.id === null && (
          <Tooltip
            id='sign-in'
            title={t("header.loginTooltip")}
            placement={window.innerWidth > 959 ? "top" : "left"}
            classes={{ tooltip: classes.tooltip }}
          >
            <Link to={`${baseUrl}/login`} className={classes.navLink}>
              <i className={`${classes.socialIcons} fas fa-sign-in-alt`} />
              <Hidden mdUp implementation='css'>
                <span style={{ paddingLeft: "10px" }}>
                  {t("header.loginLink")}
                </span>
              </Hidden>
            </Link>
          </Tooltip>
        )}
        {mobile &&
          clan.id &&
          (user.id ? (
            <>
              {user.Clan_id === clan.id && (
                <IconButton
                  color='inherit'
                  aria-label='open drawer'
                  onClick={handleClick}
                >
                  <AddCircleOutlineIcon />
                </IconButton>
              )}
              <Menu
                id='simple-menu'
                anchorEl={anchorEl}
                keepMounted
                open={anchorEl}
                onClose={handleClose}
              >
                <Submissions baseUrl={baseUrl} onClick={handleClose} />
              </Menu>
              <Divider orientation='vertical' flexItem />
              <Link
                to={`${baseUrl}/lookup/${user.username}`}
                className={`${classes.navLink}`}
              >
                <Typography
                  variant='subtitle2'
                  color={user.Clan_id === clan.id ? "inherit" : "error"}
                >
                  {user.username}
                </Typography>
              </Link>
              <Divider orientation='vertical' flexItem />
            </>
          ) : (
            <>
              <Divider orientation='vertical' flexItem />
              <Link to={`${baseUrl}/login`} className={`${classes.navLink}`}>
                <Typography variant='subtitle2'>Login</Typography>
              </Link>
              <Divider orientation='vertical' flexItem />
            </>
          ))}
        {!mobile && (
          <IconButton
            color='inherit'
            aria-label='open search'
            onClick={handleSearchToggle}
          >
            <SearchIcon />
          </IconButton>
        )}
        {mobileDown && (
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
        )}
        <Modal open={searchOpen} onClose={handleSearchToggle}>
          <div className={classes.paper}>
            {clan.id ? (
              <MemberSearch baseUrl={baseUrl} onChange={handleSearchToggle} />
            ) : (
              <ClanSearch baseUrl={baseUrl} onChange={handleSearchToggle} />
            )}
          </div>
        </Modal>
      </div>
    </Toolbar>
  );
}
