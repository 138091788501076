import React from "react";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import { CardContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import { styled } from '@material-ui/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';

const GreenCheck = styled(CheckCircleIcon)({
  color: green[500],
});
const RedCheck = styled(ErrorIcon)({
  color: red[500],
});
export default function BasicDetails({ record }) {
const useStyles = makeStyles(() => ({
    pieCard: {
      display: "flex",
    },
  }));
  const classes = useStyles();


  return (
    <Card className={classes.pieCard}>
      <CardContent style={{width: "100%"}}>
        {record && record.id && (
          <>
            <Grid container >
              <Grid item xs={12} >
                <Grid container >
                  <Grid item xs={3} >
                    <Typography variant="body" >
                      Active:
                    </Typography>
                  </Grid>
                  <Grid item xs={3} >
                    {record.active ? <GreenCheck /> : <RedCheck />}
                  </Grid>
                  <Grid item xs={3} >
                    <Typography variant="body" >
                      Gearcheck
                    </Typography>
                  </Grid>
                  <Grid item xs={3} >
                      {record.gearcheck ? <GreenCheck /> : <RedCheck />}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} >
                <Grid container >
                  <Grid item xs={6} >
                    <Typography variant="body">
                      Join Date
                    </Typography>
                  </Grid>
                  <Grid item xs={6} >
                    <Typography variant="body">
                      <b>
                        {new Date(record.joinDate)
                          .toISOString()
                          .slice(0, 10)
                          .replace(/-/g, "/")}
                      </b>
                    </Typography>
                  </Grid>
                </Grid>                
              </Grid>
            </Grid>
          </>
        )}
      </CardContent>
    </Card>
  );
}
