import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

export default function InfoArea({ title, description, Icon }) {
  return (
    <Grid container justify="center">
      <Grid item xs={10} sm={10} md={10}>
        <Icon />
      </Grid>
      <Grid item xs={10} sm={10} md={10}>
        <Typography variant="h5">{title}</Typography>
      </Grid>

      <Grid item xs={10} sm={10} md={10}>
        <Typography variant="body">{description}</Typography>
      </Grid>
    </Grid>
  );
}
