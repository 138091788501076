import API from "./axios";

const getAll = async (model: string, params = {}) =>
  (await API.get(`${model}`, { params })).data;
const get = async (model: string, id: number) =>
  (await API.get(`${model}/${id}`)).data;
const create = async (model: string, params = {}) =>
  await API.post(`${model}`, params);
const update = async (model: string, params = {}) =>
  await API.post(`${model}`, params);
const getByUsername = async (username: string) =>
  (await API.get(`Members/username/${username}`)).data;
const login = async (username: string, pin: string) =>
  await API.post("Members/login", { username, pin }).catch((error) => error);
const register = async (username: string, pin: string) =>
  await API.post("Members/register", { username, pin }).catch((error) => error);
const logout = async () => await API.get("Members/logout");
const fetchLoggedInUser = async () => (await API.get("Members/jwt")).data;
const fetchAllKillcounts = async (params = {}) =>
  await API.get("Members/killcounts", { params });
const fetchAllKillcountsOverall = async (params = {}) =>
  await API.get("Members/killcounts/overall", { params });
const fetchAllPointsOverall = async (params = {}) =>
  await API.get("Members/points/overall", { params });
const fetchAllPointsCommunity = async (params = {}) =>
  await API.get("Members/points/community", { params });
const fetchAllKillcountsEhb = async (params = {}) =>
  await API.get("Members/killcounts/ehb", { params });
const fetchAllSkills = async (params = {}) =>
  await API.get("Members/experience", { params });
const fetchAllPoints = async (params = {}) =>
  await API.get("Members/points", { params });
const fetchAllPets = async (params = {}) =>
  await API.get("Members/pets", { params });
const fetchAllDiaries = async (params = {}) =>
  await API.get("Members/diaries", { params });
const fetchAllTimes = async (params = {}) =>
  await API.get("Members/times", { params });
const fetchStarStats = async (params = {}) =>
  await API.get("Members/stars", { params });
const resetPoints = async (id: number) =>
  (await API.get(`Members/${id}/resetPoints`)).data;
const promoteHere = async (id: number) =>
  (await API.get(`Events/${id}/promoteHere`)).data;
const promoteEveryone = async (id: number) =>
  (await API.get(`Events/${id}/promoteEveryone`)).data;
const promoteNone = async (id: number) =>
  (await API.get(`Events/${id}/promoteNone`)).data;
const postDraft = async (id: number) =>
  (await API.get(`Events/${id}/postDraft`)).data;
const scanImage = async (screenshot: string) =>
  (await API.post("Drops/scanImage", { screenshot }).catch((error) => error))
    .data;
const fetchKillcounts = async (id: number) =>
  (await APIClient.getAll("Killcounts", {
    Member_id: id,
    _start: 0,
    _end: 1000,
  })) || [];
const fetchDrops = async (id: number) =>
  (await APIClient.getAll("Drops", {
    attack: id,
    approved: 1,
    _start: 0,
    _end: 1000,
  })) || [];
const fetchExperiences = async (id: number) =>
  (await APIClient.getAll("Experiences", {
    Member_id: id,
    _start: 0,
    _end: 1000,
  })) || [];
const fetchTanks = async (id: number) =>
  (await APIClient.getAll("Drops", {
    tank: id,
    approved: 1,
    _start: 0,
    _end: 1000,
  })) || [];
const fetchAlts = async (id: number) =>
  (await APIClient.getAll("Members", {
    parentAccount: id,
    active: 1,
    _start: 0,
    _end: 1000,
  })) || [];
const APIClient = {
  getAll,
  create,
  get,
  update,
  getByUsername,
  login,
  register,
  resetPoints,
  fetchLoggedInUser,
  logout,
  fetchKillcounts,
  fetchDrops,
  fetchExperiences,
  fetchTanks,
  fetchAllKillcounts,
  fetchAllKillcountsOverall,
  fetchAllKillcountsEhb,
  fetchAllSkills,
  fetchAllPoints,
  fetchAllPointsOverall,
  fetchAllPointsCommunity,
  fetchAllPets,
  fetchAllDiaries,
  fetchAllTimes,
  fetchAlts,
  promoteHere,
  promoteEveryone,
  promoteNone,
  postDraft,
  scanImage,
  fetchStarStats,
};

export default APIClient;
