import React, { useContext } from "react";
import Card from "@material-ui/core/Card";
import { CardContent } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import SkillChart from "./Skill";
import { useTranslation } from "react-i18next";
import { SubmissionContext } from "../../contexts/submissionContext";

export default function SkillCard({ member, experiences }) {
  const { t } = useTranslation();
  const { skills } = useContext(SubmissionContext);

  const filteredExperiences = experiences
    .filter(
      (experience) =>
        experience.Member_id === member.id &&
        skills.find((skill) => skill.id === experience.Skill_id).name !==
          "Overall"
    )
    .reduce((accum, experience) => {
      if (accum.find((kc) => experience.Skill_id === kc.Skill_id)) {
        const temp = accum.find((kc) => experience.Skill_id === kc.Skill_id);
        temp.value += experience.value;
        accum[
          accum.findIndex((kc) => experience.Skill_id === kc.Skill_id)
        ] = temp;
      } else {
        accum = [
          ...accum,
          {
            id: experience.id,
            value: experience.value,
            skill: skills.find((skill) => skill.id === experience.Skill_id),
            Skill_id: experience.Skill_id,
            Member_id: member.id,
          },
        ];
      }
      return accum;
    }, [])
    .sort((a, b) => (a.skill.name > b.skill.name ? 1 : -1));
  return (
    <Hidden smDown implementation='css'>
      <Card>
        <CardContent>
          <Grid container justify='center'>
            <Grid item xs={12} sm={12} md={12}>
              <Typography>{t("lookup.skillCard.name")}</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <SkillChart experiences={filteredExperiences} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Hidden>
  );
}
