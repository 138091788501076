import React, { FunctionComponent, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import CenteredGrid from "../../components/Shared/CenteredGrid";
import { MentorProgram } from "../../models";
import { UserContext } from "../../contexts/userContext";
import { SubmissionContext } from "../../contexts/submissionContext";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Student } from "../../models";
import { ThemeContext } from "../../contexts/themeContext";

interface OverviewProps {
    mentorProgram: MentorProgram;
}
const Overview: FunctionComponent<OverviewProps> = ({
    mentorProgram,
}) => {
    const { user } = useContext(UserContext);
    const { createStudent } = useContext(SubmissionContext);
    const { studentSubmitted } = useContext(ThemeContext);
    const isPendingStudent =
        mentorProgram.students &&
        mentorProgram.students.map((student) => student.id).includes(user.id);
    const studentObject = {
        MentorProgram_id: mentorProgram.id,
        Member_id: user.id,
        Clan_id: user.Clan_id,
        active: false,
        graduated: false,
        joinDate: new Date(),
    } as Student

    return (
        <CenteredGrid container spacing={3}>
            <>
                <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="h5" gutterBottom>
                        {mentorProgram.name}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="body1" gutterBottom>
                        {mentorProgram.description}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="outlined"
                        onClick={() => createStudent(studentObject)}
                        disabled={isPendingStudent || studentSubmitted}>
                        apply
                    </Button>
                </Grid>
            </>
        </CenteredGrid>
    );
};
export default Overview;
