import React, { useContext, FunctionComponent, useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Layout from "../../components/Layout/Layout";
import { ClanContext } from "../../contexts/clanContext";
import CustomTabs from "../../components/Requirements/CustomTabs";
import MentorContainer from "../../components/MentorLogger/MentorContainer";
import { SubmissionContext } from "../../contexts/submissionContext";

const MentorLogger: FunctionComponent = () => {
  const { clan } = useContext(ClanContext);
  const { mentorPrograms, mentorProgramsFetched, fetchMentorPrograms, checklistsFetched, fetchChecklists } = useContext(SubmissionContext);
  const [background, setBackground] = useState(mentorPrograms[0]?.artwork);

  useEffect(() => {
    if (clan && clan.id && !mentorProgramsFetched && !checklistsFetched) {
      fetchMentorPrograms();
      fetchChecklists();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mentorProgramsFetched, checklistsFetched]);

  return (
    <Layout loading={!mentorProgramsFetched && !checklistsFetched} background={background}>
      <Grid container justify="center">
        <Grid item xs={12} sm={12} md={12}>
          <CustomTabs
            onClick={(val: number) =>
              setBackground(mentorPrograms[val].artwork)
            }
            tabs={mentorPrograms.map((mentorProgram) => ({
              tabName: mentorProgram.name,
              tabContent: <MentorContainer mentorProgram={mentorProgram} />,
            }))}
          />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default MentorLogger;
