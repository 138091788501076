import React, { FunctionComponent, useContext, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useTranslation } from "react-i18next";
import { Member, Boss, Killcount } from "../../models";
import { SubmissionContext } from "../../contexts/submissionContext";

interface CompareTableProps {
  members: Array<Member>;
  bosses: Array<Boss>;
}

const CompareTable: FunctionComponent<CompareTableProps> = ({ members, bosses }) => {
  const { t } = useTranslation();
  const { fetchAndAppendKillcounts, killcounts } = useContext(SubmissionContext);

  useEffect(() => {
    fetchAndAppendKillcounts(members?.map(member => member.id))
  }, [members, fetchAndAppendKillcounts])

  return (
    <Grid container justify="center">
      <Grid item xs={12} sm={12} md={12}>
        <Table style={{ width: "100%" }}>
          <TableHead>
            <TableRow>
              <TableCell>{t("compare.compareTable.usernameHeader")}</TableCell>
              {bosses.map((boss) => (
                <TableCell component="th" scope="row" key={boss.name}>
                  {boss.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {members.map((alt) => (
              <>
                <TableRow key={alt.id}>
                  <TableCell component="th" scope="row">
                    {alt.username}
                  </TableCell>
                  {bosses.map((boss) => (
                    <TableCell key={boss.id} component="th" scope="row">
                      {killcounts.find(killcount => killcount.Boss_id === boss.id && killcount.Member_id === alt.id) ? (killcounts.find(killcount => killcount.Boss_id === boss.id && killcount.Member_id === alt.id) || {} as Killcount).value : "-"}
                    </TableCell>
                  ))}
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};

export default CompareTable;
