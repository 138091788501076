import React, { FunctionComponent, useState, useContext } from "react";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import { CardContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { RadialChart, Hint, RadialChartPoint, DiscreteColorLegend } from 'react-vis';
import AutoSizer from 'react-virtualized-auto-sizer';
import { ThemeContext } from "../../contexts/themeContext";
import { Member, Point, PointCategory } from "../../models";
import { SubmissionContext } from "../../contexts/submissionContext";

interface PointWheelProps {
  member: Member;
  points: Array<Point>;
}

const colorDomain = ["#f44336",
  "#ff9800",
  "#ffeb3b",
  "#4caf50",
  "#2196f3",
  "#3f51b5",
  "#9c27b0",
  "#607d8b",]

const PointWheel: FunctionComponent<PointWheelProps> = ({ member, points }) => {
  const { mode } = useContext(ThemeContext);
  const { pointCategories } = useContext(SubmissionContext);
  const [value, setValue] = useState({} as RadialChartPoint)
  console.log(points)
  const data = points.filter(point => point.Member_id === member.id).map((point: Point, index) => {
    let pointCategory = pointCategories.find(pointCategory => pointCategory.id === point.PointCategory_id) || {} as PointCategory
    return { theta: point.value, title: pointCategory.name, color: pointCategory.color || colorDomain[index % colorDomain.length] }
  })
  const items = data.map((data, index) => {
    return { strokeWidth: 12, title: data.title, color: data.color }
  })

  const useStyles = makeStyles(() => ({
    pieCard: {
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    cardWrapper: {
      height: '100%',
      width: '100%',
    },
    legend: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      '& .rv-discrete-color-legend-item__title': {
        color: mode === 'dark' ? 'white' : 'black',
      }
    },
  }));
  const classes = useStyles();
  return points.length > 0 && (
    <Card className={classes.pieCard}>
      <CardContent className={classes.cardWrapper} >
        <AutoSizer>
          {({ height, width }) => (
            <>
              <DiscreteColorLegend
                orientation='horizontal'
                width={width}
                height={height * .25}
                className={classes.legend}
                items={items} />
              <RadialChart
                width={width}
                height={height * .75}
                getAngle={d => d.theta}
                showLabels
                data={data}
                colorType={'literal'}
                onValueMouseOver={v => setValue(v)}
                onSeriesMouseOut={() => setValue({} as RadialChartPoint)}
              >
                {value.theta ? (
                  <Hint value={value}>
                    <Card>
                      <CardContent>
                        <Typography><b>{value.title}: {value.theta}</b></Typography>
                      </CardContent>
                    </Card>
                  </Hint>
                ) : (<> </>)}
              </RadialChart>
            </>
          )}
        </AutoSizer>
      </CardContent>
    </Card >
  );
};

export default PointWheel;
