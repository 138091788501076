import React, { FunctionComponent } from "react";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import Layout from "../../components/Layout/Layout";
import AltList from "../../components/Alts/AltList";
import AddAlt from "../../components/Alts/AddAlt";

const Alts: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <Layout title={t("alts.pageTitle")}>
      <Grid container justify="center" spacing={3}>
        <Grid item xs={12}>
          <AltList />
        </Grid>
        <Grid item xs={12}>
          <AddAlt />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Alts;
