import React, { useContext, useState } from "react";
import "chart.js";
import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeries,
  Hint,
} from "react-vis";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import { CardContent } from "@material-ui/core";
import AutoSizer from "react-virtualized-auto-sizer";
import { ThemeContext } from "../../contexts/themeContext";
import { makeStyles } from "@material-ui/core/styles";

const colorDomain = [
  '#272C79',
  '#5F110B',
  '#9D907D',
  '#501F5C',
  '#7C6046',
  '#6F83BA',
  '#2B632E',
  '#B66F15',
  '#84A4C1',
  '#124A4D',
  '#167329',
  '#D1CFCA',
  '#706A4C',
  '#282C75',
  '#5A5846',
  '#BEB4B4',
  '#5E771F',
  '#D48834',
  '#282626',
  '#5C5C49',
  '#11794E',
  '#774364',
  '#947943',
];

const PointWheel = ({ member, experiences }) => {
  const { mode } = useContext(ThemeContext);
  const numberWithCommas = (x) =>
    (x || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const useStyles = makeStyles(() => ({
    cardWrapper: {
      minHeight: "400px",
      height: "100%",
      width: "100%",
      "& .rv-xy-plot__axis__tick__text": {
        fill: mode === "dark" ? "white" : "black",
      },
    },
  }));
  const classes = useStyles();
  const [value, setValue] = useState({});
  return (
    <div className={classes.cardWrapper}>
      <AutoSizer>
        {({ height, width }) => (
          <>
            <XYPlot
              margin={{ bottom: 70, left: 80 }}
              xType='ordinal'
              width={width}
              height={400}
              animation={true}
            >
              <VerticalGridLines />
              <HorizontalGridLines />
              <XAxis tickLabelAngle={-45} />
              <YAxis />
              <VerticalBarSeries
                colorType='literal'
                onValueMouseOver={(v) => {
                  if (value.x !== v.x) {
                    setValue(v);
                  }
                }}
                onValueMouseOut={() => setValue({})}
                data={experiences.map((experience, index) => {
                  return {
                    x: experience.skill.name,
                    y: experience.value,
                    color: colorDomain[index % colorDomain.length],
                  };
                })}
              />
              {value.x ? (
                <Hint value={value}>
                  <Card>
                    <CardContent>
                      <Typography>
                        <b>
                          {value.x}: {numberWithCommas(value.y)}
                        </b>
                      </Typography>
                    </CardContent>
                  </Card>
                </Hint>
              ) : (
                <> </>
              )}
            </XYPlot>
          </>
        )}
      </AutoSizer>
    </div>
  );
};
export default PointWheel;
