import React from "react";
import {
  List,
  Pagination,
  Filter,
  UrlField,
  Show,
  Create,
  SimpleShowLayout,
  Datagrid,
  NumberField,
  TextField,
  NumberInput,
  TextInput,
  Edit,
  SimpleForm,
} from "react-admin";

const BigPagination = (props) => (
  <Pagination rowsPerPageOptions={[25, 50, 100, 2000]} {...props} />
);
const SearchFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Boss name" source="name" alwaysOn />
  </Filter>
);

export const BossList = (props) => (
  <List
    {...props}
    filters={<SearchFilter {...props} />}
    pagination={<BigPagination />}
    filter={{ Clan_id: props.clan.id }}
    perPage={25}
  >
    <Datagrid rowClick="show">
      <TextField source="name" />
      <UrlField source="artwork" />
      <NumberField source="ehb" />
    </Datagrid>
  </List>
);

export const BossShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <UrlField source="artwork" />
      <NumberField source="ehb" />
    </SimpleShowLayout>
  </Show>
);

export const BossEdit = (props) => (
  <Edit {...props} undoable={false}>
    <SimpleForm>
      <NumberInput disabled label="Id" source="id" />
      <TextInput label="Name" source="name" />
      <TextInput label="Boss artwork" source="artwork" />
      <TextInput label="Type (raid or boss)" source="type" />
      <NumberInput label="EHB" source="ehb" />
    </SimpleForm>
  </Edit>
);

export const BossCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput label="Name" source="name" />
      <TextInput label="Boss artwork" source="artwork" />
      <TextInput label="Type (raid or boss)" source="type" />
      <NumberInput label="EHB" source="ehb" />
      <NumberInput source="Clan_id" initialValue={props.clan.id} disabled />
    </SimpleForm>
  </Create>
);
