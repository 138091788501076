import React, { useState, createContext, useEffect, useContext } from "react";
import API from "../helpers/api";
import {
  Killcount,
  Member,
  Event,
  Drop,
  BadgeMember,
  TaskCompleted,
  EventMember,
  Rank,
  Skill,
  Point,
} from "../models";
import { ClanContext } from "./clanContext";

const LookupContext = createContext({
  member: {} as Member,
  main: {} as Member,
  killcounts: [] as Array<Killcount>,
  alts: [] as Array<Member>,
  drops: [] as Array<Drop>,
  tanks: [] as Array<Drop>,
  unlockedPets: [] as Array<TaskCompleted>,
  memberBadges: [] as Array<BadgeMember>,
  events: [] as Array<Event>,
  points: [] as Array<Point>,
  ranks: [] as Array<Rank>,
  experiences: [] as Array<Skill>,
  setMember: (val: Member) => {},
  resetMember: () => {},
  setLoading: (val: boolean) => {},
  loading: false,
});

const LookupContextConsumer = LookupContext.Consumer;

interface LookupContextProviderProps {
  children: JSX.Element;
  username: string;
}

const LookupContextProvider = (
  props: LookupContextProviderProps
): JSX.Element => {
  const { children } = props;
  const { clan } = useContext(ClanContext);
  const [member, setMember] = useState({
    id: undefined,
    username: props.username,
  } as Member);
  const [drops, setDrops] = useState([] as Array<Drop>);
  const [tanks, setTanks] = useState([] as Array<Drop>);
  const [loading, setLoading] = useState(true);
  const [memberBadges, setMemberBadges] = useState([] as Array<BadgeMember>);
  const [killcounts, setKillcounts] = useState([] as Array<Killcount>);
  const [events, setEvents] = useState([] as Array<Event>);
  const [points, setPoints] = useState([] as Array<Point>);
  const [unlockedPets, setUnlockedPets] = useState([]);
  const [main, setMain] = useState({} as Member);
  const [alts, setAlts] = useState([] as Array<Member>);
  const [ranks, setRanks] = useState([] as Array<Rank>);
  const [experiences, setExperiences] = useState([] as Array<Skill>);
  const resetMember = () => {
    setAlts([]);
    setMain({} as Member);
    setDrops([]);
    setTanks([]);
    setLoading(false);
    setMemberBadges([]);
    setKillcounts([]);
    setEvents([]);
    setUnlockedPets([]);
    setRanks([] as Array<Rank>);
    setExperiences([] as Array<Skill>);
    setPoints([] as Array<Point>);
  };

  useEffect(() => {
    const fetchTanks = async (id: number) => setTanks(await API.fetchTanks(id));
    const fetchAlts = async (id: number) => {
      let alts = await API.fetchAlts(id);
      setAlts(alts);
      return alts;
    };
    const fetchMain = async (id: number) =>
      setMain((await API.get("Members", id)) || []);
    const fetchAndAppendKillcounts = async (ids: Array<number>) => {
      const res = await Promise.all(
        ids.map((id) => API.fetchKillcounts(id)) || []
      );
      if (res.length > 0) {
        let killcounts = [...res.flat()];
        setKillcounts(killcounts);
        let favoriteBoss =
          killcounts.sort((a, b) => (a.value > b.value ? -1 : 1))[0]?.boss ||
          {};
        if (favoriteBoss.artwork) {
          const img = new Image();
          img.src = favoriteBoss.artwork;
          await img.decode();
        }
      }
    };
    const fetchAndAppendDrops = async (ids: Array<number>) => {
      const res = await Promise.all(ids.map((id) => API.fetchDrops(id)) || []);
      if (res.length > 0) {
        setDrops([...res.flat()]);
      }
    };
    const fetchBadges = async (id: number) => {
      setMemberBadges(
        await API.getAll("BadgeMembers", {
          Member_id: id,
          _start: 0,
          _end: 100,
        })
      );
    };
    const fetchEvents = async (id: number) => {
      let events =
        (await API.getAll("EventMembers", {
          Member_id: member.id,
          _start: 0,
          _end: 200,
        })) || [];
      events = events
        .filter((e: EventMember) => e.event)
        .map((e: EventMember) => e.event);
      const groups = events.reduce(
        (
          r: { [key: string]: { group: string; data: Array<Event> } },
          o: Event
        ) => {
          const y = o.date.split("-")[0];
          const key = y;
          r[key]
            ? r[key].data.push(o)
            : (r[key] = { group: `${y}`, data: [o] });
          return r;
        },
        {}
      );
      setEvents(
        Object.keys(groups)
          .map((k) => groups[k])
          .sort(
            (a, b) =>
              Date.parse(b.data[0].date || "2000-01-01") -
              Date.parse(a.data[0].date || "2000-01-01")
          )
      );
    };
    const fetchPets = async (id: number) => {
      let tasksCompleted = await API.getAll("TaskCompleted", {
        Member_id: member.id,
        approved: 1,
        _start: 0,
        _end: 1000,
      }) || [] as Array<TaskCompleted>;
      tasksCompleted = tasksCompleted.filter(
        (taskCompleted: TaskCompleted) =>
          taskCompleted.task &&
          taskCompleted.task.Checklist_id === 1 &&
          (taskCompleted.task?.parenttask
            ? !tasksCompleted.find(
                (task: TaskCompleted) =>
                  task.task?.id === taskCompleted.task?.parenttask
              )
            : true)
      );

      setUnlockedPets(tasksCompleted);
    };
    const fetchMember = async (username: string) =>
      setMember((await API.getByUsername(username)) || {});
    const fetchAndAppendRanks = async (ids: Array<number>) => {
      const res = await Promise.all(
        ids.map((id) => API.get("Ranks", id)) || {}
      );
      if (res.length > 0) {
        setRanks([...res.flat()]);
      }
    };
    const fetchAndAppendExperiences = async (ids: Array<number>) => {
      const res = await Promise.all(
        ids.map((id) => API.fetchExperiences(id)) || []
      );
      if (res.length > 0) {
        setExperiences([...res.flat()]);
      }
    };
    const fetchAndAppendPoints = async (ids: Array<number>) => {
      const res = await Promise.all(
        ids.map((id) =>
          API.getAll("Points", {
            Member_id: id,
            _start: 0,
            _end: 100,
          })
        ) || []
      );
      if (res.length > 0) {
        setPoints([...res.flat()]);
      }
    };
    if (member && member.id && member.Clan_id === clan.id) {
      if (loading) {
        fetchAlts(member.id).then((alts) => {
          let fetches = [
            fetchTanks(member.id || 0),
            fetchAndAppendDrops(
              alts.map((alt: Member) => alt.id).concat([member.id])
            ),
            fetchAndAppendKillcounts(
              alts.map((alt: Member) => alt.id).concat([member.id])
            ),
            fetchAndAppendRanks(
              alts.map((alt: Member) => alt.Rank_id).concat([member.Rank_id])
            ),
            fetchAndAppendExperiences(
              alts.map((alt: Member) => alt.id).concat([member.id])
            ),
            fetchAndAppendPoints(
              alts.map((alt: Member) => alt.id).concat([member.id])
            ),
            fetchEvents(member.id || 0),
            fetchPets(member.id || 0),
            fetchBadges(member.id || 0),
          ];

          if (member.parentAccount) {
            fetches.push(fetchMain(member.parentAccount));
          }
          Promise.all(fetches).then(() => {
            setLoading(false);
          });
        });
      }
    } else if (member && member.username && !member.Clan_id) {
      fetchMember(member.username);
    } else {
      setLoading(false);
    }
    // Disable the warning to include fetchKillcounts as a dependency because it's used twice
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [member]);

  return (
    <LookupContext.Provider
      value={{
        member,
        setMember,
        setLoading,
        resetMember,
        main,
        alts,
        memberBadges,
        loading,
        drops,
        tanks,
        killcounts,
        unlockedPets,
        events,
        points,
        ranks,
        experiences,
      }}
    >
      {children}
    </LookupContext.Provider>
  );
};

export { LookupContextProvider, LookupContextConsumer, LookupContext };
