import React, { FunctionComponent, useContext } from "react";
import Card from "@material-ui/core/Card";
import { CardContent } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { Member } from "../../models";
import { ClanContext } from "../../contexts/clanContext";

interface NameBarProps {
  member?: Member;
  main?: Member;
  alts?: Member[];
}

const NameBar: FunctionComponent<NameBarProps> = ({
  member = {},
  main = {},
  alts = [],
}) => {
  const { clan } = useContext(ClanContext);
  const baseUrl =
    !process.env.REACT_APP_STANDALONE_ID && clan?.id ? `/${clan.slug}` : "";

  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4" align="center">
              {`${member.username}${
                alts.length > 0
                  ? ", " + alts.map((alt) => alt.username).join(", ")
                  : ""
              }`}
              {main.id && member.parentAccount === main.id && (
                <Link to={`${baseUrl}/lookup/${main.username}`}>
                  {" "}
                  ({main.username})
                </Link>
              )}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default NameBar;
