import { AxiosResponse } from "axios";
import React, { useState, createContext, useContext } from "react";
import API from "../helpers/api";
import { ClanContext } from "./clanContext";

const HiscoreContext = createContext({
  fetchValues: async (
    overall: boolean,
    ehb: boolean,
    query: { [key: string]: string },
    skill: boolean,
    points: boolean,
    community: boolean,
    pets: boolean,
    diaries: boolean,
    times: boolean,
    page: 0
  ) => {},
  count: 0,
  rowsPerPage: 10,
  setRowsPerPage: (val: number) => {},
  values: [],
});

const HiscoreContextConsumer = HiscoreContext.Consumer;

interface HiscoreContextProviderProps {
  children: JSX.Element;
}

const HiscoreContextProvider = (
  props: HiscoreContextProviderProps
): JSX.Element => {
  const { children } = props;
  const { clan } = useContext(ClanContext);
  const [values, setValues] = useState([]);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const fetchValues = async (
    overall: boolean,
    ehb: boolean,
    query: { [key: string]: string },
    skill: boolean,
    points: boolean,
    community: boolean,
    pets: boolean,
    diaries: boolean,
    times: boolean,
    page: number
  ) => {
    let response = {} as AxiosResponse<any>;
    if (overall) {
      if (points) {
        response = await API.fetchAllPointsOverall({
          Clan_id: clan.id,
          _start: rowsPerPage * page,
          _end: rowsPerPage * (page + 1),
        });
      } else {
        response = await API.fetchAllKillcountsOverall({
          Clan_id: clan.id,
          _start: rowsPerPage * page,
          _end: rowsPerPage * (page + 1),
        });
      }
    } else if (community) {
      response = await API.fetchAllPointsCommunity({
        Clan_id: clan.id,
        _start: rowsPerPage * page,
        _end: rowsPerPage * (page + 1),
      });
    } else if (ehb) {
      response = await API.fetchAllKillcountsEhb({
        Clan_id: clan.id,
        _start: rowsPerPage * page,
        _end: rowsPerPage * (page + 1),
      });
    } else if (skill) {
      response = await API.fetchAllSkills({
        ...query,
        Clan_id: clan.id,
        _sort: "value",
        _order: "DESC",
        _start: rowsPerPage * page,
        _end: rowsPerPage * (page + 1),
      });
    } else if (points) {
      response = await API.fetchAllPoints({
        ...query,
        Clan_id: clan.id,
        _sort: "value",
        _order: "DESC",
        _start: rowsPerPage * page,
        _end: rowsPerPage * (page + 1),
      });
    } else if (pets) {
      response = await API.fetchAllPets({
        ...query,
        Clan_id: clan.id,
        _sort: "count",
        _order: "DESC",
        _start: rowsPerPage * page,
        _end: rowsPerPage * (page + 1),
      });
    } else if (diaries) {
      response = await API.fetchAllDiaries({
        ...query,
        Clan_id: clan.id,
        _sort: "count",
        _order: "DESC",
        _start: rowsPerPage * page,
        _end: rowsPerPage * (page + 1),
      });
    } else if (times) {
      response = await API.fetchAllTimes({
        ...query,
        Clan_id: clan.id,
        _sort: "value",
        _order: "ASC",
        _start: 0,
        _end: 5,
      });
    } else {
      response = await API.fetchAllKillcounts({
        ...query,
        Clan_id: clan.id,
        _sort: "value",
        _order: "DESC",
        _start: rowsPerPage * page,
        _end: rowsPerPage * (page + 1),
      });
    }

    if (count === 0) {
      setCount(response.headers["x-total-count"] || 0);
    }

    setValues(response.data || []);
  };

  return (
    <HiscoreContext.Provider
      value={{
        fetchValues,
        setRowsPerPage,
        values,
        count,
        rowsPerPage,
      }}
    >
      {children}
    </HiscoreContext.Provider>
  );
};

export { HiscoreContextProvider, HiscoreContextConsumer, HiscoreContext };
