import React, { useContext } from "react";
import { Route, Switch } from "react-router-dom";
import { UserContext } from "./contexts/userContext";
import Header from "./components/Header/Header";
import CmsRoutes from "./cmsRoutes";
import StandaloneRoutes from "./standaloneRoutes";
import LoadingOverlay from "./components/Loading/LoadingOverlay";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        display: "block",
      },
    },
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      marginTop: "50px",
      minHeight: "100vh",
    },
  })
);
export default function Routes() {
  const { user, userFetched, noClan } = useContext(UserContext);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Header />
      <main className={classes.content}>
        {(user.id || userFetched || noClan) && (
          <Route
            render={({ location }) => (
              <Switch location={location}>
                {process.env.REACT_APP_STANDALONE_ID ? (
                  <StandaloneRoutes />
                ) : (
                  <CmsRoutes />
                )}
              </Switch>
            )}
          />
        )}
        <LoadingOverlay />
      </main>
    </div>
  );
}
