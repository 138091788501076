import {
    List,
    Filter,
    Pagination,
    Show,
    Create,
    SimpleShowLayout,
    Datagrid,
    TextField,
    TextInput,
    ReferenceField,
    ReferenceInput,
    AutocompleteInput,
    Edit,
    SimpleForm,
    BooleanInput,
    BooleanField
  } from "react-admin";
  import React from "react";
  
  const BigPagination = (props) => (
    <Pagination rowsPerPageOptions={[25, 50, 100, 2000]} {...props} />
  );
  const SearchFilter = (props) => (
    <Filter {...props}>
      <ReferenceInput
        label="Member"
        source="Member_id"
        reference="members"
        filter={{ Clan_id: props.clan.id }}
        alwaysOn
      >
        <AutocompleteInput optionText="username" />
      </ReferenceInput>
    </Filter>
  );
  export const SuggestionList = (props) => (
    <List
      {...props}
      filters={<SearchFilter {...props} />}
      pagination={<BigPagination />}
      filter={{ Clan_id: props.clan.id }}
      perPage={25}
    >
      <Datagrid rowClick="show">
        <ReferenceField label="Member" source="Member_id" reference="members">
          <TextField source="username" />
        </ReferenceField>
        <TextField multiline source="suggestion" />
        <TextField source="link" />
        <BooleanField source='addressed' />
      </Datagrid>
    </List>
  );
  
  export const SuggestionShow = (props) => (
    <Show {...props}>
      <SimpleShowLayout>
      <ReferenceField label="Member" source="Member_id" reference="members">
          <TextField source="username" />
        </ReferenceField>
        <TextField multiline source="suggestion" />
        <TextField source="link" />
        <BooleanField source='addressed' />
      </SimpleShowLayout>
    </Show>
  );
  
  export const SuggestionEdit = (props) => (
    <Edit {...props} undoable={false}>
      <SimpleForm>
        <ReferenceInput label="Member" source="Member_id" reference="members" filter={{ Clan_id: props.clan.id }}>
          <AutocompleteInput optionText="username" />
        </ReferenceInput>
        <TextInput source="suggestion" />
        <TextInput source="link" />
        <BooleanInput source='addressed' />
      </SimpleForm>
    </Edit>
  );
  
  export const SuggestionCreate = (props) => (
    <Create {...props}>
      <SimpleForm>
      <ReferenceInput label="Member" source="Member_id" reference="members" filter={{ Clan_id: props.clan.id }}>
          <AutocompleteInput optionText="username" />
        </ReferenceInput>
        <TextInput source="suggestion" />
        <TextInput source="link" />
        <BooleanInput source='addressed' />
      </SimpleForm>
    </Create>
  );
  