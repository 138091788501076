import React, { useContext, FunctionComponent, useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CardActionArea from "@material-ui/core/CardActionArea";
import Chip from "@material-ui/core/Chip";

import Typography from "@material-ui/core/Typography";
// import { useTranslation } from "react-i18next";
import { ThemeContextConsumer } from "../../contexts/themeContext";
import { ClanContext } from "../../contexts/clanContext";
import { ThemeContext } from "../../contexts/themeContext";
import styles from "../../assets/jss/global";

const Home: FunctionComponent = () => {
  // const { t } = useTranslation();
  const { clans, clansFetched } = useContext(ClanContext);
  const useStyles = makeStyles(styles);
  const { setLoading } = React.useContext(ThemeContext);
  const classes = useStyles();

  useEffect(() => {
    setLoading(!clansFetched);
  }, [setLoading, clansFetched]);

  return (
    <ThemeContextConsumer>
      {(context) => (
        <Container className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant='h3' color='textPrimary'>
                Featured Clans
              </Typography>
            </Grid>
            {clans.map((clan) => (
              <Grid item xs={6} sm={6} md={3} key={clan.id}>
                <Link to={`/${clan.slug}`}>
                  <Card>
                    <CardActionArea className={classes.clanCard}>
                      <Grid container>
                        <Grid item xs={12}>
                          <CardMedia
                            className={classes.clanMedia}
                            image={clan.clanHeaderImage}
                            title={`${clan.name} Logo`}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <CardContent>
                            <Grid container spacing={1}>
                              <Grid item xs={12}>
                                <Typography variant='h5'>
                                  {clan.name}
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Chip label='PvM' variant='outlined' />

                                {/* <Typography variant='body1'>
                                  {clan.description}
                                </Typography> */}
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Grid>
                      </Grid>
                    </CardActionArea>
                  </Card>
                </Link>
              </Grid>
            ))}
            <Grid item xs={12}>
              <Typography variant='body1' color='textPrimary'>
                Oldschool Clans is a clan management platform for Oldschool
                Runescape. Oldschool Clans provides clan owners with a complete
                set of tools to fully manage your Oldschool Runescape clan in
                and outside of the game.
              </Typography>
            </Grid>
            {/* <Grid item xs={12}>
              <Typography
                variant='body1'
                color='textPrimary'
                display='inline'
                // style={{ display: "inline-block" }}
              >
                Key Features: <br />- Member tracking
                <br />- Loot submission tracking
                <br />- Fully customizable point tracking
                <br />- Progression style rank systems
                <br />- Event hosting
                <br />- Clan specific hiscores
                <br />- Member comparisons
                <br />- Custom badges
                <br />- Automatic Runewatch detection
                <br />- Admin management panel for staff
                <br />- Integration with the Runescape hiscores
                <br />- Runelite Plugin
                <br />- Full featured Discord bot for members wary of offsites
                <br />- Automatic Discord role management
              </Typography>
            </Grid> */}
          </Grid>
        </Container>
      )}
    </ThemeContextConsumer>
  );
};

export default Home;
