/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
import * as React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { Tabs, Tab } from "@material-ui/core/";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import StaffGraph from "./StaffGraph";
import { ClanContext } from "../../contexts/clanContext";
import { SubmissionContext } from "../../contexts/submissionContext";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const Dashboard = ({ user }) => {
  const { clan } = React.useContext(ClanContext);
  const { fetchStarsStats } = React.useContext(SubmissionContext);
  const [starStats, setStarStats] = React.useState(undefined);
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  React.useEffect(() => {
    const loadStarStars = async () => setStarStats(await fetchStarsStats());
    if (starStats === undefined) {
      loadStarStars();
    }
  }, [starStats, fetchStarsStats]);
  const useStyles = makeStyles(() => ({
    cardHeader: {
      width: "auto",
      textAlign: "center",
      marginLeft: "20px",
      marginRight: "20px",
      marginTop: "20px",
      padding: "20px 0",
      marginBottom: "15px",
      borderRadius: "3px",
      border: "0",
      color: "#fff",
      background: clan.clanColorGradient,
      boxShadow:
        "0 12px 20px -10px rgba(0, 0, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 0, 0, 0.2)",
    },
  }));
  const classes = useStyles();

  return (
    <Card>
      <div color={clan.clanColor} className={classes.cardHeader}>
        {clan.name} Admin
      </div>
      <CardContent>
        <Tabs
          value={value}
          indicatorColor={clan.clanColor}
          textColor="primary"
          onChange={handleChange}
        >
          Welcome to {clan.name} Admin!
          <Tab label="Home" {...a11yProps(0)} />
          <Tab label="Members" {...a11yProps(1)} />
          <Tab label="Drops and Such" {...a11yProps(2)} />
          <Tab label="Change Name" {...a11yProps(3)} />
          <Tab label="Unacceptable" {...a11yProps(4)} />
          <Tab label="Diagrams" {...a11yProps(5)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          Welcome to the {clan.name} Admin Dashboard!
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Typography variant="h6" component="h2">
            To accept a new <Link to="/members">member</Link>:
          </Typography>
          <Typography variant="body2">
            - Monitor Discord #unapproved-apps for new posts (approved
            applications will have a checkmark reaction and declined
            applications will have an “X” reaction)
            <br />
            - Check Answer to questions to make sure they reviewed our clan
            information
            <br />
            - Verify that their stats meet clan requirements
            <br />
            - Verify that they have all prayers unlocked
            <br />
            - Verify that they have all gear requirements
            <br />
            - Note that all provided screenshot need to be full screen and have
            the username showing
            <br />
            - If they were referred by someone, make sure the “Recruited By”
            field on their website Members entry is filled with the right name
            <br />
            - If everything with their trial is good, edit their member entry
            and toggle the “Active” and “Gear check” switches
            <br />
            - Set the “Rank” dropdown box to “Trial” – This will also
            automatically give them the “Trial Member” and “Active Member” roles
            on Discord
            <br />
            - Double check that their Discord nickname exactly matches their
            in-game username. You can right click on the user to manually set
            their nickname
            <br />
            - They will receive a welcome message from a Discord bot. Give them
            a shout out and welcome in the cc if they are online
            <br />
            - NOTE : If anything with their application is wrong, we encourage
            you to make a reasonable attempt to @ them on Discord or message
            them in game to get things sorted. They may send you any corrected
            screenshots via DM
            <br />- Back in the #unapproved-apps channel mark the post with
            either a checkmark or “X” reaction to let the rest of the team know
            it has been reviewed
          </Typography>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Typography variant="h6" component="h2">
            To accept a <Link to="/drops">drop</Link>/
            <Link to="/TaskCompleted">task</Link>/<Link to="/Times">time</Link>/
            <Link to="/Mentors">mentor</Link>:
          </Typography>
          <Typography variant="body2">
            - Locate the item <br />
            - Check the drop for accuracy
            <br />
            - Check the screenshot for accuracy
            <br />
            - Click approve
            <br />
          </Typography>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Typography variant="h6" component="h2">
            {" "}
            How to handle Name Changes:
          </Typography>
          <Typography variant="body2">
            - Monitor Discord #name-changes for new posts (addressed posts will
            have a checkmark reaction)
            <br />- In <Link to="/members">members</Link> search the menu for
            their old username and edit their profile to the new username
            <br />
            - Add the checkmark reaction to the name-change post in Discord
            <br />
          </Typography>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Typography variant="h6" component="h2">
            {" "}
            Unacceptable Behaviors:
          </Typography>
          <Typography variant="body2">
            - Fueling arguments -&gt; Try to be objective and diffuse them in a
            friendly way
            <br />
            - Openly arguing in cc -&gt; Take it to PMs
            <br />
            - Abusing authority – it is your job to enforce the clan rules but
            we try to take a chill approach whenever possible
            <br />- Give informal warnings for most things, but kick and strike
            where necessary when people are abusive, toxic, racist, hateful, etc
          </Typography>
        </TabPanel>
        <TabPanel value={value} index={5}>
          Under Construction...
          {starStats && user.Rank_id === 10 && <StaffGraph data={starStats} />}
        </TabPanel>
      </CardContent>
    </Card>
  );
};

export default Dashboard;
