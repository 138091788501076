import React from "react";
import Cookies from "universal-cookie";
import { createMuiTheme } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";

const ThemeContext = React.createContext({
  mode: "dark" as "dark" | "white",
  themeColor: "#000000",
  setThemeColor: (val: String) => {},
  setMode: (val: string) => {},
  toggleDarkTheme: () => {},
  theme: {} as Theme,
  state: { open: false, message: "" } as ThemeState,
  setState: (val: ThemeState) => {},
  handleClose: () => {},
  mentorSubmitted: false,
  setMentorSubmitted: (val: boolean) => {},
  recordSubmitted: false,
  setRecordSubmitted: (val: boolean) => {},
  lootSubmitted: false,
  setLootSubmitted: (val: boolean) => {},
  studentSubmitted: false,
  setStudentSubmitted: (val: boolean) => {},
  submitting: false,
  setSubmitting: (val: boolean) => {},
  loading: true,
  setLoading: (val: boolean) => {},
});
const ThemeContextConsumer = ThemeContext.Consumer;
interface ThemeContextProviderProps {
  children: JSX.Element;
}

interface ThemeState {
  open: boolean;
  message: string;
}
const ThemeContextProvider = (
  props: ThemeContextProviderProps
): JSX.Element => {
  const cookies = new Cookies();
  const [mode, setMode] = React.useState(
    cookies.get("dark") ? "dark" : "white"
  );

  const [state, setState] = React.useState({ open: false, message: "" });
  const [mentorSubmitted, setMentorSubmitted] = React.useState(false);
  const [recordSubmitted, setRecordSubmitted] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [lootSubmitted, setLootSubmitted] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [studentSubmitted, setStudentSubmitted] = React.useState(false);
  const handleClose = () => setState({ ...state, open: false });
  const [themeColor, setThemeColor] = React.useState(
    cookies.get("dark") ? "#ffffff" : "#000000"
  );
  const toggleDarkTheme = () => {
    cookies.get("dark")
      ? cookies.remove("dark", { path: "/" })
      : cookies.set("dark", true, {
          path: "/",
          expires: new Date(
            new Date().setFullYear(new Date().getFullYear() + 1)
          ),
        });
    setMode(mode === "dark" ? "white" : "dark");
  };

  const theme = createMuiTheme({
    palette: {
      type: mode === "dark" ? "dark" : "light",
      primary: {
        main: themeColor,
      },
      secondary: {
        main: "#b71c1c",
      },
      background: {
        default: mode === "dark" ? "#333" : "#e5e5e5",
      },
    },
  });

  return (
    <ThemeContext.Provider
      value={{
        mode,
        setThemeColor,
        setMode,
        toggleDarkTheme,
        theme,
        state,
        setState,
        handleClose,
        submitting,
        setSubmitting,
        mentorSubmitted,
        setMentorSubmitted,
        recordSubmitted,
        setRecordSubmitted,
        lootSubmitted,
        setLootSubmitted,
        studentSubmitted,
        setStudentSubmitted,
        loading,
        setLoading,
      }}
    >
      {props.children}
    </ThemeContext.Provider>
  );
};
export { ThemeContextProvider, ThemeContextConsumer, ThemeContext };
