import React from "react";
import {
    List,
    Show,
    Pagination,
    Create,
    SimpleShowLayout,
    Datagrid,
    TextField,
    TextInput,
    Edit,
    SimpleForm,
    BooleanField,
    BooleanInput
} from "react-admin";

const BigPagination = (props) => (
    <Pagination rowsPerPageOptions={[25, 50, 100, 2000]} {...props} />
);

export const ClanList = (props) => (
    <List {...props} pagination={<BigPagination />} perPage={25} filter={{ id: props.clan.id }}>
        <Datagrid rowClick="show">
            <TextField source="name" />
            <TextField source="description" />
            <TextField source="slug" />
            <TextField source="clanColor" />
            <TextField source="clanColorGradient" />
            <TextField source="clanLogo" />
            <TextField source="clanHeaderImage" />
            <TextField source="clanBackgroundImage" />
            <TextField source="discordUrl" />
            <TextField source="forumUrl" />
            <TextField source="twitterUrl" />
        </Datagrid>
    </List>
);

export const ClanShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="name" />
            <TextField source="description" />
            <TextField source="slug" />
            <TextField source="clanColor" />
            <TextField source="clanColorGradient" />
            <TextField source="clanLogo" />
            <TextField source="clanHeaderImage" />
            <TextField source="clanBackgroundImage" />
            <TextField source="discordUrl" />
            <TextField source="forumUrl" />
            <TextField source="twitterUrl" />
            <BooleanField source="ironman" />
            <BooleanField source="normal" />
            <BooleanField source="hardcoreIronman" />
            <BooleanField source="ultimateIronman" />
        </SimpleShowLayout>
    </Show>
);

export const ClanEdit = (props) => (
    <Edit {...props} undoable={false}>
        <SimpleForm>
            <TextInput source="name" />
            <TextInput source="description" />
            <TextInput source="slug" />
            <TextInput source="clanColor" />
            <TextInput source="clanColorGradient" />
            <TextInput source="clanLogo" />
            <TextInput source="clanHeaderImage" />
            <TextInput source="clanBackgroundImage" />
            <TextInput source="discordUrl" />
            <TextInput source="forumUrl" />
            <TextInput source="twitterUrl" />
            <BooleanInput source="normal" />
            <BooleanInput source="ironman" />
            <BooleanInput source="hardcoreIronman" />
            <BooleanInput source="ultimateIronman" />
        </SimpleForm>
    </Edit>
);

export const ClanCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <TextInput source="description" />
            <TextInput source="slug" />
            <TextInput source="clanColor" />
            <TextInput source="clanColorGradient" />
            <TextInput source="clanLogo" />
            <TextInput source="clanHeaderImage" />
            <TextInput source="clanBackgroundImage" />
            <TextInput source="discordUrl" />
            <TextInput source="forumUrl" />
            <TextInput source="twitterUrl" />
            <BooleanInput source="normal" />
            <BooleanInput source="ironman" />
            <BooleanInput source="hardcoreIronman" />
            <BooleanInput source="ultimateIronman" />
        </SimpleForm>
    </Create>
);
