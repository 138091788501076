import React, { FunctionComponent } from "react";
import Card from "@material-ui/core/Card";
import { CardContent } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { Member } from "../../models";

interface ValueSplitCardProps {
  member: Member;
}

const ValueSplitCard: FunctionComponent<ValueSplitCardProps> = ({ member }) => {
  const { t } = useTranslation();
  const numberWithCommas = (x?: number) =>
    x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return (
    <Card>
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <Typography>{t("lookup.valueSplitCard.title")}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" component="h2">
              {`${numberWithCommas(member.valueSplit)} M`}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ValueSplitCard;
