import React from "react";
import {
  Filter,
  Pagination,
  List,
  Show,
  Create,
  Datagrid,
  TextField,
  SimpleForm,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
  Edit,
  SimpleShowLayout,
  NumberInput,
} from "react-admin";

const BigPagination = (props) => (
  <Pagination rowsPerPageOptions={[25, 50, 100, 2000]} {...props} />
);
const SearchFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput
      label="Mentor Program"
      source="MentorProgram_id"
      reference="MentorPrograms"
      filter={{ Clan_id: props.clan.id }}
      alwaysOn
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      label="Mentor"
      source="Member_id"
      reference="members"
      filter={{ Clan_id: props.clan.id }}
      alwaysOn
    >
      <AutocompleteInput optionText="username" />
    </ReferenceInput>
  </Filter>
);
export const TeacherList = (props) => (
  <List
    {...props}
    pagination={<BigPagination />}
    perPage={25}
    filters={<SearchFilter {...props} />}
    filter={{ Clan_id: props.clan.id }}
  >
    <Datagrid rowClick="show">
      <ReferenceField label="Mentor" source="Member_id" reference="members">
        <TextField source="username" />
      </ReferenceField>
      <ReferenceField
        label="Mentor Program"
        source="MentorProgram_id"
        reference="MentorPrograms"
      >
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
);

export const TeacherShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <ReferenceField label="Mentor" source="Member_id" reference="members">
        <TextField source="username" />
      </ReferenceField>
      <ReferenceField
        label="Mentor Program"
        source="MentorProgram_id"
        reference="MentorPrograms"
      >
        <TextField source="name" />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
);

export const TeacherEdit = (props) => (
  <Edit {...props} undoable={false}>
    <SimpleForm>
      <ReferenceInput
        label="Mentor Program"
        source="MentorProgram_id"
        reference="MentorPrograms"
        filter={{ Clan_id: props.clan.id }}
        alwaysOn
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        label="Mentor"
        source="Member_id"
        reference="members"
        filter={{ Clan_id: props.clan.id }}
        alwaysOn
      >
        <AutocompleteInput optionText="username" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

export const TeacherCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput
        label="Mentor Program"
        source="MentorProgram_id"
        reference="MentorPrograms"
        filter={{ Clan_id: props.clan.id }}
        alwaysOn
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        label="Mentor"
        source="Member_id"
        reference="members"
        filter={{ Clan_id: props.clan.id }}
        alwaysOn
      >
        <AutocompleteInput optionText="username" />
      </ReferenceInput>
      <NumberInput source="Clan_id" initialValue={props.clan.id} disabled />
    </SimpleForm>
  </Create>
);
