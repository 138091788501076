import React from "react";
import Card from "@material-ui/core/Card";
import { CardContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";

export default function PetBar({ unlockedPets }) {
  const { t } = useTranslation();
  const noPetImage = "https://gyazo.com/2a08c96b2dda6880cf048e6105527da3";
  const useStyles = makeStyles(() => ({
    pets: {
      height: "100%",
      textAlign: "center",
      margin: "10px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  }));
  const classes = useStyles();

  return unlockedPets.length > 0 ? (
    <Grid item xs={12} sm={12} md={12}>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography>{t("lookup.petBar.title")}</Typography>
            </Grid>
            {unlockedPets.map((pet) => (
              <Grid key={pet.id} item xs={6} sm={6} md={4} lg={2}>
                <Tooltip
                  title={pet.task.description}
                  placement={window.innerWidth > 959 ? "top" : "left"}
                >
                  <a
                    href={pet.screenshot || noPetImage}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Card className={classes.pets}>
                      <CardContent>
                        <img src={pet.task.artwork} alt={pet.task.name} />
                      </CardContent>
                    </Card>
                  </a>
                </Tooltip>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  ) : (
      <> </>
    );
}
