import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { UserContext } from "./contexts/userContext";
import Home from "./views/Home/Home";
import Login from "./views/Login/Login";
import Register from "./views/Register/Register";
import Requirements from "./views/Requirements/Requirements";
import HiScores from "./views/HiScores/HiScores";
import Items from "./views/Items/Items";
import LootLogger from "./views/LootLogger/LootLogger";
import MentorLogger from "./views/MentorLogger/MentorLogger";
import RecordLogger from "./views/RecordLogger/RecordLogger";
import Lookup from "./views/Lookup/Lookup";
import Apply from "./views/Apply/Apply";
import Admin from "./views/Admin/Admin";
import Events from "./views/Events/Events";
import Donate from "./views/Donate/Donate";
import Diary from "./views/Diary/Diary";
import Settings from "./views/Settings/Settings";
import Pets from "./views/Pets/Pets";
import Alts from "./views/Alts/Alts";
import Compare from "./views/Compare/Compare";
import Accolades from "./views/Accolades/Accolades";
import Suggestions from "./views/Suggestions/Suggestions";

export default function Routes() {
  const { user } = useContext(UserContext);

  const ProtectedRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        user.id ? (
          <Component {...props} />
        ) : (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          )
      }
    />
  );

  const AuthRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        user.id === undefined ? (
          <Component {...props} />
        ) : (
            <Redirect
              to={{
                pathname: `/lookup/${user.username}`,
                state: { from: props.location },
              }}
            />
          )
      }
    />
  );

  return (
    <>
      <ProtectedRoute
        exact
        path="/members/loot"
        component={() => <LootLogger />}
      />
      <ProtectedRoute
        exact
        path="/members/mentoring"
        component={() => <MentorLogger />}
      />
      <ProtectedRoute
        exact
        path="/members/record"
        component={() => <RecordLogger />}
      />
      <ProtectedRoute exact path="/members/diary" component={() => <Diary />} />
      <ProtectedRoute exact path="/members/accolades" component={() => <Accolades />} />
      <ProtectedRoute
        exact
        path="/members/settings"
        component={() => <Settings />}
      />
      <ProtectedRoute
        exact
        path="/members/suggestions"
        component={() => <Suggestions />}
      />
      <ProtectedRoute exact path="/members/pets" component={() => <Pets />} />
      <ProtectedRoute exact path="/members/alts" component={() => <Alts />} />
      <ProtectedRoute
        exact
        path="/members/compare"
        component={() => <Compare />}
      />
      <ProtectedRoute path="/admin" component={() => <Admin />} />
      <AuthRoute path="/login" component={() => <Login />} />
      <AuthRoute path="/register" component={() => <Register />} />
      <Route exact path="/lookup/:username" component={() => <Lookup />} />
      <Route exact path="/lookup" component={() => <Lookup />} />
      <Route path="/hiscores" component={() => <HiScores />} />
      <Route path="/items" component={() => <Items />} />
      <Route path="/events" component={() => <Events />} />
      <Route path="/requirements" component={() => <Requirements />} />
      <Route path="/apply" component={() => <Apply />} />
      <Route path="/donate" component={() => <Donate />} />
      <Route exact path="/" component={() => <Home />} />
    </>
  );
}
