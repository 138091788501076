import React, { useContext, FunctionComponent } from "react";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { useTranslation } from "react-i18next";
import Layout from "../../components/Layout/Layout";
import { UserContext } from "../../contexts/userContext";
import { ClanContext } from "../../contexts/clanContext";
import CenteredGrid from "../../components/Shared/CenteredGrid";

const LoginPage: FunctionComponent = () => {
  const { login } = useContext(UserContext);
  const { clan } = useContext(ClanContext);
  const baseUrl =
    !process.env.REACT_APP_STANDALONE_ID && clan?.id ? `/${clan.slug}` : "";
  const { t } = useTranslation();
  const [username, setUsername] = React.useState("");
  const [pin, setPin] = React.useState("");

  return (
    <Layout title={t("login.pageTitle")} auth>
      <form
        noValidate
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault();
          login(username, pin);
        }}
      >
        <CenteredGrid container spacing={3}>
          <>
            <Grid item xs={12}>
              <Typography variant="caption" paragraph>
                {t("login.subTitle")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                href="https://discord.gg/HvVup5K"
                target="_blank"
                variant="outlined"
              >
                {t("login.discordLink")}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t("login.usernameLabel")}
                id="username"
                fullWidth
                autoFocus
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                name="Username"
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t("login.pinLabel")}
                id="pin"
                fullWidth
                value={pin}
                onChange={(e) => setPin(e.target.value)}
                name="Pin"
                autoComplete="off"
                type="password"
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="outlined" type="submit">
                {t("login.logInButton")}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption" paragraph>
                {t("login.notSignedUp")}
                <Link color="primary" to={`${baseUrl}/register`}>
                  {t("login.registerLink")}
                </Link>
              </Typography>
            </Grid>
          </>
        </CenteredGrid>
      </form>
    </Layout>
  );
};

export default LoginPage;
