import React, { FunctionComponent, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Killcounts from "../../components/Hiscore/Killcount";
import Skills from "../../components/Hiscore/Skill";
import EHB from "../../components/Hiscore/EHB";
import Points from "../../components/Hiscore/Points";
import Time from "../../components/Hiscore/Time";
import VerticalTabs from "../../components/VerticalTabs/VerticalTabs";
import Layout from "../../components/Layout/Layout";
import { ClanContext } from "../../contexts/clanContext";
import { SubmissionContext } from "../../contexts/submissionContext";
import { HiscoreContextProvider } from "../../contexts/hiscoreContext";

const Hiscores: FunctionComponent = () => {
  const { clan } = useContext(ClanContext);
  const {
    bossesFetched,
    fetchAllBosses,
    recordsFetched,
    fetchRecords,
    skillsFetched,
    fetchSkills,
    pointCategoriesFetched,
    fetchPointCategories,
  } = useContext(SubmissionContext);
  const [image, setImage] = React.useState(clan.clanBackgroundImage);
  const { t } = useTranslation();

  useEffect(() => {
    if (clan && clan.id && !bossesFetched) {
      fetchAllBosses();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bossesFetched, clan]);

  useEffect(() => {
    if (clan && clan.id && !recordsFetched) {
      fetchRecords();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clan, recordsFetched]);

  useEffect(() => {
    if (clan && clan.id && !skillsFetched) {
      fetchSkills();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skillsFetched, clan]);

  useEffect(() => {
    if (clan && clan.id && !pointCategoriesFetched) {
      fetchPointCategories();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pointCategoriesFetched, clan]);
  return (
    <Layout
      title={t("hiscores.pageTitle")}
      background={image}
      loading={
        !bossesFetched ||
        !recordsFetched ||
        !skillsFetched ||
        !pointCategoriesFetched
      }
    >
      <HiscoreContextProvider>
        <VerticalTabs
          tabs={[
            {
              label: "Points",
              onClick: () => setImage(clan.clanBackgroundImage),
              component: <Points />,
            },
            {
              label: "KC",
              onClick: () => setImage(clan.clanBackgroundImage),
              component: <Killcounts updateImage={setImage} />,
            },
            {
              label: "EHB",
              onClick: () => setImage(clan.clanBackgroundImage),
              component: <EHB updateImage={setImage} />,
            },
            {
              label: "Time",
              onClick: () => setImage(clan.clanBackgroundImage),
              component: <Time updateImage={setImage} />,
            },
            {
              label: "Skills",
              onClick: () => setImage(clan.clanBackgroundImage),
              component: <Skills updateImage={setImage} />,
            },
          ]}
        />
      </HiscoreContextProvider>
    </Layout>
  );
};

export default Hiscores;
