import React, { useContext, FunctionComponent } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import Skeleton from "@material-ui/lab/Skeleton";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import NumberFormat from "../../components/NumberFormat/NumberFormat";
import CenteredGrid from "../../components/Shared/CenteredGrid";
import Layout from "../../components/Layout/Layout";
import { ThemeContext } from "../../contexts/themeContext";
import { UserContext } from "../../contexts/userContext";
import { ClanContext } from "../../contexts/clanContext";
import { Member, Boss, Item, Drop } from "../../models";
import { SubmissionContext } from "../../contexts/submissionContext";

const LootLogger: FunctionComponent = () => {
  const { t } = useTranslation();
  const { clan } = useContext(ClanContext);
  const {
    members,
    fetchMembers,
    altMembers,
    fetchAltMembers,
    createDrop,
    scanImage,
    imageDetails,
    setImageDetails,
    bosses,
    fetchBosses,
  } = useContext(SubmissionContext);
  const { lootSubmitted, setLootSubmitted, submitting } = useContext(
    ThemeContext
  );
  const [boss, setBoss] = React.useState({} as Boss);
  const [attack, setAttack] = React.useState({} as Member);
  const [tank, setTank] = React.useState({} as Member);
  const [alts, setAlts] = React.useState([] as Array<Member>);
  const [drop, setDrop] = React.useState({} as Item);
  const [attendees, setAttendees] = React.useState([] as Array<Member>);
  const [price, setPrice] = React.useState("");
  const [screenshot, setScreenshot] = React.useState("");
  const { user } = useContext(UserContext);
  const [dialogOpen, setDialogOpen] = React.useState(false);

  function handleDialogOpen() {
    setDialogOpen(true);
  }

  function handleDialogClose() {
    setDialogOpen(false);
  }

  const resetForm = () => {
    setScreenshot("");
    setPrice("");
    setAttendees([] as Array<Member>);
    setDrop({} as Item);
    setAlts([] as Array<Member>);
    setTank({} as Member);
    setAttack({} as Member);
    setBoss({} as Boss);
    setLootSubmitted(false);
    setImageDetails("");
  };

  const submitLoot = async () => {
    const dropObject = {
      Item_id: drop.id,
      attack: attack.id,
      value: price,
      screenshot,
      submitter: user.id,
      attendees: attendees.map((attendee) => attendee.username).join(", "),
      date: new Date(),
      Clan_id: clan.id,
    } as Drop;

    if (drop.attack) dropObject.pointsAttack = drop.attack;
    if (drop.tank) dropObject.pointsTank = drop.tank;
    if (drop.alt) dropObject.pointsAlt = drop.alt;
    if (tank) dropObject.tank = tank.id;
    if (alts.length > 0) dropObject.alts = alts.map((alt) => alt.id);
    if (attack && tank && attack.id === tank.id) dropObject.pointsAttack = 0;

    createDrop(dropObject);
  };

  const auto = () => {
    if (!imageDetails) {
      return false;
    }
    if (
      !imageDetails.toLowerCase().includes("public") ||
      !imageDetails.toLowerCase().includes("xp")
    ) {
      return false;
    }
    if (
      drop &&
      drop.name &&
      !imageDetails.toLowerCase().includes(drop.name.toLowerCase())
    ) {
      return false;
    }
    if (
      attack &&
      attack.username &&
      !imageDetails.toLowerCase().includes(attack.username.toLowerCase())
    ) {
      return false;
    }
    if (
      tank &&
      tank.username &&
      !imageDetails.toLowerCase().includes(tank.username.toLowerCase())
    ) {
      return false;
    }
    if (
      alts.find(
        (alt) =>
          alt &&
          alt.username &&
          !imageDetails.toLowerCase().includes(alt.username.toLowerCase())
      )
    ) {
      return false;
    }
    return true;
  };

  React.useEffect(() => {
    if (imageDetails) {
      bosses.forEach((boss) => {
        const drop = boss.drops.find(
          (drop) =>
            drop &&
            drop.name &&
            imageDetails.toLowerCase().includes(drop.name.toLowerCase())
        );
        if (drop) {
          setDrop(drop);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageDetails]);

  React.useEffect(() => {
    if (drop.id) {
      setBoss(
        bosses.find((boss) => boss.drops.find((x) => x.id === drop.id)) ||
          ({} as Boss)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drop]);

  return (
    <Layout
      title={t("lootLogger.title")}
      background={boss?.artwork || clan.clanBackgroundImage}
    >
      {!lootSubmitted ? (
        <Grid container justify='center' spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <Grid container justify='center' alignItems='center' spacing={3}>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  id='screenshot'
                  label={t("lootLogger.screenshotLabel")}
                  placeholder={t("lootLogger.screenshotPlaceholder")}
                  fullWidth
                  autoFocus
                  autoComplete='off'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  helperText={t("lootLogger.exampleText")}
                  value={screenshot}
                  onChange={(e) => setScreenshot(e.target.value)}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Grid container justify='center' spacing={3}>
              <Grid item xs={12} sm={12} md={6}>
                <Card>
                  {screenshot &&
                  (screenshot.includes(".png") ||
                    screenshot.includes(".jpg")) ? (
                    <img
                      style={{ width: 513, height: 300 }}
                      alt='screenie'
                      src={screenshot}
                      onError={(e) => {
                        (e.target as HTMLInputElement).src =
                          boss?.artwork || clan.clanBackgroundImage;
                      }}
                      onLoad={() => scanImage(screenshot)}
                    />
                  ) : (
                    <Skeleton
                      variant='rect'
                      animation='wave'
                      width={513}
                      height={300}
                    />
                  )}
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Grid container justify='center' spacing={3}>
                  {imageDetails ? (
                    <Grid item xs={12} sm={12} md={12}>
                      <Autocomplete
                        options={bosses.filter((boss) => boss.drops.length > 0)}
                        autoHighlight
                        autoSelect
                        value={boss}
                        getOptionLabel={(option) => option.name || ""}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label={t("lootLogger.bossLabel")}
                            placeholder={t("lootLogger.bossPlaceholder")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                        onChange={(event, boss) => {
                          setBoss(boss || ({} as Boss));
                        }}
                        onInputChange={(event, value) => {
                          fetchBosses(value);
                        }}
                      />

                      <Autocomplete
                        options={
                          boss
                            ? (boss.drops &&
                                boss.drops.filter(
                                  (x) => x.attack && x.attack > 0
                                )) ||
                              []
                            : []
                        }
                        autoHighlight
                        autoSelect
                        value={drop}
                        getOptionLabel={(option) => option.name || ""}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label={t("lootLogger.dropLabel")}
                            placeholder={t("lootLogger.dropPlaceholder")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                        onChange={(event, drop) => {
                          setDrop(drop || ({} as Item));
                        }}
                      />

                      <Autocomplete
                        options={members}
                        autoHighlight
                        autoSelect
                        getOptionLabel={(option) => option.username || ""}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label={t("lootLogger.recipientLabel")}
                            placeholder={t("lootLogger.recipientPlaceholder")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                        onChange={(event, attack) => {
                          setAttack(attack || {});
                        }}
                        onInputChange={(event, value) => {
                          fetchMembers(value);
                        }}
                      />

                      {drop && drop.tank && drop.tank > 0 && (
                        <Autocomplete
                          options={members}
                          autoHighlight
                          autoSelect
                          getOptionLabel={(option) => option.username || ""}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              label={t("lootLogger.tankLabel")}
                              placeholder={t("lootLogger.tankPlaceholder")}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                          onChange={(event, tank) => {
                            setTank(tank || {});
                          }}
                          onInputChange={(event, value) => {
                            fetchMembers(value);
                          }}
                        />
                      )}
                      <TextField
                        id='price'
                        label={t("lootLogger.priceLabel")}
                        placeholder={t("lootLogger.pricePlaceholder")}
                        fullWidth
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                        name='Price'
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          inputComponent: NumberFormat,
                        }}
                      />
                      <Divider />
                      {drop && drop.alt && drop.alt > 0 && (
                        <Autocomplete
                          multiple
                          options={altMembers}
                          autoHighlight
                          autoSelect
                          getOptionLabel={(option) => option.username || ""}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              label={t("lootLogger.altLabel")}
                              placeholder={t("lootLogger.altPlaceholder")}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                          onChange={(event, alts) => {
                            setAlts(alts || {});
                          }}
                          onInputChange={(event, value) => {
                            fetchAltMembers(value);
                          }}
                        />
                      )}
                      <Autocomplete
                        multiple
                        options={members}
                        autoHighlight
                        autoSelect
                        getOptionLabel={(option) => option.username || ""}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label={t("lootLogger.attendeeLabel")}
                            placeholder={t("lootLogger.attendeePlaceholder")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                        onChange={(event, attendees) => {
                          setAttendees(attendees || {});
                        }}
                        onInputChange={(event, value) => {
                          fetchMembers(value);
                        }}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={12} md={12}>
                      <Skeleton variant='text' />
                      <Skeleton variant='text' />
                      <Skeleton variant='text' />
                      <Skeleton variant='text' />
                      <Skeleton variant='text' />
                      <Skeleton variant='text' />
                      <Skeleton variant='text' />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={12} md={12}>
                    <Button
                      disabled={
                        !boss.id ||
                        !drop.id ||
                        !attack.id ||
                        price === "" ||
                        screenshot === "" ||
                        attendees.length === 0 ||
                        submitting
                      }
                      variant='outlined'
                      fullWidth
                      onClick={() =>
                        auto() ? submitLoot() : handleDialogOpen()
                      }
                    >
                      {t("lootLogger.submit")}
                    </Button>
                    <Dialog
                      open={dialogOpen}
                      onClose={handleDialogClose}
                      aria-labelledby='alert-dialog-title'
                      aria-describedby='alert-dialog-description'
                    >
                      <DialogTitle id='alert-dialog-title'>
                        {t("lootLogger.noAutoTitle")}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id='alert-dialog-description'>
                          {t("lootLogger.noAutoText")}
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleDialogClose} color='primary'>
                          {t("lootLogger.noAutoDisagree")}
                        </Button>
                        <Button
                          onClick={() => {
                            handleDialogClose();
                            submitLoot();
                          }}
                          color='primary'
                          autoFocus
                          disabled={submitting}
                        >
                          {t("lootLogger.noAutoAgree")}
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <CenteredGrid container spacing={3}>
          <>
            <Grid item xs={12}>
              <Typography variant='caption'>
                {t("lootLogger.success")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button variant='outlined' onClick={() => resetForm()}>
                {t("lootLogger.submitAnother")}
              </Button>
            </Grid>
          </>
        </CenteredGrid>
      )}
    </Layout>
  );
};

export default LootLogger;
