import React from "react";
import BadgeBar from "./BadgeBar"
import { useTranslation } from 'react-i18next';

const AccoladeBadgeBar = ({  memberBadges }) => {
    const { t } = useTranslation();
    let accoladeBadges = memberBadges.filter((task) => task.badge && !task.badge.name.includes("-"))
    const result = [];
    const map = new Map();
    for (const item of accoladeBadges) {
        if(!map.has(item.Badge_id)){
            map.set(item.Badge_id, true);   
            result.push(item.badge);
        }
    }
    return (
     <BadgeBar
        title={t('lookup.accoladeBadgeBar.title')}
        badges={result}
      />
    );
  }

export default AccoladeBadgeBar
