import React, { FunctionComponent } from "react";
import Card from "@material-ui/core/Card";
import { CardContent } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { Member } from "../../models";

interface MemberTimeCardProps {
  member: Member;
}

const MemberTimeCard: FunctionComponent<MemberTimeCardProps> = ({ member }) => {
  const { t } = useTranslation();
  const datetime = (currentdate?: Date) =>
    `${currentdate?.getDate()}/${
      (currentdate?.getMonth() || 0) + 1
    }/${currentdate?.getFullYear()}`;

  return (
    <Card>
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <Typography>{t("lookup.memberTimeCard.title")}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" component="h2">
              {datetime(new Date(member.joinDate || "1111-11-11"))}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default MemberTimeCard;
