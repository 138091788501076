const COLORS = ["#f44336",
"#ff9800",
"#ffeb3b",
"#4caf50",
"#2196f3",
"#3f51b5",
"#9c27b0",
"#e91e63",
];

export default COLORS;
