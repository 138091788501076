import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "./ListItem";
import List from "@material-ui/core/List";
import styles from "../../assets/jss/components/headerStyle";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import SchoolIcon from "@material-ui/icons/School";
import TimerIcon from "@material-ui/icons/Timer";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import PetsIcon from "@material-ui/icons/Pets";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import StarIcon from '@material-ui/icons/Star';
import { useTranslation } from "react-i18next";
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ListItemDefault from '@material-ui/core/ListItem';

export default function Submissions({ baseUrl, onClick }) {
    const useStyles = makeStyles(styles);
    const classes = useStyles();
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <List className={classes.list}>
            <ListItemDefault button onClick={handleClick}>
                <ListItemText primary={t("header.sidebar.submissions")} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemDefault>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem to={`${baseUrl}/members/loot`} text={t("header.sidebar.loot")} Icon={MonetizationOnIcon} onClick={onClick} />
                    <ListItem to={`${baseUrl}/members/mentoring`} text={t("header.sidebar.mentoring")} Icon={SchoolIcon} onClick={onClick} />
                    <ListItem to={`${baseUrl}/members/record`} text={t("header.sidebar.time")} Icon={TimerIcon} onClick={onClick} />
                    <ListItem to={`${baseUrl}/members/diary`} text={t("header.sidebar.diary")} Icon={MenuBookIcon} onClick={onClick} />
                    <ListItem to={`${baseUrl}/members/pets`} text={t("header.sidebar.pets")} Icon={PetsIcon} onClick={onClick} />
                    <ListItem to={`${baseUrl}/members/accolades`} text={t("header.sidebar.accolades")} Icon={StarIcon} onClick={onClick} />
                    <ListItem to={`${baseUrl}/members/alts`} text={t("header.sidebar.alts")} Icon={PeopleAltIcon} onClick={onClick} />
                </List>
            </Collapse>
        </List>
    )
}
