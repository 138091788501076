export const drawerWidth = 260;

export const primaryColor = "#da2400";
export const warningColor = "#ff9800";
export const dangerColor = "#f44336";
export const successColor = "#4caf50";
export const infoColor = "#00acc1";
export const roseColor = "#e91e63";

export const transition = {
  transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)",
};

export const boxShadow = {
  boxShadow:
    "0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
};

export const defaultFont = {
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: "300",
  lineHeight: "1.5em",
};

const styles = {
  textCenter: {
    textAlign: "center",
  },
  textLeft: {
    textAlign: "left",
  },
  divider: {
    marginTop: "30px",
    marginBottom: "0px",
    textAlign: "center",
  },
  main: {
    position: "relative",
    zIndex: "3",
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
  },
  footBlocker: {
    height: "40px",
  },
  dark: {
    background: "#333",
  },
  white: {
    background: "#fff",
  },
  section: {
    padding: "70px 0",
    textAlign: "center",
  },
  pageHeader: {
    background: "rgba(0, 0, 0, 0.5)",
    height: "100vh",
    width: "100vw",
    display: "inherit",
    position: "fixed",
    margin: "0",
    padding: "0",
    border: "0",
    backgroundSize: "cover",
    overflowX: "hidden",
    backgroundPosition: "top center",
    transition: "background-image 1s ease-in-out",
    alignItems: "center",
    "& footer li a,& footer li a:hover,& footer li a:active": {
      color: "#FFFFFF",
    },
    "& footer": {
      position: "absolute",
      bottom: "0",
      width: "100vw",
    },
  },
  cardHeader: {
    width: "auto",
    textAlign: "center",
    padding: "20px 0",
    borderRadius: "3px",
    border: "0",
    color: "#fff",
    boxShadow:
      "0 12px 20px -10px rgba(0, 0, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 0, 0, 0.2)",
  },
  cardFooter: {
    paddingTop: "0rem",
    border: "0",
    borderRadius: "6px",
    justifyContent: "center !important",
    display: "flex",
    alignItems: "center",
    backgroundColor: "transparent",
    padding: "0.9375rem 1.875rem",
  },
  container: {
    zIndex: "2",
    position: "relative",
    paddingTop: "24px",
    color: "#FFFFFF",
    paddingBottom: "24px",
  },
  cardContent: {
    padding: "0.9375rem 1.875rem",
    flex: "1 1 auto",
  },
  cardHeaderPlain: {
    marginLeft: "0px",
    marginRight: "0px",
  },
  appFrame: {
    width: "100vw",
  },
  media: {
    height: 140,
  },
  clanCard: {
    display: "flex",
  },
  clanMedia: {
    height: 200,
  },
};

export default styles;
