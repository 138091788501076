import React, { FunctionComponent } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import Layout from "../../components/Layout/Layout";

const Donate: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <Layout title={t("donate.pageTitle")}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="body1" gutterBottom>
            {t("donate.subTitle")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" component="h2" gutterBottom>
            {t("donate.discordHeader")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            {t("donate.discordParagraph")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" component="h2" gutterBottom>
            {t("donate.cashHeader")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">{t("donate.cashParagraph")}</Typography>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Donate;
