import React, { useContext, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Container from "@material-ui/core/Container";
import Hidden from "@material-ui/core/Hidden";
import { Link } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { HiscoreContext } from "../../contexts/hiscoreContext";
import { ClanContext } from "../../contexts/clanContext";

export default function HiScores({
  valueAttribute,
  usernameAttribute,
  label,
  valueLabel,
  overall,
  ehb,
  query,
  skill,
  points,
  times,
  community,
  pets,
  diaries,
}) {
  const headers = ["Rank", "Username", valueLabel];
  const { t } = useTranslation();
  const {
    fetchValues,
    setRowsPerPage,
    values,
    count,
    rowsPerPage,
  } = useContext(HiscoreContext);
  const [page, setPage] = useState(0);
  const { clan } = useContext(ClanContext);
  const baseUrl =
    !process.env.REACT_APP_STANDALONE_ID && clan?.id ? `/${clan.slug}` : "";

  React.useEffect(() => {
    fetchValues(
      overall,
      ehb,
      query,
      skill,
      points,
      community,
      pets,
      diaries,
      times,
      page
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div>
      <Hidden mdUp implementation='css'>
        <Container>
          <b>
            {label} {t("hiscores.hiscores.labelTitle")}
          </b>
          <Table aria-label='simple table'>
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableCell key={header}>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {values.length > 0 ? (
                values.map((value, index) => (
                  <TableRow key={value.id}>
                    <TableCell>
                      <Typography>{page * 10 + index + 1}.</Typography>
                    </TableCell>{" "}
                    <TableCell>
                      <Typography>
                        <Link
                          to={`${baseUrl}/lookup/${usernameAttribute(value)}`}
                          style={{ color: "unset" }}
                        >
                          {usernameAttribute(value)}
                        </Link>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{valueAttribute(value)} </Typography>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell>
                    <Skeleton height={460} />
                  </TableCell>
                  <TableCell>
                    <Skeleton height={460} />
                  </TableCell>
                  <TableCell>
                    <Skeleton height={460} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10]}
            component='div'
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Container>
      </Hidden>
      <Hidden smDown implementation='css'>
        <Container style={{ paddingLeft: "4rem" }}>
          <b>
            {label} {t("hiscores.hiscores.labelTitle")}
          </b>
          <Table aria-label='simple table'>
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableCell key={header}>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {values.length > 0 ? (
                values.map((value, index) => (
                  <TableRow key={value.id}>
                    <TableCell>
                      <Typography>{page * 10 + index + 1}.</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        <Link
                          to={`${baseUrl}/lookup/${usernameAttribute(value)}`}
                          style={{ color: "unset" }}
                        >
                          {usernameAttribute(value)}
                        </Link>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{valueAttribute(value)}</Typography>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell>
                    <Skeleton height={460} />
                  </TableCell>
                  <TableCell>
                    <Skeleton height={460} />
                  </TableCell>
                  <TableCell>
                    <Skeleton height={460} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {!times && (
            <TablePagination
              rowsPerPageOptions={[10]}
              component='div'
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )}
        </Container>
      </Hidden>
    </div>
  );
}
