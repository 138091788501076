import React from "react";
import {
  List,
  Show,
  Filter,
  UrlField,
  Pagination,
  Create,
  SimpleShowLayout,
  Datagrid,
  NumberField,
  TextField,
  NumberInput,
  TextInput,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
  Edit,
  SimpleForm,
  // TopToolbar
} from "react-admin";
// import { ImportButton } from "react-admin-import-csv";
// import { CreateButton } from "ra-ui-materialui";


// const ListActions = (props) => {
//   const { className, basePath, clan } = props;
//   const addClan = (rows) => {
//     let newRows = rows.map((row) => {
//       let newObj = { ...row }
//       newObj.Clan_id = clan.id
//       return newObj
//     })
//     return newRows
//   }

//   return (
//     <TopToolbar className={className}>
//       <CreateButton basePath={basePath} />
//       <ImportButton {...props} disableImportOverwrite parseConfig={{
//         dynamicTyping: true
//       }} transformRows={addClan} />
//     </TopToolbar>
//   );
// };
const BigPagination = (props) => (
  <Pagination rowsPerPageOptions={[25, 50, 100, 2000]} {...props} />
);
const SearchFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Item name" source="name" alwaysOn />
    <ReferenceInput
      label="Boss"
      source="Boss_id"
      reference="bosses"
      filter={{ Clan_id: props.clan.id }}
      allowEmpty
      alwaysOn
    >
      <AutocompleteInput optionText="name" allowEmpty emptyText="-- none --" />
    </ReferenceInput>
  </Filter>
);

export const ItemList = (props) => (
  <List
    {...props}
    filters={<SearchFilter {...props} />}
    pagination={<BigPagination />}
    filter={{ Clan_id: props.clan.id }}
    perPage={25}
    // actions={<ListActions {...props} />}
  >
    <Datagrid rowClick="show">
      <TextField source="name" />
      <UrlField source="artwork" />
      <NumberField source="attack" />
      <NumberField source="tank" />
      <NumberField source="alt" />
      <NumberField source="legacyAttack" />
      <NumberField source="legacyTank" />
      <NumberField source="legacyAlt" />
      <ReferenceField label="Boss" source="Boss_id" reference="bosses">
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
);

export const ItemShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <UrlField source="artwork" />
      <NumberField source="attack" />
      <NumberField source="tank" />
      <NumberField source="alt" />
      <NumberField source="legacyAttack" />
      <NumberField source="legacyTank" />
      <NumberField source="legacyAlt" />
      <ReferenceField label="Boss" source="Boss_id" reference="bosses">
        <TextField source="name" />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
);

export const ItemEdit = (props) => (
  <Edit {...props} undoable={false}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="artwork" />
      <NumberInput source="attack" />
      <NumberInput source="tank" />
      <NumberInput source="alt" />
      <NumberInput source="legacyAttack" />
      <NumberInput source="legacyTank" />
      <NumberInput source="legacyAlt" />
      <ReferenceInput
        label="Boss"
        source="Boss_id"
        reference="bosses"
        filter={{ Clan_id: props.clan.id }}
        allowEmpty
      >
        <AutocompleteInput
          optionText="name"
          allowEmpty
          emptyText="-- none --"
        />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

export const ItemCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="artwork" />
      <NumberInput source="attack" />
      <NumberInput source="tank" />
      <NumberInput source="alt" />
      <NumberInput source="legacyAttack" />
      <NumberInput source="legacyTank" />
      <NumberInput source="legacyAlt" />
      <ReferenceInput
        label="Boss"
        source="Boss_id"
        reference="bosses"
        filter={{ Clan_id: props.clan.id }}
        allowEmpty
      >
        <AutocompleteInput
          optionText="name"
          allowEmpty
          emptyText="-- none --"
        />
      </ReferenceInput>
      <NumberInput source="Clan_id" initialValue={props.clan.id} disabled />
    </SimpleForm>
  </Create>
);
