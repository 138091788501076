import React from "react";
import { Resource } from "react-admin";
import { EventList, EventShow, EventEdit, EventCreate } from "../models/Events";

const eventStaffRoutes = (clan) => [
  <Resource
    key="Events"
    name="Events"
    list={(props) => <EventList clan={clan} {...props} />}
    show={EventShow}
    edit={(props) => <EventEdit clan={clan} {...props} />}
    create={(props) => <EventCreate clan={clan} {...props} />}
  />,
  <Resource name="members" key="members" />,
  <Resource name="EventMembers" key="EventMembers" />,
];

export default eventStaffRoutes;
