/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect, useContext, useState } from "react";
import Card from "@material-ui/core/Card";
import { CardContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { SubmissionContext } from "../../contexts/submissionContext";

export default function StudentTasks({ record }) {
  const { fetchTasksCompleted, fetchTasks, fetchMentorProgram } = useContext(
    SubmissionContext
  );
  const [mentorProgramFetched, setMentorProgramFetched] = useState(false);
  const [mentorProgram, setMentorProgram] = useState({});
  const [tasksCompleted, setTasksCompleted] = useState(undefined);
  const [tasks, setTasks] = useState(undefined);
  const useStyles = makeStyles(() => ({
    pieCard: {
      display: "flex",
    },
  }));

  const classes = useStyles();
  // const validURL = (str) => {
  //     var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
  //         '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
  //         '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
  //         '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
  //         '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
  //         '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  //     return !!pattern.test(str);
  // }

  useEffect(() => {
    if (mentorProgramFetched) {
      const loadTasksCompleted = async () =>
        setTasksCompleted(await fetchTasksCompleted(record.Member_id));
      const loadTasks = async () =>
        setTasks(await fetchTasks(mentorProgram.Checklist_id));
      if (
        tasksCompleted === undefined &&
        tasks === undefined &&
        record &&
        record.Member_id &&
        record.MentorProgram_id
      ) {
        loadTasksCompleted();
        loadTasks();
      }
    } else {
      const loadMentorProgram = async () =>
        setMentorProgram(
          (await fetchMentorProgram(record.MentorProgram_id))[0]
        );
      console.log(record);
      if (record && record.MentorProgram_id) {
        loadMentorProgram();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tasksCompleted,
    record,
    tasks,
    fetchTasksCompleted,
    fetchTasks,
    mentorProgramFetched,
    fetchMentorProgram,
  ]);

  useEffect(() => {
    if (mentorProgram.id) {
      setMentorProgramFetched(true);
    }
  }, [mentorProgram]);

  const completeTaskButton = ({ task }) => {
    const taskCompleted =
      tasksCompleted &&
      tasksCompleted.find(
        (taskCompleted) =>
          taskCompleted.approved && taskCompleted.Task_id === task.id
      );
    return (
      <Button
        component={Link}
        disabled={taskCompleted}
        to={{
          pathname: "/TaskCompleted/create",
          state: {
            record: {
              approved: false,
              Task_id: task.id,
              Member_id: record && record.Member_id,
            },
          },
        }}
      >
        {taskCompleted ? "Completed" : "Needs Approval"}
      </Button>
    );
  };

  return (
    <Card className={classes.pieCard}>
      <CardContent>
        {record && (
          <>
            <div style={{ width: "100%", margin: "1em" }}>
              <Typography variant="h6">
                {tasks && tasks.length > 0 ? "Tasks" : "No application"}
              </Typography>
              {tasks && (
                <Table aria-label="Alt list" style={{ width: "100%" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Task</TableCell>
                      <TableCell>Completed</TableCell>
                      <TableCell>Screenshot</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tasks.map((task) => (
                      <TableRow key={task.id}>
                        <TableCell component="th" scope="row">
                          {task.name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {completeTaskButton({ task })}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {tasksCompleted &&
                          tasksCompleted.find(
                            (taskCompleted) =>
                              taskCompleted.approved &&
                              taskCompleted.Task_id === task.id
                          )
                            ? tasksCompleted.find(
                                (taskCompleted) =>
                                  taskCompleted.approved &&
                                  taskCompleted.Task_id === task.id
                              ).screenshot
                            : ""}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </div>
          </>
        )}
      </CardContent>
    </Card>
  );
}
