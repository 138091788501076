import React from "react";
import {
  Filter,
  Pagination,
  TabbedShowLayout,
  Tab,
  TabbedForm,
  FormTab,
  List,
  Show,
  Create,
  Datagrid,
  BooleanField,
  TextField,
  TextInput,
  ReferenceField,
  BooleanInput,
  ReferenceInput,
  AutocompleteInput,
  Edit,
  NumberInput,
} from "react-admin";

const BigPagination = (props) => (
  <Pagination rowsPerPageOptions={[25, 50, 100, 2000]} {...props} />
);
const SearchFilter = (props) => (
  <Filter {...props}>
    <TextInput source="name" alwaysOn />
    <ReferenceInput
      label="Checklist"
      source="Checklist_id"
      reference="Checklists"
      filter={{ Clan_id: props.clan.id }}
      alwaysOn
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
  </Filter>
);
export const TaskList = (props) => (
  <List
    {...props}
    pagination={<BigPagination />}
    perPage={25}
    filters={<SearchFilter {...props} />}
    filter={{ Clan_id: props.clan.id }}
  >
    <Datagrid rowClick="show">
      <TextField source="name" />
      <TextField source="description" />
      <TextField source="artwork" />
      <ReferenceField
        label="Checklist"
        source="Checklist_id"
        reference="Checklists"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="Autocompleted by"
        source="parenttask"
        reference="Tasks"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Badge" source="Badge_id" reference="Badges">
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
);

export const TaskShow = (props) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="Task">
        <TextField source="name" />
        <TextField source="description" />
        <TextField source="artwork" />
        <ReferenceField
          label="Checklist"
          source="Checklist_id"
          reference="Checklists"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Autocompleted by"
          source="parenttask"
          reference="Tasks"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Badge" source="Badge_id" reference="Badges">
          <TextField source="name" />
        </ReferenceField>
        <BooleanField source="screenshot" label="Require a screenshot?" />
        <BooleanField source="approved" label="Require approval?" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export const TaskEdit = (props) => (
  <Edit {...props} undoable={false}>
    <TabbedForm>
      <FormTab label="Task">
        <TextInput source="name" />
        <TextInput multiline source="description" />
        <TextInput source="artwork" />
        <ReferenceInput
          label="Checklist"
          source="Checklist_id"
          reference="Checklists"
          filter={{ Clan_id: props.clan.id }}
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          label="Autocompleted by"
          source="parenttask"
          reference="Tasks"
          filter={{ Clan_id: props.clan.id }}
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput label="Badge" source="Badge_id" reference="Badges" filter={{ Clan_id: props.clan.id }}>
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <BooleanInput source="screenshot" label="Require a screenshot?" />
        <BooleanInput source="approved" label="Require approval?" />
      </FormTab>
    </TabbedForm>
  </Edit>
);

export const TaskCreate = (props) => (
  <Create {...props}>
    <TabbedForm>
      <FormTab label="Task">
        <TextInput source="name" />
        <TextInput multiline source="description" />
        <TextInput source="artwork" />
        <ReferenceInput label="Badge" source="Badge_id" reference="Badges" filter={{ Clan_id: props.clan.id }}>
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          label="Checklist"
          source="Checklist_id"
          reference="Checklists"
          filter={{ Clan_id: props.clan.id }}
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          label="Autocompleted by"
          source="parenttask"
          reference="Tasks"
          filter={{ Clan_id: props.clan.id }}
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <BooleanInput source="screenshot" label="Require a screenshot?" />
        <BooleanInput source="approved" label="Require approval?" />
        <NumberInput source="Clan_id" initialValue={props.clan.id} disabled />
      </FormTab>
    </TabbedForm>
  </Create>
);
