import React from 'react';
import { Resource } from 'react-admin';
import { EventList, EventShow, EventEdit, EventCreate } from '../models/Events';
import { TaskShow, TaskEdit, TaskCreate } from '../models/Tasks';
import {
  TaskCompletedShow,
  TaskCompletedEdit,
  TaskCompletedCreate,
} from '../models/TaskCompleted';
import {
  MentorProgramList,
  MentorProgramShow,
  MentorProgramEdit,
} from '../models/MentorPrograms';
import {
  StudentList,
  StudentShow,
  StudentEdit,
  StudentCreate,
} from '../models/Students';

const clanMentorEventRoutes = (clan, mentorProgramIds) => [
  <Resource name="members" key="members" />,
  <Resource key="Mentors" name="Mentors" />,
  <Resource key="Badges" name="Badges" />,
  <Resource
    key="Events"
    name="Events"
    list={(props) => <EventList clan={clan} {...props} />}
    show={EventShow}
    edit={(props) => <EventEdit clan={clan} {...props} />}
    create={(props) => <EventCreate clan={clan} {...props} />}
  />,
  <Resource name="EventMembers" key="EventMembers" />,
  <Resource
    options={{ label: 'Mentor Programs' }}
    key="MentorPrograms"
    name="MentorPrograms"
    list={(props) => <MentorProgramList clan={clan} {...props} />}
    show={MentorProgramShow}
    edit={(props) => <MentorProgramEdit clan={clan} {...props} />}
  />,
  <Resource key="Checklists" name="Checklists" />,
  <Resource
    key="Tasks"
    name="Tasks"
    show={TaskShow}
    edit={(props) => <TaskEdit clan={clan} {...props} />}
    create={(props) => <TaskCreate clan={clan} {...props} />}
  />,
  <Resource key="Teachers" name="Teachers" />,
  <Resource
    key="Students"
    name="Students"
    list={(props) => (
      <StudentList clan={clan} mentorProgramIds={mentorProgramIds} {...props} />
    )}
    show={StudentShow}
    edit={(props) => <StudentEdit clan={clan} {...props} />}
    create={(props) => <StudentCreate clan={clan} {...props} />}
  />,
  <Resource
    key="TaskCompleted"
    name="TaskCompleted"
    options={{ label: 'Tasks Completed' }}
    show={TaskCompletedShow}
    create={(props) => <TaskCompletedCreate clan={clan} {...props} />}
    edit={(props) => <TaskCompletedEdit clan={clan} {...props} />}
  />,
];

export default clanMentorEventRoutes;
