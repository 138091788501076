import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import { CardContent } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from 'react-i18next';
import { ThemeContext } from "../../contexts/themeContext";
import { UserContext } from "../../contexts/userContext";
import { SubmissionContext } from "../../contexts/submissionContext";

const QuickToggles = () => {
  const { refreshKc } = useContext(SubmissionContext);
  const { user } = useContext(UserContext);
  const { mode, setMode, toggleDarkTheme } = React.useContext(ThemeContext);
  const { t } = useTranslation();

  const updateChecked = () => setMode(mode === "dark" ? "white" : "dark");

  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography>
              {t("settings.quickToggles.title")}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={2}>
            <Button
              variant="outlined"
              onClick={() => {
                toggleDarkTheme();
                updateChecked();
              }}
            >
              {`${mode === "dark" ? t("global.mode.light") : t("global.mode.dark")} ${t("global.mode.title")}`}
            </Button>
          </Grid>
          {user.id && (
            <Grid item xs={6} sm={6} md={2}>
              <Button
                variant="outlined"
                onClick={() => refreshKc({ username: user.username, id: user.id })}
              >
                {t("settings.quickToggles.kcButton")}
              </Button>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  )
};

export default QuickToggles;
