import React, { FunctionComponent, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import CenteredGrid from "../../components/Shared/CenteredGrid";
import { MentorProgram } from "../../models";
import Submission from "./Submission";
import { Divider } from "@material-ui/core";
import Mentoring from "./Mentoring";
import { UserContext } from "../../contexts/userContext";
import Overview from "./Overview";

interface MentorContainerProps {
  mentorProgram: MentorProgram;
}
const MentorContainer: FunctionComponent<MentorContainerProps> = ({
  mentorProgram,
}) => {
  const { user } = useContext(UserContext);
  const isTeacher =
    mentorProgram.teachers &&
    mentorProgram.teachers.map((teacher) => teacher.id).includes(user.id);
  const isStudent =
    mentorProgram.students &&
    mentorProgram.students.filter(student => student.active).map((student) => student.id).includes(user.id);

  return (
    <CenteredGrid container spacing={3}>
      {isStudent || isTeacher ? (
        <>
          <Grid item xs={12}>
            <Submission mentorProgram={mentorProgram} />
          </Grid>
          {(mentorProgram.active || isTeacher) && (
            <>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Mentoring mentorProgram={mentorProgram} />
              </Grid>
            </>
          )}
        </>
      ) : (
          <>
            <Grid item xs={12}>
              <Overview mentorProgram={mentorProgram} />
            </Grid>
          </>
        )}
    </CenteredGrid>
  );
};
export default MentorContainer;
