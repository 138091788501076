import Axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

const API = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

const refreshAuthLogic = () => API.get('Members/refreshJwt')
  .then(() => Promise.resolve())
  .catch(() => Promise.reject());

createAuthRefreshInterceptor(API, refreshAuthLogic);

export default API;
