import React, { useState, createContext, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router-dom";
import API from "../helpers/api";
import { ThemeContext } from "./themeContext";
import { ClanContext } from "./clanContext";
import {
  Drop,
  Killcount,
  Member,
  Mentor,
  Rank,
  Time,
  Stat,
  PointCategory,
  Question,
  Event,
  Boss,
  Skill,
  Checklist,
  MentorProgram,
  Student,
  Clan,
  Record,
  Suggestion,
  Item,
} from "../models";

const SubmissionContext = createContext({
  ranksFetched: false as boolean,
  eventsFetched: false as boolean,
  statsFetched: false as boolean,
  pointCategoriesFetched: false as boolean,
  questionsFetched: false as boolean,
  bossesFetched: false as boolean,
  timesFetched: false as boolean,
  recordsFetched: false as boolean,
  skillsFetched: false as boolean,
  checklistsFetched: false as boolean,
  mentorProgramsFetched: false as boolean,
  imageDetails: "",
  stats: {} as Stat,
  ranks: [] as Array<Rank>,
  requirements: [] as Array<Rank>,
  members: [] as Array<Member>,
  clans: [] as Array<Clan>,
  bosses: [] as Array<Boss>,
  bossesWithDrops: [] as Array<Boss>,
  mentorPrograms: [] as Array<MentorProgram>,
  events: [] as Array<Event>,
  futureEvents: [] as Array<Event>,
  skills: [] as Array<Skill>,
  times: [] as Array<Time>,
  records: [] as Array<Record>,
  checklists: [] as Array<Checklist>,
  pointCategories: [] as Array<PointCategory>,
  questions: [] as Array<Question>,
  killcounts: [] as Array<Killcount>,
  timezoneChoices: [] as Array<string>,
  altMembers: [] as Array<Member>,
  fetchMembers: (query: string) => { },
  fetchClans: (query: string) => { },
  fetchAltMembers: (query: string) => { },
  fetchAndAppendKillcounts: (ids?: Array<number | undefined>) => { },
  createMentor: (val: Mentor) => { },
  createRecord: (val: Time) => { },
  createDrop: (val: Drop) => { },
  createStudent: (val: Student) => { },
  createSuggestion: (val: Suggestion) => { },
  refreshKc: (val: Member) => { },
  scanImage: (val: string) => { },
  resetPoints: (val: number) => { },
  resetActivity: (val: number) => { },
  attendEvent: (userId: number, eventId: number) => { },
  fetchResponses: (val: number) => { },
  fetchTasksCompleted: (val: number) => { },
  fetchMentorProgram: (val: number) => { },
  fetchTasks: (val: number) => { },
  fetchMember: (query: string) => { },
  fetchRanks: () => { },
  fetchEvents: () => { },
  fetchStats: () => { },
  fetchPointCategories: () => { },
  fetchQuestions: () => { },
  fetchBosses: (query: string) => { },
  fetchAllBosses: () => { },
  fetchTimes: () => { },
  fetchRecords: () => { },
  fetchSkills: () => { },
  fetchChecklists: () => { },
  fetchMentorPrograms: () => { },
  setImageDetails: (query: string) => { },
  promoteHere: (val: number) => { },
  promoteEveryone: (val: number) => { },
  promoteNone: (val: number) => { },
  postDraft: (val: number) => { },
  fetchStarsStats: () => { },
});

const SubmissionContextConsumer = SubmissionContext.Consumer;

interface SubmissionContextProviderProps {
  children: JSX.Element;
}

const SubmissionContextProvider = ({
  children,
}: SubmissionContextProviderProps): JSX.Element => {
  const route = useRouteMatch("/:clanSlug");
  const { t } = useTranslation();
  const [ranksFetched, setRanksFetched] = useState(false);
  const [eventsFetched, setEventsFetched] = useState(false);
  const [statsFetched, setStatsFetched] = useState(false);
  const [pointCategoriesFetched, setPointCategoriesFetched] = useState(false);
  const [questionsFetched, setQuestionsFetched] = useState(false);
  const [bossesFetched, setBossesFetched] = useState(false);
  const [timesFetched, setTimesFetched] = useState(false);
  const [recordsFetched, setRecordsFetched] = useState(false);
  const [skillsFetched, setSkillsFetched] = useState(false);
  const [checklistsFetched, setChecklistsFetched] = useState(false);
  const [mentorProgramsFetched, setMentorProgramsFetched] = useState(false);
  const {
    setThemeColor,
    setState,
    setMentorSubmitted,
    setRecordSubmitted,
    setLootSubmitted,
    setSubmitting,
    setStudentSubmitted,
  } = useContext(ThemeContext);
  const { clan } = useContext(ClanContext);
  const [stats, setStats] = React.useState({} as Stat);
  const [bosses, setBosses] = useState([] as Array<Boss>);
  const [bossesWithDrops, setBossesWithDrops] = useState([] as Array<Boss>);
  const [mentorPrograms, setMentorPrograms] = useState(
    [] as Array<MentorProgram>
  );
  const [skills, setSkills] = useState([] as Array<Skill>);
  const [times, setTimes] = useState([] as Array<Time>);
  const [records, setRecords] = useState([] as Array<Record>);
  const [checklists, setChecklists] = useState([] as Array<Checklist>);
  const [ranks, setRanks] = React.useState([] as Array<Rank>);
  const [requirements, setRequirements] = React.useState([] as Array<Rank>);
  const [events, setEvents] = React.useState([] as Array<Event>);
  const [futureEvents, setFutureEvents] = React.useState([] as Array<Event>);
  const [questions, setQuestions] = React.useState([] as Array<Question>);
  const [pointCategories, setPointCategories] = React.useState(
    [] as Array<PointCategory>
  );
  const [members, setMembers] = React.useState([] as Array<Member>);
  const [clans, setClans] = React.useState([] as Array<Clan>);
  const [altMembers, setAltMembers] = React.useState([] as Array<Member>);
  const [imageDetails, setImageDetails] = React.useState("");
  const [killcounts, setKillcounts] = useState([] as Array<Killcount>);
  const timezoneChoices = [
    "MIT Midway Islands Time GMT-11:00",
    "HST Hawaii Standard Time GMT-10:00",
    "AST Alaska Standard Time GMT-9:00",
    "PST Pacific Standard Time GMT-8:00",
    "PNT Phoenix Standard Time GMT-7:00",
    "MST Mountain Standard Time GMT-7:00",
    "CST Central Standard Time GMT-6:00",
    "EST Eastern Standard Time GMT-5:00",
    "IET Indiana Eastern Standard Time GMT-5:00",
    "PRT Puerto Rico and US Virgin Islands Time GMT-4:00",
    "CNT Canada Newfoundland Time GMT-3:30",
    "AGT Argentina Standard Time GMT-3:00",
    "BET Brazil Eastern Time GMT-3:00",
    "CAT Central African Time GMT-1:00",
    "GMT Greenwich Mean Time GMT+0:00",
    "ECT European Central Time GMT+1:00",
    "EET Eastern European Time GMT+2:00",
    "ART (Arabic) Egypt Standard Time GMT+2:00",
    "EAT Eastern African Time GMT+3:00",
    "MET Middle East Time GMT+3:30",
    "NET Near East Time GMT+4:00",
    "PLT Pakistan Lahore Time GMT+5:00",
    "IST India Standard Time GMT+5:30",
    "GMT Bangladesh Standard Time GMT+6:00",
    "VST Vietnam Standard Time GMT+7:00",
    "CTT China Taiwan Time GMT+8:00",
    "JST Japan Standard Time GMT+9:00",
    "ACT Australia Central Time GMT+9:30",
    "AET Australia Eastern Time GMT+10:00",
    "SST Solomon Standard Time GMT+11:00",
    "NST New Zealand Standard Time GMT+12:00",
  ];

  const fetchMembers = async (query: string) => {
    if (query.length > 0) {
      const newMembers = (
        (await API.getAll("Members", {
          active: 1,
          Clan_id: clan.id,
          username: query,
          _start: 0,
          _end: 10,
        })) || []
      ).concat(members);
      const uniqueMembers: Array<Member> = Array.from(
        new Set(newMembers.map((a: Member) => a.id))
      ).map((id) => newMembers.find((a: Member) => a.id === id));
      setMembers(uniqueMembers);
    }
  };

  const fetchClans = async (query: string) => {
    if (query.length > 0) {
      const newClans = (
        (await API.getAll("Clans", {
          name: query,
          _start: 0,
          _end: 10,
        })) || []
      ).concat(clans);
      const uniqueClans: Array<Clan> = Array.from(
        new Set(newClans.map((a: Clan) => a.id))
      ).map((id) => newClans.find((a: Clan) => a.id === id));
      setClans(uniqueClans);
    }
  };

  const fetchAltMembers = async (query: string) => {
    if (query.length > 0) {
      let newMembers = (
        (await API.getAll("Members", {
          Rank_id: 15,
          username: query,
          Clan_id: clan.id,
          _start: 0,
          _end: 4,
        })) || []
      ).concat(altMembers);
      newMembers = (
        (await API.getAll("Members", {
          Rank_id: 18,
          username: query,
          Clan_id: clan.id,
          _start: 0,
          _end: 4,
        })) || []
      ).concat(newMembers);
      const uniqueMembers = Array.from(
        new Set(newMembers.map((a: Member) => a.id))
      ).map((id) => newMembers.find((a: Member) => a.id === id));
      setAltMembers(uniqueMembers);
    }
  };

  const refreshKc = async (userObject: Member) => {
    await API.update("Killcounts", userObject);
    setState({
      open: true,
      message: t("settings.quickToggles.kcRefreshMessage"),
    });
  };

  const createMentor = async (mentorObject: Mentor) => {
    setSubmitting(true);
    const response = await API.create("Mentors", mentorObject);

    if (response.status === 201) {
      setMentorSubmitted(true);
    } else {
      setState({
        open: true,
        message: t("mentorLogger.failure"),
      });
    }
    setSubmitting(false);
  };

  const createRecord = async (recordObject: Time) => {
    setSubmitting(true);
    const response = await API.create("Times", recordObject);

    if (response.status === 201) {
      setRecordSubmitted(true);
    } else {
      setState({
        open: true,
        message: t("recordLogger.failure"),
      });
    }
    setSubmitting(false);
  };

  const createDrop = async (dropObject: Drop) => {
    setSubmitting(true);
    const response = await API.create("Drops", dropObject);

    if (response.status === 201) {
      setLootSubmitted(true);
    } else {
      setState({
        open: true,
        message: t("lootLogger.failure"),
      });
    }
    setSubmitting(false);
  };

  const createStudent = async (studentObject: Student) => {
    setSubmitting(false);
    const response = await API.create("Students", studentObject);

    if (response.status === 201) {
      setStudentSubmitted(true);
    } else {
      setState({
        open: true,
        message: t("mentorLogger.studentSuccess"),
      });
    }
    setSubmitting(true);
  };

  const createSuggestion = async (suggestion: Suggestion) =>
    await API.create("Suggestions", suggestion);

  const resetPoints = async (id: number) => {
    return API.resetPoints(id);
  };

  const resetActivity = async (id: number) => {
    return API.create("PointTransactions", { date: new Date(), Member_id: id, value: 0, Clan_id: clan.id });
  };

  const attendEvent = async (userId: number, eventId: number) => {
    await API.create("EventMembers", { Member_id: userId, Event_id: eventId, approved: false });
    fetchEvents()
  };

  const promoteHere = async (id: number) => {
    return API.promoteHere(id);
  };

  const promoteEveryone = async (id: number) => {
    return API.promoteEveryone(id);
  };

  const promoteNone = async (id: number) => {
    return API.promoteNone(id);
  };

  const postDraft = async (id: number) => {
    return API.postDraft(id);
  };

  const scanImage = async (screenshot: string) => {
    try {
      setImageDetails((await API.scanImage(screenshot)) || "failed");
    } catch {
      setState({
        open: true,
        message: t("lootLogger.failure"),
      });
    }
  };

  const fetchResponses = async (id: number) =>
    (await API.getAll("Responses", { Member_id: id, _start: 0, _end: 5000 })) ||
    [];

  const fetchTasksCompleted = async (id: number) =>
    (await API.getAll("TaskCompleted", {
      Member_id: id,
      _start: 0,
      _end: 5000,
    })) || [];

  const fetchTasks = async (id: number) =>
    (await API.getAll("Tasks", { Checklist_id: id, _start: 0, _end: 5000 })) ||
    [];

  const fetchMentorProgram = async (id: number) =>
    (await API.getAll("MentorPrograms", { id: id, _start: 0, _end: 5000 })) ||
    [];

  const fetchStarsStats = async () => {
    const response = await API.fetchStarStats();

    return response.data;
  };

  const fetchMember = async (username: string) =>
    (await API.getByUsername(username)) || [];

  const fetchAndAppendKillcounts = async (ids?: Array<number | undefined>) => {
    const res: Array<Array<Killcount>> = await Promise.all(
      ids?.map((id) => API.fetchKillcounts(id || 0)) || []
    );
    if (res.length > 0) {
      setKillcounts([...res.flat()]);
    }
  };

  const fetchRanks = async () => {
    try {
      const ranks = (await API.getAll("Ranks", {
        Clan_id: clan.id,
        _start: 0,
        _end: 5000,
        _sort: "order",
        _order: "ASC",
      })) || []
      setRanks(ranks);
      setRequirements(ranks.filter((rank: Rank) => rank.order === 0 || rank.order).map((rank: Rank) => ({
        ...rank,
        items: rank.items.sort((a: Item, b: Item) => ((a.name || '') > (b.name || '') ? 1 : -1)),
      })))
    } catch {
      setRanks([] as Array<Rank>);
    }
    setRanksFetched(true);
  };

  const fetchStats = async () => {
    try {
      setStats(
        (
          await API.getAll("Stats", {
            Clan_id: clan.id,
            _start: 0,
            _end: 5000,
          })
        )[0] || ({} as Stat)
      );
      setStatsFetched(true);
    } catch {
      setStats({} as Stat);
      setStatsFetched(true);
    }
  };

  const fetchEvents = async () => {
    try {
      const events = await API.getAll("Events", {
        Clan_id: clan.id,
        _start: 0,
        _end: 10,
        _sort: "date",
        _order: "DESC",
      }) || ([] as Array<Event>)
      setEvents(events.filter((event: Event) => new Date(event.date) < new Date()));
      setFutureEvents(events.filter((event: Event) => new Date(event.date) > new Date()));
    } catch {
      setEvents([] as Array<Event>);
    }
    setEventsFetched(true);
  };

  const fetchPointCategories = async () => {
    try {
      setPointCategories(
        (await API.getAll("PointCategories", {
          Clan_id: clan.id,
          _start: 0,
          _end: 5000,
        })) || ([] as Array<PointCategory>)
      );
    } catch {
      setPointCategories([] as Array<PointCategory>);
    }
    setPointCategoriesFetched(true);
  };

  const fetchQuestions = async () => {
    try {
      setQuestions(
        (await API.getAll("Questions", {
          Clan_id: clan.id,
          _start: 0,
          _end: 5000,
          _order: "ASC",
          _sort: "order",
          active: 1,
        })) || ([] as Array<Question>)
      );
    } catch {
      setQuestions([] as Array<Question>);
    }
    setQuestionsFetched(true);
  };

  const fetchAllBosses = async () => {
    try {
      const bosses = await API.getAll("Bosses", {
        Clan_id: clan.id,
        _start: 0,
        _end: 5000,
        _order: "ASC",
        _sort: "name",
      })
      let bossesWithDrops = bosses.filter((boss: Boss) => boss.drops.length > 0)
      setBosses(bosses);
      setBossesWithDrops(bossesWithDrops.map((boss: Boss) => ({
        ...boss,
        drops: boss.drops.filter((drop) => (drop.attack || 0) > 0).sort((a, b) => (a.name || '') > (b.name || '') ? 1 : -1)
          .sort((a, b) => (a.attack || 0) - (b.attack || 0)),
      })
      ));
    } catch {
      setBosses([] as Array<Boss>);
    }
    setBossesFetched(true);
  };

  const fetchBosses = async (query: string) => {
    if (query.length > 0) {
      const newBosses = (
        (await API.getAll("Bosses", {
          name: query,
          Clan_id: clan.id,
          _start: 0,
          _end: 10,
        })) || []
      ).concat(bosses);
      const uniqueBosses: Array<Boss> = Array.from(
        new Set(newBosses.map((a: Boss) => a.id))
      ).map((id) => newBosses.find((a: Boss) => a.id === id));
      setBosses(uniqueBosses);
    }
  };

  const fetchSkills = async () => {
    try {
      setSkills(
        await API.getAll("Skills", {
          Clan_id: 1,
          _start: 0,
          _end: 5000,
          _order: "ASC",
          _sort: "name",
        })
      );
    } catch {
      setSkills([]);
    }
    setSkillsFetched(true);
  };

  const fetchTimes = async () => {
    try {
      setTimes(
        (await API.getAll("Times", {
          Clan_id: clan.id,
          _start: 0,
          _end: 5000,
        })) || []
      );
    } catch {
      setTimes([]);
    }
    setTimesFetched(true);
  };

  const fetchRecords = async () => {
    try {
      setRecords(
        (await API.getAll("Records", {
          Clan_id: clan.id,
          _start: 0,
          _end: 5000,
        })) || []
      );
    } catch {
      setRecords([]);
    }
    setRecordsFetched(true);
  };

  const fetchChecklists = async () => {
    try {
      setChecklists(
        (await API.getAll("Checklists", {
          Clan_id: clan.id,
          _start: 0,
          _end: 5000,
        })) || []
      );
    } catch {
      setChecklists([]);
    }
    setChecklistsFetched(true);
  };

  const fetchMentorPrograms = async () => {
    try {
      setMentorPrograms(
        await API.getAll("MentorPrograms", {
          Clan_id: clan.id,
          _start: 0,
          _end: 5000,
          _order: "ASC",
          _sort: "name",
        })
      );
    } catch {
      setMentorPrograms([] as Array<MentorProgram>);
    }
    setMentorProgramsFetched(true);
  };

  useEffect(() => {
    if (!process.env.REACT_APP_STANDALONE_ID) {
      if (clan && clan?.id && !route?.params?.clanSlug) {
        setStats({} as Stat);
        setRanks([] as Array<Rank>);
        setRequirements([] as Array<Rank>);
        setEvents([] as Array<Event>);
        setFutureEvents([] as Array<Event>);
        setPointCategories([] as Array<PointCategory>);
        setQuestions([] as Array<Question>);
        setMembers([] as Array<Member>);
        setBosses([] as Array<Boss>);
        setBossesWithDrops([] as Array<Boss>);
        setMentorPrograms([] as Array<MentorProgram>);
        setSkills([] as Array<Skill>);
        setTimes([] as Array<Time>);
        setRecords([] as Array<Record>);
        setChecklists([] as Array<Checklist>);
        setKillcounts([] as Array<Killcount>);
        setAltMembers([] as Array<Member>);
        setRanksFetched(false);
        setEventsFetched(false);
        setStatsFetched(false);
        setPointCategoriesFetched(false);
        setQuestionsFetched(false);
        setBossesFetched(false);
        setTimesFetched(false);
        setImageDetails("");
        setSkillsFetched(false);
        setChecklistsFetched(false);
        setMentorProgramsFetched(false);
        setThemeColor("#000000");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route?.params]);

  return (
    <SubmissionContext.Provider
      value={{
        bosses,
        bossesWithDrops,
        skills,
        times,
        records,
        checklists,
        ranksFetched,
        eventsFetched,
        statsFetched,
        pointCategoriesFetched,
        questionsFetched,
        bossesFetched,
        timesFetched,
        recordsFetched,
        skillsFetched,
        checklistsFetched,
        mentorProgramsFetched,
        mentorPrograms,
        stats,
        ranks,
        requirements,
        events,
        futureEvents,
        pointCategories,
        questions,
        timezoneChoices,
        members,
        fetchMembers,
        fetchClans,
        createSuggestion,
        clans,
        altMembers,
        fetchAltMembers,
        createMentor,
        createRecord,
        createDrop,
        createStudent,
        refreshKc,
        scanImage,
        resetPoints,
        resetActivity,
        attendEvent,
        fetchResponses,
        fetchTasksCompleted,
        fetchTasks,
        fetchMentorProgram,
        imageDetails,
        fetchStarsStats,
        fetchMember,
        fetchRanks,
        fetchEvents,
        fetchStats,
        fetchPointCategories,
        fetchQuestions,
        fetchAllBosses,
        fetchBosses,
        fetchTimes,
        fetchRecords,
        fetchSkills,
        fetchChecklists,
        fetchMentorPrograms,
        promoteHere,
        promoteEveryone,
        promoteNone,
        postDraft,
        killcounts,
        fetchAndAppendKillcounts,
        setImageDetails,
      }}
    >
      {children}
    </SubmissionContext.Provider>
  );
};

export {
  SubmissionContextProvider,
  SubmissionContextConsumer,
  SubmissionContext,
};
