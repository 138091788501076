import React, { FunctionComponent, useContext, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import LoadingIndicators from "../../components/Settings/LoadingIndicators";
import Layout from "../../components/Layout/Layout";
import QuickToggles from "../../components/Settings/QuickToggles";
import { ClanContext } from "../../contexts/clanContext";
import { SubmissionContext } from "../../contexts/submissionContext";

const Settings: FunctionComponent = () => {
  const { t } = useTranslation();
  const { clan } = useContext(ClanContext);
  const { fetchChecklists, checklistsFetched } = useContext(SubmissionContext);

  useEffect(() => {
    if (clan && clan.id && !checklistsFetched) {
      fetchChecklists();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checklistsFetched]);

  return (
    <Layout loading={!checklistsFetched} title={t("settings.pageTitle")}>
      <Grid container justify="center" spacing={3}>
        <Grid item xs={12}>
          <QuickToggles />
        </Grid>
        <Grid item xs={12}>
          <LoadingIndicators />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Settings;
