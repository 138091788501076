import React, { useContext, useState, Fragment, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Container from "@material-ui/core/Container";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Snackbar from "@material-ui/core/Snackbar";
import styles from "../../assets/jss/global";
import { ThemeContext } from "../../contexts/themeContext";
import { ClanContext } from "../../contexts/clanContext";

export default function Layout({
  title = null,
  children,
  background = null,
  auth = false,
  loading = false,
  helperText = null,
}) {
  const { handleClose, state } = useContext(ThemeContext);
  const { clan, clanFetched } = useContext(ClanContext);
  const { setLoading } = useContext(ThemeContext);
  const { message, open } = state;
  const [displayedBackground, setDisplayedBackground] = useState(background ? background : clan.clanBackgroundImage);
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  useEffect(() => {
    var image = new Image();
    if (background === null && displayedBackground == null && clan.clanBackgroundImage) {
      image.src = clan.clanBackgroundImage;
    } else if (background !== displayedBackground) {
      image.src = background;
    }
    image.onload = () => { setDisplayedBackground(image.src) }
  }, [background, displayedBackground, clan.clanBackgroundImage])

  useEffect(() => {
    setLoading((loading || !clanFetched))
  }, [loading, clanFetched, setLoading])

  return (
    <Box>
      {(loading || !clanFetched) ? (
        <div />
      ) : (
          <>
            <Box
              className={classes.pageHeader}
              style={{
                backgroundImage: `url(${displayedBackground})`,
              }}
            />
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              key="vertical: top, horizontal: center"
              open={open}
              onClose={handleClose}
              ContentProps={{
                "aria-describedby": "message-id",
              }}
              message={message}
            />
            <Container className={classes.container}>
              <Grid container justify="center">
                <Grid item xs={12} sm={12} md={auth ? 4 : 12}>
                  <Card>
                    {title ? (
                      <>
                        <Typography className={classes.cardHeader} style={{ background: clan.clanColorGradient }}>
                          {title}
                        </Typography>
                        <CardContent className={classes.cardContent}>
                          {helperText ? (
                            <Grid container justify="center" spacing={3}>
                              <Grid item>
                                <Typography variant="caption" align="center">
                                  {helperText}
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                {children}
                              </Grid>
                            </Grid>
                          ) : (
                              children
                            )}
                        </CardContent>
                      </>
                    ) : (
                        <Fragment>{children}</Fragment>
                      )}
                  </Card>
                </Grid>
              </Grid>
            </Container>
          </>
        )}
    </Box>
  );
}
