import React, { useContext, FunctionComponent } from "react";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import { CardContent } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../contexts/userContext";

const AltList: FunctionComponent = () => {
  const { alts } = useContext(UserContext);
  const { t } = useTranslation();

  return (
    <Card>
      <CardContent>
        <Grid container justify="center">
          <Grid item xs={12}>
            <Typography>{t("alts.altList.title")}</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Table aria-label="Alt list" style={{ width: "100%" }}>
              <TableHead>
                <TableRow>
                  <TableCell>{t("alts.altList.usernameHeader")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {alts.map((alt) => (
                  <TableRow key={alt.id}>
                    <TableCell component="th" scope="row">
                      {alt.username}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AltList;
