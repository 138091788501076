import React, { useContext } from "react";
import VerticalTabs from "../VerticalTabs/VerticalTabs";
import Hiscores from "./Hiscores";
import { SubmissionContext } from "../../contexts/submissionContext";

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const Skill = ({ updateImage }) => {
  const { skills } = useContext(SubmissionContext);

  const tabs = skills.map((skill) => ({
    label: skill.name,
    component: (
      <Hiscores
        skill
        key={skill.id}
        label={skill.name}
        valueLabel='Experience'
        queryModel='skills'
        query={{ Skill_id: skill.id }}
        valueAttribute={(x) => numberWithCommas(x.value || 0)}
        usernameAttribute={(x) => x.member && x.member.username}
      />
    ),
  }));

  const overallTab = tabs.find((x) => x.label === "Overall");
  tabs.splice(
    tabs.findIndex((x) => x.label === "Overall"),
    1
  );
  tabs.unshift(overallTab);

  return <VerticalTabs tabs={tabs} />;
};

export default Skill;
