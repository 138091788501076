import React, { FunctionComponent, useContext, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useTranslation } from "react-i18next";
import Layout from "../../components/Layout/Layout";
import { SubmissionContext } from "../../contexts/submissionContext";
import { TextField } from "@material-ui/core";
import { Member, Boss } from "../../models";
import CompareTable from "../../components/Compare/CompareTable";

const Compare: FunctionComponent = () => {
  const { t } = useTranslation();
  const { members: fetchedMembers, bosses: fetchedBosses, fetchMembers, fetchBosses } = useContext(SubmissionContext);
  const [members, setMembers] = useState([] as Array<Member>);
  const [bosses, setBosses] = useState([] as Array<Boss>);

  return (
    <Layout title={t("compare.pageTitle")}>
      <Grid container justify="center" spacing={3}>
        <Grid item xs={6}>
          <Autocomplete
            multiple
            options={fetchedMembers}
            autoHighlight
            autoSelect
            getOptionLabel={(option: Member) => option.username || ""}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label={t("compare.searchLabel")}
                placeholder={t("compare.searchPlaceholder")}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
            onChange={(event, member) => {
              setMembers(member || {});
            }}
            onInputChange={(event, value) => {
              fetchMembers(value);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            multiple
            options={fetchedBosses}
            autoHighlight
            autoSelect
            getOptionLabel={(option: Boss) => option.name || ""}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label={t("compare.bossLabel")}
                placeholder={t("compare.bossPlaceholder")}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
            onChange={(event, boss) => {
              setBosses(boss || {});
            }}
            onInputChange={(event, value) => {
              fetchBosses(value);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <CompareTable members={members} bosses={bosses} />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Compare;
