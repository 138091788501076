import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: "en",
    ns: "translation",
    debug: process.env.NODE_ENV !== "production",
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: `${process.env.REACT_APP_API_URL}/Languages?Clan_id=${process.env.REACT_APP_STANDALONE_ID || 1}`,
      parse: (data) => (JSON.parse(data)[0].content),
    },
  });

export default i18n;
