import React, { Fragment, useContext, useState } from "react";
import {
  List,
  Filter,
  TopToolbar,
  SelectInput,
  ReferenceManyField,
  TabbedShowLayout,
  useUpdate,
  Tab,
  Button,
  DateField,
  ImageField,
  DateInput,
  Confirm,
  useRefresh,
  useNotify,
  Pagination,
  Show,
  Create,
  EditButton,
  Datagrid,
  BooleanField,
  NumberField,
  TextField,
  NumberInput,
  TextInput,
  ReferenceField,
  BooleanInput,
  ReferenceInput,
  AutocompleteInput,
  Edit,
  SimpleForm,
} from "react-admin";
import PointsWheel from "../PointsWheel"
import BasicDetails from "../BasicDetails"
import ApplicationResponses from "../ApplicationResponses"
import { SubmissionContext } from "../../../contexts/submissionContext";

const ResetPoints = ({ record }) => {
  const [open, setOpen] = useState(false);
  const { resetPoints } = useContext(SubmissionContext);
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const resetUserPoints = () => {
    resetPoints(record.id)
      .then(() => {
        refresh();
        notify("Points reset!");
      })
      .catch(() => notify("Error: Points not reset", "warning"));
  };

  const handleConfirm = () => {
    resetUserPoints();
    setOpen(false);
  };

  return (
    <>
      <Button label="Reset Points" onClick={handleClick} />
      <Confirm
        isOpen={open}
        title="Reset Points"
        content="Are you sure you want to reset this member's points? This literally can never ever be undone."
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};

const BumpActivity = ({ record }) => {
  const [open, setOpen] = useState(false);
  const { resetActivity } = useContext(SubmissionContext);
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const resetUserPoints = () => {
    resetActivity(record.id)
      .then(() => {
        refresh();
        notify("Activity timer reset!");
      })
      .catch(() => notify("Error: Activity timer not reset", "warning"));
  };

  const handleConfirm = () => {
    resetUserPoints();
    setOpen(false);
  };

  return (
    <>
      <Button label="Reset Activity Timer" onClick={handleClick} />
      <Confirm
        isOpen={open}
        title="Reset Activity"
        content="Are you sure you want to reset this member's activity timer? This will reset the 90 day inactivity timer for this particular member."
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};

const ActivateAccount = ({ record }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const [approve, { loading }] = useUpdate(
    "Members",
    record ? record.id : null,
    { active: true, gearcheck: true },
    record,
    {
      onSuccess: () => {
        refresh();
        notify("Account activated!");
      },
      onFailure: (error) => notify(`Error: ${error.message}`, "warning"),
    }
  );
  return <Button label="Activate Account" onClick={approve} disabled={loading} />;
};

const MemberShowActions = ({ basePath, data, props }) => (
  <TopToolbar>
    <EditButton basePath={basePath} record={data} />
    {/* Add your custom actions */}
    <ActivateAccount {...props} record={data} />
    <ResetPoints {...props} record={data} />
    <BumpActivity {...props} record={data} />
  </TopToolbar>
);

const timezoneChoices = [
  {
    id: "MIT Midway Islands Time GMT-11:00",
    name: "MIT Midway Islands Time GMT-11:00",
  },
  {
    id: "HST Hawaii Standard Time GMT-10:00",
    name: "HST Hawaii Standard Time GMT-10:00",
  },
  {
    id: "AST Alaska Standard Time GMT-9:00",
    name: "AST Alaska Standard Time GMT-9:00",
  },
  {
    id: "PST Pacific Standard Time GMT-8:00",
    name: "PST Pacific Standard Time GMT-8:00",
  },
  {
    id: "PNT Phoenix Standard Time GMT-7:00",
    name: "PNT Phoenix Standard Time GMT-7:00",
  },
  {
    id: "MST Mountain Standard Time GMT-7:00",
    name: "MST Mountain Standard Time GMT-7:00",
  },
  {
    id: "CST Central Standard Time GMT-6:00",
    name: "CST Central Standard Time GMT-6:00",
  },
  {
    id: "EST Eastern Standard Time GMT-5:00",
    name: "EST Eastern Standard Time GMT-5:00",
  },
  {
    id: "IET Indiana Eastern Standard Time GMT-5:00",
    name: "IET Indiana Eastern Standard Time GMT-5:00",
  },
  {
    id: "PRT Puerto Rico and US Virgin Islands Time GMT-4:00",
    name: "PRT Puerto Rico and US Virgin Islands Time GMT-4:00",
  },
  {
    id: "CNT Canada Newfoundland Time GMT-3:30",
    name: "CNT Canada Newfoundland Time GMT-3:30",
  },
  {
    id: "AGT Argentina Standard Time GMT-3:00",
    name: "AGT Argentina Standard Time GMT-3:00",
  },
  {
    id: "BET Brazil Eastern Time GMT-3:00",
    name: "BET Brazil Eastern Time GMT-3:00",
  },
  {
    id: "CAT Central African Time GMT-1:00",
    name: "CAT Central African Time GMT-1:00",
  },
  {
    id: "GMT Greenwich Mean Time GMT+0:00",
    name: "GMT Greenwich Mean Time GMT+0:00",
  },
  {
    id: "ECT European Central Time GMT+1:00",
    name: "ECT European Central Time GMT+1:00",
  },
  {
    id: "EET Eastern European Time GMT+2:00",
    name: "EET Eastern European Time GMT+2:00",
  },
  {
    id: "ART (Arabic) Egypt Standard Time GMT+2:00",
    name: "ART (Arabic) Egypt Standard Time GMT+2:00",
  },
  {
    id: "EAT Eastern African Time GMT+3:00",
    name: "EAT Eastern African Time GMT+3:00",
  },
  {
    id: "MET Middle East Time GMT+3:30",
    name: "MET Middle East Time GMT+3:30",
  },
  { id: "NET Near East Time GMT+4:00", name: "NET Near East Time GMT+4:00" },
  {
    id: "PLT Pakistan Lahore Time GMT+5:00",
    name: "PLT Pakistan Lahore Time GMT+5:00",
  },
  {
    id: "IST India Standard Time GMT+5:30",
    name: "IST India Standard Time GMT+5:30",
  },
  {
    id: "GMT Bangladesh Standard Time GMT+6:00",
    name: "GMT Bangladesh Standard Time GMT+6:00",
  },
  {
    id: "VST Vietnam Standard Time GMT+7:00",
    name: "VST Vietnam Standard Time GMT+7:00",
  },
  {
    id: "CTT China Taiwan Time GMT+8:00",
    name: "CTT China Taiwan Time GMT+8:00",
  },
  {
    id: "JST Japan Standard Time GMT+9:00",
    name: "JST Japan Standard Time GMT+9:00",
  },
  {
    id: "ACT Australia Central Time GMT+9:30",
    name: "ACT Australia Central Time GMT+9:30",
  },
  {
    id: "AET Australia Eastern Time GMT+10:00",
    name: "AET Australia Eastern Time GMT+10:00",
  },
  {
    id: "SST Solomon Standard Time GMT+11:00",
    name: "SST Solomon Standard Time GMT+11:00",
  },
  {
    id: "NST New Zealand Standard Time GMT+12:00",
    name: "NST New Zealand Standard Time GMT+12:00",
  },
];
const SearchFilter = (props) => (
  <Filter {...props}>
    <TextInput source="username" alwaysOn />
    <SelectInput source="timezone" choices={timezoneChoices} alwaysOn />
    <ReferenceInput label="Rank" source="Rank_id" reference="Ranks" alwaysOn filter={{ Clan_id: props.clan.id }}>
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <DateInput source="joinDate" alwaysOn />
    <ReferenceInput
      label="Recruited By"
      source="recruitedBy"
      reference="members"
      filter={{ Clan_id: props.clan.id }}
      alwaysOn
    >
      <AutocompleteInput optionText="username" />
    </ReferenceInput>
    <ReferenceInput
      label="Parent Account"
      source="parentAccount"
      reference="members"
      filter={{ Clan_id: props.clan.id }}
      alwaysOn
    >
      <AutocompleteInput optionText="username" />
    </ReferenceInput>
    <TextInput source="discordId" alwaysOn />
  </Filter>
);
const BigPagination = (props) => (
  <Pagination rowsPerPageOptions={[25, 50, 100, 2000]} {...props} />
);
const Aside = ({ record }) => (
  <div style={{ marginLeft: "24px", width: "350px" }}>
    <div style={{ marginBottom: "24px" }}>
      <BasicDetails record={record} />
    </div>
    <div style={{ marginBottom: "24px" }}>
      <PointsWheel record={record} />
    </div>
    <div style={{ marginBottom: "24px" }}>
      <ApplicationResponses record={record} />
    </div>
  </div>
);

export const MemberList = (props) => (
  <List
    {...props}
    perPage={25}
    filters={<SearchFilter {...props} />}
    pagination={<BigPagination />}
    filter={{ Clan_id: props.clan.id }}
    sort={{ field: "joinDate", order: "DESC" }}
  >
    <Datagrid rowClick="show">
      <TextField source="username" />
      <ReferenceField label="Rank" source="Rank_id" reference="Ranks">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="timezone" />
      <NumberField label="Total Points" source="overallPoints" />
      <NumberField label="PvM Points" source="pvmPoints" />
      <NumberField label="Community Points" source="nonPvmPoints" />
      <NumberField label="Alt Points" source="altPoints" />
      <BooleanField source="active" />
      <BooleanField source="gearcheck" />
      <DateField source="joinDate" />
      <TextField source="discordId" />
    </Datagrid>
  </List>
);

export const MemberShow = (props) => (
  <Show
    aside={<Aside {...props} />}
    {...props}
    actions={<MemberShowActions {...props} />}
  >
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="username" />
        <TextField source="timezone" />
        <ReferenceField label="Rank" source="Rank_id" reference="Ranks">
          <TextField source="name" />
        </ReferenceField>
        <BooleanField source="active" />
        <BooleanField source="gearcheck" />
        <BooleanField source="mentor" />
        <TextField source="comments" />
        <DateField source="joinDate" />
        <ReferenceField
          label="Recruited By"
          source="recruitedBy"
          reference="members"
        >
          <TextField source="username" />
        </ReferenceField>
        <ReferenceField label="Main" source="parentAccount" reference="members">
          <TextField source="username" />
        </ReferenceField>
        <TextField source="previousUsername" />
        <BooleanField source="normal" />
        <BooleanField source="ironman" />
        <BooleanField source="hardcoreIronman" />
        <BooleanField source="ultimateIronman" />
      </Tab>
      <Tab label="Drops">
        <ReferenceManyField reference="drops" target="attack">
          <Datagrid>
            <ReferenceField label="Attack" source="attack" reference="members">
              <TextField source="username" />
            </ReferenceField>
            <ReferenceField label="Tank" source="tank" reference="members">
              <TextField source="username" />
            </ReferenceField>
            <ReferenceField label="Alt" source="alt" reference="members">
              <TextField source="username" />
            </ReferenceField>
            <ReferenceField label="Item" source="Item_id" reference="items">
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="Admin" source="admin" reference="members">
              <TextField source="username" />
            </ReferenceField>
            <DateField source="date" />
            <NumberField label="Attack Points" source="pointsAttack" />
            <NumberField label="Tank Points" source="pointsTank" />
            <ImageField source="screenshot" />
            <BooleanField source="approved" />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="Drops as tanks">
        <ReferenceManyField reference="drops" target="tank">
          <Datagrid>
            <ReferenceField label="Attack" source="attack" reference="members">
              <TextField source="username" />
            </ReferenceField>
            <ReferenceField label="Tank" source="tank" reference="members">
              <TextField source="username" />
            </ReferenceField>
            <ReferenceField label="Alt" source="alt" reference="members">
              <TextField source="username" />
            </ReferenceField>
            <ReferenceField label="Item" source="Item_id" reference="items">
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="Admin" source="admin" reference="members">
              <TextField source="username" />
            </ReferenceField>
            <DateField source="date" />
            <NumberField label="Attack Points" source="pointsAttack" />
            <NumberField label="Tank Points" source="pointsTank" />
            <ImageField source="screenshot" />
            <BooleanField source="approved" />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="Events hosted">
        <ReferenceManyField reference="Events" target="Member_id">
          <Datagrid rowClick="show">
            <TextField source="name" />
            <DateField source="date" />
            <TextField source="description" />
            <NumberField source="points" />
            <ReferenceField label="Host" source="Member_id" reference="members">
              <TextField source="username" />
            </ReferenceField>
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="Events attended">
        <ReferenceManyField reference="EventMembers" target="Member_id">
          <Datagrid rowClick="show">
            <ReferenceField
              label="Member"
              source="Member_id"
              reference="members"
            >
              <TextField source="username" />
            </ReferenceField>
            <ReferenceField label="Event" source="Event_id" reference="Events">
              <TextField source="name" />
            </ReferenceField>
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="App responses">
        <ReferenceManyField reference="Responses" target="Member_id">
          <Datagrid rowClick="show">
            <TextField source="response" />
            <ReferenceField
              label="Question"
              source="Question_id"
              reference="Questions"
            >
              <TextField source="question" />
            </ReferenceField>
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export const MemberEdit = (props) => (
  <Edit {...props} undoable={false}>
    <SimpleForm>
      <TextInput source="username" />
      <TextInput source="discordId" />
      <SelectInput source="timezone" choices={timezoneChoices} />
      <ReferenceInput label="Rank" source="Rank_id" reference="Ranks" filter={{ Clan_id: props.clan.id }}>
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <BooleanInput source="active" />
      <BooleanInput source="gearcheck" />
      <BooleanInput source="mentor" />
      <BooleanInput source="isAdmin" />
      <BooleanInput source="isEventStaff" />
      <TextInput multiline source="comments" />
      <TextInput source="pin" />
      <DateInput source="joinDate" />
      <ReferenceInput
        label="Recruited By"
        source="recruitedBy"
        reference="members"
        filter={{ Clan_id: props.clan.id }}
        allowEmpty
      >
        <AutocompleteInput
          optionText="username"
          allowEmpty
          emptyText="-- none --"
        />
      </ReferenceInput>
      <ReferenceInput
        label="Parent Account"
        source="parentAccount"
        reference="members"
        filter={{ Clan_id: props.clan.id }}
        allowEmpty
      >
        <AutocompleteInput
          optionText="username"
          allowEmpty
          emptyText="-- none --"
        />
      </ReferenceInput>
      <TextInput source="previousUsername" />
      <BooleanInput source="normal" />
      <BooleanInput source="ironman" />
      <BooleanInput source="hardcoreIronman" />
      <BooleanInput source="ultimateIronman" />
    </SimpleForm>
  </Edit>
);

export const MemberCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="username" />
      <SelectInput source="timezone" choices={timezoneChoices} />
      <ReferenceInput label="Rank" source="Rank_id" reference="Ranks" filter={{ Clan_id: props.clan.id }}>
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <BooleanInput source="active" />
      <BooleanInput source="gearcheck" />
      <BooleanInput source="mentor" />
      <NumberInput source="legacyBumps" />
      <NumberInput source="legacyevents" />
      <NumberInput source="legacymentor" />
      <NumberInput source="legacyrecruits" />
      <NumberInput source="legacyhelper" />
      <TextInput multiline source="comments" />
      <TextInput source="pin" />
      <DateInput source="joinDate" />
      <ReferenceInput
        label="Recruited By"
        source="recruitedBy"
        reference="members"
        filter={{ Clan_id: props.clan.id }}
        allowEmpty
      >
        <AutocompleteInput
          optionText="username"
          allowEmpty
          emptyText="-- none --"
        />
      </ReferenceInput>
      <ReferenceInput
        label="Parent Account"
        source="parentAccount"
        reference="members"
        filter={{ Clan_id: props.clan.id }}
        allowEmpty
      >
        <AutocompleteInput
          optionText="username"
          allowEmpty
          emptyText="-- none --"
        />
      </ReferenceInput>
      <BooleanInput source="normal" />
      <BooleanInput source="ironman" />
      <BooleanInput source="hardcoreIronman" />
      <BooleanInput source="ultimateIronman" />
      <NumberInput source="Clan_id" initialValue={props.clan.id} disabled />
    </SimpleForm>
  </Create>
);
