import React, {
  useContext,
  FunctionComponent,
  ChangeEvent,
  FormEvent,
} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "../../contexts/themeContext";
import { UserContext } from "../../contexts/userContext";

const AddAlt: FunctionComponent = () => {
  const { user, addAlt } = useContext(UserContext);
  const { setState } = useContext(ThemeContext);
  const { t } = useTranslation();
  const [screenshot, setScreenshot] = React.useState("");
  const [newAlt, setNewAlt] = React.useState("");
  const submitAlt = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (newAlt === "") {
      return setState({
        open: true,
        message: t("alts.addAlt.missingNameError"),
      });
    }
    if (screenshot === "") {
      return setState({
        open: true,
        message: t("alts.addAlt.missingScreenshotError"),
      });
    }

    addAlt({
      username: newAlt,
      timezone: user.timezone,
      joinDate: new Date().toISOString(),
      parentAccount: user.id,
      Rank_id: 15,
      comments: screenshot,
      Clan_id: user.Clan_id
    });
  };

  return (
    <Card>
      <form noValidate autoComplete="off" onSubmit={submitAlt}>
        <CardContent>
          <Grid container justify="center" spacing={3}>
            <Grid item xs={12}>
              <Typography>{t("alts.addAlt.title")}</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="alts"
                label={t("alts.addAlt.usernameLabel")}
                fullWidth
                onChange={(
                  e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => setNewAlt(e.target.value)}
                type="text"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="alts"
                label={t("alts.addAlt.screenshotLabel")}
                fullWidth
                onChange={(
                  e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => setScreenshot(e.target.value)}
                type="text"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <Button variant="outlined" type="submit">
                {t("alts.addAlt.addButton")}
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </form>
    </Card>
  );
};

export default AddAlt;
