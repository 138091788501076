import React from "react";
import {
  Filter,
  Pagination,
  TabbedShowLayout,
  Tab,
  TabbedForm,
  AutocompleteArrayInput,
  FormTab,
  ReferenceArrayInput,
  ReferenceArrayField,
  List,
  Show,
  Create,
  Datagrid,
  TextField,
  TextInput,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
  Edit,
  NumberInput,
} from "react-admin";

const BigPagination = (props) => (
  <Pagination rowsPerPageOptions={[25, 50, 100, 2000]} {...props} />
);
const SearchFilter = (props) => (
  <Filter {...props}>
    <TextInput source="name" alwaysOn />
    <TextField source="type" />
  </Filter>
);
export const ChecklistList = (props) => (
  <List
    {...props}
    pagination={<BigPagination />}
    perPage={25}
    filters={<SearchFilter {...props} />}
    filter={{ Clan_id: props.clan.id }}
  >
    <Datagrid rowClick="show">
      <TextField source="name" />
      <TextField source="description" />
      <TextField source="type" />
    </Datagrid>
  </List>
);

export const ChecklistShow = (props) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="Checklist">
        <TextField source="name" />
        <TextField source="description" />
        <TextField source="type" />
        <ReferenceField label="Badge" source="Badge_id" reference="Badges">
          <TextField source="name" />
        </ReferenceField>
      </Tab>
      <Tab label="tasks" path="tasks">
        <ReferenceArrayField source="tasks" reference="Tasks">
          <Datagrid>
            <TextField source="name" />
          </Datagrid>
        </ReferenceArrayField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export const ChecklistEdit = (props) => (
  <Edit {...props} undoable={false}>
    <TabbedForm>
      <FormTab label="Checklist">
        <TextInput source="name" />
        <TextInput source="description" />
        <TextInput source="type" />
        <ReferenceInput label="Badge" source="Badge_id" reference="Badges" filter={{ Clan_id: props.clan.id }}>
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
      </FormTab>
      <FormTab label="tasks" path="tasks">
        <ReferenceArrayInput source="Task_id" reference="Tasks" allowEmpty filter={{ Clan_id: props.clan.id }}>
          <AutocompleteArrayInput
            optionText="name"
            allowEmpty
            emptyText="-- none --"
          />
        </ReferenceArrayInput>
      </FormTab>
    </TabbedForm>
  </Edit>
);

export const ChecklistCreate = (props) => (
  <Create {...props}>
    <TabbedForm>
      <FormTab label="Checklist">
        <TextInput source="name" />
        <TextInput source="description" />
        <TextInput source="type" />
        <ReferenceInput label="Badge" source="Badge_id" reference="Badges" filter={{ Clan_id: props.clan.id }}>
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <NumberInput source="Clan_id" initialValue={props.clan.id} disabled />
      </FormTab>
    </TabbedForm>
  </Create>
);
