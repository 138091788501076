import React from "react";
import {
  Filter,
  Pagination,
  TabbedShowLayout,
  Tab,
  TabbedForm,
  AutocompleteArrayInput,
  FormTab,
  ReferenceArrayInput,
  ReferenceArrayField,
  List,
  Show,
  Create,
  Datagrid,
  TextField,
  TextInput,
  Edit,
  NumberInput,
} from "react-admin";

const BigPagination = (props) => (
  <Pagination rowsPerPageOptions={[25, 50, 100, 2000]} {...props} />
);
const SearchFilter = (props) => (
  <Filter {...props}>
    <TextInput source="name" alwaysOn />
  </Filter>
);

export const BadgeList = (props) => (
  <List
    {...props}
    pagination={<BigPagination />}
    perPage={25}
    filters={<SearchFilter {...props} />}
    filter={{ Clan_id: props.clan.id }}
  >
    <Datagrid rowClick="show">
      <TextField source="name" />
      <TextField source="description" />
      <TextField source="icon" />
    </Datagrid>
  </List>
);

export const BadgeShow = (props) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="Badge">
        <TextField source="name" />
        <TextField source="description" />
        <TextField source="icon" />
      </Tab>
      <Tab label="members" path="members">
        <ReferenceArrayField source="members" reference="members">
          <Datagrid>
            <TextField source="username" />
          </Datagrid>
        </ReferenceArrayField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export const BadgeEdit = (props) => (
  <Edit {...props} undoable={false}>
    <TabbedForm>
      <FormTab label="Badge">
        <TextInput source="name" />
        <TextInput source="description" />
        <TextInput source="icon" />
      </FormTab>
      <FormTab label="members" path="members">
        <ReferenceArrayInput source="members" reference="members" allowEmpty filter={{ Clan_id: props.clan.id }}>
          <AutocompleteArrayInput
            optionText="username"
            allowEmpty
            emptyText="-- none --"
          />
        </ReferenceArrayInput>
      </FormTab>
    </TabbedForm>
  </Edit>
);

export const BadgeCreate = (props) => (
  <Create {...props}>
    <TabbedForm>
      <FormTab label="Badge">
        <TextInput source="name" />
        <TextInput source="description" />
        <TextInput source="icon" />
        <NumberInput source="Clan_id" initialValue={props.clan.id} disabled />
      </FormTab>
    </TabbedForm>
  </Create>
);
