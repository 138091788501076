import React, { useState, createContext, useEffect, useContext } from "react";
import API from "../helpers/api";
import { UserContext } from "./userContext";
import { TaskCompleted } from "../models";

const ChecklistContext = createContext({
  tasksFetched: false as boolean,
  memberTasks: [] as Array<TaskCompleted>,
  fetchTasksCompleted: () => { },
  createTask: (task: TaskCompleted) => { },
});

const ChecklistContextConsumer = ChecklistContext.Consumer;

interface ChecklistContextProviderProps {
  children: JSX.Element;
}

const ChecklistContextProvider = (
  props: ChecklistContextProviderProps
): JSX.Element => {
  const { user, userFetched } = useContext(UserContext);
  const { children } = props;
  const [memberTasks, setMemberTasks] = React.useState([]);
  const [tasksFetched, setTasksFetched] = useState(false);
  const createTask = async (task: TaskCompleted) =>
    await API.create("TaskCompleted", task);
  const fetchTasksCompleted = async () => {
    setMemberTasks(
      (await API.getAll("TaskCompleted", {
        Member_id: user.id,
        _start: 0,
        _end: 100,
      })) || []
    );
  };

  useEffect(() => {
    async function fetchMemberTasks() {
      try {
        setMemberTasks(
          (await API.getAll("TaskCompleted", {
            Member_id: user.id,
            _start: 0,
            _end: 100,
          })) || []
        );
      } catch {
        setMemberTasks([]);
      }
    }

    if (userFetched && user && user.id && !tasksFetched) {
      fetchMemberTasks().then(() => {
        setTasksFetched(true)
      });
    }
  }, [user, userFetched, tasksFetched]);

  return (
    <ChecklistContext.Provider
      value={{ tasksFetched, memberTasks, fetchTasksCompleted, createTask }}
    >
      {children}
    </ChecklistContext.Provider>
  );
};

export { ChecklistContextProvider, ChecklistContextConsumer, ChecklistContext };
