import React, { useContext } from "react";
import VerticalTabs from "../VerticalTabs/VerticalTabs";
import Hiscores from "./Hiscores";
import { SubmissionContext } from "../../contexts/submissionContext";

const Killcount = ({ updateImage }) => {
  const { bosses } = useContext(SubmissionContext);

  const tabs = bosses.map((boss) => ({
    label: boss.name,
    onClick: () => updateImage(boss.artwork),
    component: (
      <Hiscores
        key={boss.id}
        label={boss.name}
        valueLabel='Kills'
        queryModel='killcounts'
        query={{ Boss_id: boss.id }}
        valueAttribute={(x) => x.value}
        usernameAttribute={(x) => x.member && x.member.username}
      />
    ),
  }));

  tabs.unshift({
    label: "Overall",
    component: (
      <Hiscores
        key='overall'
        label='Overall'
        valueLabel='Kills'
        queryModel='killcounts'
        overall
        valueAttribute={(x) => x.sum}
        usernameAttribute={(x) => x.member && x.member.username}
      />
    ),
  });

  return <VerticalTabs tabs={tabs} />;
};

export default Killcount;
