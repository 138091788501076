import React, { FunctionComponent, useContext } from "react";
import { LookupContext } from "../../contexts/lookupContext";
import Grid from "@material-ui/core/Grid";
import BanMeter from "./BanMeter";
import PointWheel from "./PointWheel";
import DropLog from "./DropLog";
import PetBar from "./PetBar";
import EventsBar from "./EventsBar";
import BossCard from "./BossCard";
import SkillCard from "./SkillCard";
import NameBar from "./NameBar";
import StatusCard from "./StatusCard";
import RankCard from "./RankCard";
import MemberTimeCard from "./MemberTimeCard";
import ValueSplitCard from "./ValueSplitCard";
import ValueTankedCard from "./ValueTankedCard";
import PointsBreakdown from "./PointsBreakdown";
import CollectionLogCard from "./CollectionLogCard";
import AccoladeBadgeBar from "./AccoladeBadgeBar";
import DiaryBadgeBar from "./DiaryBadgeBar";
import LoadingCard from "../Loading/LoadingCard";
import { Rank } from "../../models";
import { ClanContext } from "../../contexts/clanContext";
import { Typography } from "@material-ui/core";
import CenteredGrid from "../../components/Shared/CenteredGrid";

interface LookupContainerProps {
  setBackground: (val: string) => {};
}

const LookupContainer: FunctionComponent<LookupContainerProps> = ({
  setBackground,
}) => {
  const {
    member,
    main,
    alts,
    memberBadges,
    loading,
    drops,
    tanks,
    killcounts,
    experiences,
    unlockedPets,
    events,
    ranks,
    points,
  } = useContext(LookupContext);
  const { clan } = useContext(ClanContext);
  return (
    <>
      <Grid container justify='center' spacing={3}>
        {loading ? (
          <Grid item xs={12}>
            <LoadingCard />
          </Grid>
        ) : (
            <>
              {member.id && member.Clan_id === clan.id ? (
                <>
                  <Grid item xs={12}>
                    <NameBar member={member} main={main} alts={alts} />
                  </Grid>

                  <AccoladeBadgeBar memberBadges={memberBadges} />

                  <DiaryBadgeBar memberBadges={memberBadges} />

                  <Grid item xs={12} sm={6} md={4}>
                    <StatusCard member={member} />
                  </Grid>
                  {member.Rank_id && (
                    <Grid item xs={12} sm={6} md={4}>
                      <RankCard
                        rank={
                          ranks.find((rank) => rank.id === member.Rank_id) ||
                          ({} as Rank)
                        }
                      />
                    </Grid>
                  )}

                  <Grid item xs={12} sm={6} md={4}>
                    <MemberTimeCard member={member} />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <BanMeter member={member} />
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <ValueSplitCard member={member} />
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <ValueTankedCard member={member} />
                  </Grid>

                  {killcounts?.length > 0 && (
                    <Grid item xs={12} sm={12} md={12}>
                      <BossCard
                        alts={alts}
                        member={member}
                        setBackground={setBackground}
                        killcounts={killcounts}
                      />
                    </Grid>
                  )}

                  <Grid item xs={12} sm={6} md={4}>
                    <PointsBreakdown member={member} />
                  </Grid>

                  <Grid item xs={12} sm={10} md={8}>
                    <PointWheel member={member} points={points} />
                  </Grid>

                  {experiences?.length > 0 && (
                    <Grid item xs={12} sm={12} md={12}>
                      <SkillCard member={member} experiences={experiences} />
                    </Grid>
                  )}

                  <PetBar unlockedPets={unlockedPets} />

                  {drops?.length > 0 && (
                    <Grid item xs={12} sm={12} md={12}>
                      <DropLog drops={drops} />
                    </Grid>
                  )}

                  <EventsBar events={events} />

                  {drops?.length > 0 && (
                    <Grid item xs={12} sm={12} md={12}>
                      <CollectionLogCard
                        drops={drops.filter((drop) => drop.attack === member.id)}
                        tanks={tanks}
                      />
                    </Grid>
                  )}
                </>
              ) : (
                  <>
                    <CenteredGrid item sm={12} md={12}>
                      <Typography variant='h1'>Not found</Typography>
                    </CenteredGrid>
                  </>
                )}
            </>
          )}
      </Grid>
      {!loading && member.id ? (
        alts.map((alt) => (
          <div key={alt.id}>
            <Grid container justify='center' spacing={3}>
              <Grid item xs={12}>
                <NameBar member={alt} />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <StatusCard member={alt} />
              </Grid>
              {alt.Rank_id && (
                <Grid item xs={12} sm={6} md={4}>
                  <RankCard
                    rank={
                      ranks.find((rank) => rank.id === alt.Rank_id) ||
                      ({} as Rank)
                    }
                  />
                </Grid>
              )}

              <Grid item xs={12} sm={6} md={4}>
                <MemberTimeCard member={alt} />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <ValueSplitCard member={alt} />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <ValueTankedCard member={alt} />
              </Grid>

              {killcounts.filter((killcount) => killcount.Member_id === alt.id)
                .length > 0 && (
                  <Grid item xs={12} sm={12} md={12}>
                    <BossCard
                      alts={alts}
                      member={alt}
                      killcounts={killcounts.filter(
                        (killcount) => killcount.Member_id === alt.id
                      )}
                    />
                  </Grid>
                )}
              <Grid item xs={12} sm={6} md={4}>
                <PointsBreakdown member={alt} />
              </Grid>
              <Grid item xs={12} sm={10} md={8}>
                <PointWheel member={alt} points={points} />
              </Grid>
            </Grid>
          </div>
        ))
      ) : (
          <> </>
        )}
    </>
  );
};

export default LookupContainer;
