import React, { useContext } from "react";
import VerticalTabs from "../VerticalTabs/VerticalTabs";
import Hiscores from "./Hiscores";
import { SubmissionContext } from "../../contexts/submissionContext";

const Points = () => {
  const { pointCategories } = useContext(SubmissionContext);

  const tabs = pointCategories.map((pointCategory) => ({
    label: pointCategory.name,
    component: (
      <Hiscores
        points
        key={pointCategory.id}
        label={pointCategory.name}
        valueLabel='Points'
        query={{ PointCategory_id: pointCategory.id }}
        valueAttribute={(x) => x.value}
        usernameAttribute={(x) => x.member && x.member.username}
      />
    ),
  }));

  tabs.unshift({
    label: "Community",
    component: (
      <Hiscores
        points
        key='community'
        label='Community'
        valueLabel='Points'
        community
        valueAttribute={(x) => x.sum}
        usernameAttribute={(x) => x.member && x.member.username}
      />
    ),
  });

  tabs.unshift({
    label: "Overall",
    component: (
      <Hiscores
        points
        key='overall'
        label='Overall'
        valueLabel='Points'
        overall
        valueAttribute={(x) => x.sum}
        usernameAttribute={(x) => x.member && x.member.username}
      />
    ),
  });

  tabs.push({
    label: "Pets",
    component: (
      <Hiscores
        key='pets'
        valueLabel='Pets'
        pets
        valueAttribute={(x) => x.count}
        usernameAttribute={(x) => x.member && x.member.username}
      />
    ),
  });

  tabs.push({
    label: "Diaries",
    component: (
      <Hiscores
        key='diaries'
        valueLabel='Diaries'
        diaries
        valueAttribute={(x) => x.count}
        usernameAttribute={(x) => x.member && x.member.username}
      />
    ),
  });

  return <VerticalTabs tabs={tabs} />;
};

export default Points;
