import React, { useContext, FunctionComponent } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../contexts/userContext";
import { ChecklistContext } from "../../contexts/checklistContext";
import { Checklist, Task, MentorProgram } from "../../models";
import { CardMedia } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import { SubmissionContext } from "../../contexts/submissionContext";

interface MentoringProps {
  mentorProgram: MentorProgram;
}

const Mentoring: FunctionComponent<MentoringProps> = ({ mentorProgram }) => {
  const { memberTasks, createTask, fetchTasksCompleted } = useContext(ChecklistContext);
  const { checklists } = useContext(SubmissionContext);
  const programChecklist =
    checklists.find(
      (checklist) => checklist.id === mentorProgram.Checklist_id
    ) || ({} as Checklist);
  const completeTasks =
    programChecklist.tasks
      ?.filter((task) =>
        memberTasks.find((t) => t.approved && t.Task_id === task.id)
      )
      .sort((a, b) => (a.id || 0) - (b.id || 0)) || ([] as Array<Task>);
  const incompleteTasks =
    programChecklist.tasks
      ?.filter((task) => !memberTasks.find((t) => t.Task_id === task.id))
      .sort((a, b) => (a.id || 0) - (b.id || 0)) || ([] as Array<Task>);
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const useStyles = makeStyles(() => ({
    media: {
      height: 300,
      backgroundSize: "cover",
      margin: 0,
    },
    halfWidth: {
      width: "50%",
    },
  }));

  const classes = useStyles();

  const submitTask = async (
    e: React.KeyboardEvent<HTMLInputElement>,
    task: Task
  ) => {
    if (e.key === "Enter") {
      await createTask({
        Task_id: task.id,
        Member_id: user.id,
        Clan_id: user.Clan_id,
        date: new Date(),
        screenshot: (e.target as HTMLInputElement).value,
      });
      fetchTasksCompleted();
    }
  };
  const [artwork, setArtwork] = React.useState("");

  const handleClickOpen = (artwork: string) => {
    setArtwork(artwork);
  };
  const handleClose = () => {
    setArtwork("");
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="h5" gutterBottom>
              {programChecklist.name}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="body1" gutterBottom>
              {programChecklist.description}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <LinearProgress
              variant="determinate"
              value={
                (completeTasks.length /
                  ((programChecklist.tasks &&
                    programChecklist.tasks.length) ||
                    1)) *
                100
              }
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="h6" gutterBottom>
              {t("mentorLogger.mentoring.incomplete")}
            </Typography>
          </Grid>

          <Grid container spacing={3}>
            {incompleteTasks.map((task: Task) => (
              <Grid key={task.id} item xs={12} sm={12} md={12}>
                <ExpansionPanel>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>{task.name}</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Grid container justify="center">
                      <Grid item xs={12} sm={12} md={12}>
                        <Table
                          aria-label="Task list"
                          style={{
                            width: "100%",
                          }}
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                {t(
                                  "mentorLogger.mentoring.descriptionHeader"
                                )}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow key={task.description}>
                              <TableCell className={classes.halfWidth}>
                                <Grid container spacing={3}>
                                  <>
                                    {task.artwork ? (
                                      <>
                                        <Grid item sm={12} md={6}>
                                          <Typography
                                            style={{ whiteSpace: "pre-line" }}
                                          >
                                            {task.description}
                                          </Typography>
                                        </Grid>
                                        <Grid item sm={12} md={6} style={{ width: '100%' }}>
                                          {task.artwork.includes(
                                            "streamable"
                                          ) ? (
                                              <CardMedia
                                                className={classes.media}
                                                src={task.artwork}
                                                allowFullScreen
                                                component="iframe"
                                                frameBorder="0"
                                                width="100%"
                                                height="300px"
                                              />
                                            ) : (
                                              <CardMedia
                                                className={classes.media}
                                                image={task.artwork}
                                                onClick={() => handleClickOpen(task.artwork || "")}
                                              />
                                            )}
                                        </Grid>
                                      </>
                                    ) : (
                                        <Grid item xs={12} sm={12} md={12}>
                                          <Typography
                                            style={{ whiteSpace: "pre-line" }}
                                          >
                                            {task.description}
                                          </Typography>
                                        </Grid>
                                      )}
                                    {task.screenshot && (
                                      <Grid item xs={12} sm={12} md={12}>
                                        <TextField
                                          id="skills"
                                          label={t("pets.submitLabel")}
                                          fullWidth
                                          onKeyDown={(
                                            e: React.KeyboardEvent<any>
                                          ) => submitTask(e, task)}
                                          type="text"
                                          helperText={t("pets.helperText")}
                                        />
                                      </Grid>
                                    )}
                                  </>
                                </Grid>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Grid>
                    </Grid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="h6" gutterBottom>
              {t("mentorLogger.mentoring.complete")}
            </Typography>
          </Grid>

          <Grid container spacing={3}>
            {completeTasks.map((task: Task) => (
              <Grid key={task.id} item xs={12} sm={12} md={12}>
                <ExpansionPanel>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>{task.name}</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Grid container justify="center">
                      <Grid item xs={12} sm={12} md={12}>
                        <Table
                          aria-label="Task list"
                          style={{
                            width: "100%",
                          }}
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                {t(
                                  "mentorLogger.mentoring.descriptionHeader"
                                )}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow key={task.description}>
                              <TableCell className={classes.halfWidth}>
                                <Grid container spacing={3}>
                                  <>
                                    {task.artwork ? (
                                      <>
                                        <Grid item sm={12} md={6}>
                                          <Typography
                                            style={{ whiteSpace: "pre-line" }}
                                          >
                                            {task.description}
                                          </Typography>
                                        </Grid>
                                        <Grid item sm={12} md={6} style={{ width: '100%' }}>
                                          {task.artwork.includes(
                                            "streamable"
                                          ) ? (
                                              <CardMedia
                                                className={classes.media}
                                                src={task.artwork}
                                                allowFullScreen
                                                component="iframe"
                                                frameBorder="0"
                                                width="100%"
                                                height="300px"
                                              />
                                            ) : (
                                              <CardMedia
                                                className={classes.media}
                                                image={task.artwork}
                                                onClick={() => handleClickOpen(task.artwork || "")}
                                              />
                                            )}
                                        </Grid>
                                      </>
                                    ) : (
                                        <Grid item xs={12} sm={12} md={12}>
                                          <Typography
                                            style={{ whiteSpace: "pre-line" }}
                                          >
                                            {task.description}
                                          </Typography>
                                        </Grid>
                                      )}
                                  </>
                                </Grid>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Grid>
                    </Grid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Dialog maxWidth="lg" onClose={handleClose} open={artwork !== ""}>
        <img src={artwork} alt="Overlay" />
      </Dialog>
    </Grid>
  );
};

export default Mentoring;
