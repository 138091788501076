import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Card from "@material-ui/core/Card";
import { CardContent } from "@material-ui/core";
import styles from "../../assets/jss/components/customTabsStyle";
import { ClanContext } from "../../contexts/clanContext";

export default function CustomTabs(props) {
  const { clan } = useContext(ClanContext);
  const [value, setValue] = React.useState(0);
  const useStyles = makeStyles(styles);
  const handleChange = (event, value) => {
    setValue(value);
    if (props.onClick) {
      props.onClick(value);
    }
  };
  const classes = useStyles();
  const { tabs } = props;

  return (
    <Card>
      <div color="primary" className={classes.cardHeader} style={{ background: clan.clanColorGradient }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs"
          classes={{
            root: classes.tabsRoot,
            indicator: classes.displayNone,
          }}
        >
          {tabs.map((prop, key) => {
            let icon = {};
            if (prop.tabIcon) {
              icon = {
                icon:
                  typeof prop.tabIcon === "string" ? (
                    <div
                      style={{
                        backgroundImage: `url('${prop.tabIcon}')`,
                      }}
                      src={prop.tabIcon}
                      className={classes.customBadge}
                    />
                  ) : (
                      <prop.tabIcon />
                    ),
              };
            }
            return (
              <Tab
                classes={{
                  root: classes.tabRootButton,
                  label: classes.tabLabel,
                  selected: classes.tabSelected,
                  wrapper: classes.tabWrapper,
                }}
                key={key}
                label={prop.tabName}
                {...icon}
              />
            );
          })}
        </Tabs>
      </div>
      <CardContent className={classes.cardcontent}>
        {tabs.map((prop, key) => {
          if (key === value) {
            return <div key={key}>{prop.tabContent}</div>;
          }
          return null;
        })}
      </CardContent>
    </Card>
  );
}
