import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@material-ui/styles";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import Contexts from "./contexts";
import Routes from "./routes";
import { ThemeContextConsumer } from "./contexts/themeContext";
import "./i18next";
import "./assets/css/global.css";
import '../node_modules/react-vis/dist/style.css';


ReactDOM.render(
  <React.StrictMode>
    <React.Suspense fallback={<div />}>
      <Router history={createBrowserHistory()}>
        <Contexts>
          <ThemeContextConsumer>
            {({ theme }) => (
              <ThemeProvider theme={theme}>
                <Routes />
              </ThemeProvider>
            )}
          </ThemeContextConsumer>
        </Contexts>
      </Router>
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);
