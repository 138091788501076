import React, { useContext, FunctionComponent, useEffect } from "react";
import { withRouter } from "react-router";
import Grid from "@material-ui/core/Grid";
import RankBlock from "../../components/Requirements/Rank";
import CustomTabs from "../../components/Requirements/CustomTabs";
import Layout from "../../components/Layout/Layout";
import { ClanContext } from "../../contexts/clanContext";
import { SubmissionContext } from "../../contexts/submissionContext";

const Lookup: FunctionComponent = () => {
  const { clan } = useContext(ClanContext);
  const { requirements, ranksFetched, fetchRanks } = useContext(SubmissionContext);

  useEffect(() => {
    if (clan && clan.id && !ranksFetched) {
      fetchRanks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ranksFetched]);
  return (
    <Layout loading={!ranksFetched}>
      <Grid container justify="center">
        <Grid item xs={12} sm={12} md={12}>
          <CustomTabs
            tabs={requirements
              .map((rank, index) => ({
                tabName: rank.name,
                tabIcon: rank.artwork,
                tabContent: <RankBlock rank={rank} />,
              }))}
          />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default withRouter(Lookup);
