import React, {
  useContext,
  useState,
  useEffect,
  FunctionComponent,
} from "react";
import { RouteComponentProps } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ClanContext } from "../../contexts/clanContext";
import { SubmissionContext } from "../../contexts/submissionContext";
import { LookupContextProvider } from "../../contexts/lookupContext";
import Layout from "../../components/Layout/Layout";
import LookupContainer from "../../components/Lookup/LookupContainer";
import { withRouter } from "react-router";

interface MatchParams {
  username?: string;
}

interface LookupProps extends RouteComponentProps<MatchParams> { }

const Lookup: FunctionComponent<LookupProps> = ({ match }) => {
  const { clan, } = useContext(ClanContext);
  const {
    bossesFetched,
    fetchAllBosses,
    skillsFetched,
    fetchSkills,
    pointCategoriesFetched,
    fetchPointCategories
  } = useContext(SubmissionContext);
  const { t } = useTranslation();
  const welcomeText = t("lookup.welcomeText");
  const [background, setBackground] = useState(clan.clanBackgroundImage);

  useEffect(() => {
    if (clan && clan.id && !bossesFetched && !skillsFetched && !pointCategoriesFetched) {
      fetchAllBosses();
      fetchSkills();
      fetchPointCategories();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bossesFetched, skillsFetched, pointCategoriesFetched]);

  return (
    <Layout
      loading={!bossesFetched && !skillsFetched && !pointCategoriesFetched}
      title={t("lookup.title")}
      background={background}
      helperText={welcomeText}
    >
      <LookupContextProvider username={match.params.username}>
        <LookupContainer setBackground={setBackground} />
      </LookupContextProvider>
    </Layout>
  );
};

export default withRouter(Lookup);
