import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "./ListItem";
import List from "@material-ui/core/List";
import styles from "../../assets/jss/components/headerStyle";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../contexts/userContext";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ListItemDefault from "@material-ui/core/ListItem";
import ListIcon from "@material-ui/icons/List";
import DashboardIcon from "@material-ui/icons/Dashboard";
import { useRouteMatch } from "react-router-dom";

export default function Admin({ baseUrl }) {
  const { user } = useContext(UserContext);
  const route = useRouteMatch("/:clanSlug/admin/");
  const [open, setOpen] = React.useState(!!route);
  const [mode, setMode] = React.useState('manage');

  const handleClick = () => {
    setOpen(!open);
  };
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <List className={classes.list}>
      <ListItemDefault button onClick={handleClick}>
        <ListItemText primary={t("header.sidebar.admin")} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemDefault>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List >
          <ListItemDefault button onClick={() => mode !== 'manage' ? setMode('manage') : setMode('')}>
            <ListItemText primary={t("header.sidebar.adminManage")} />
            {mode === 'manage' ? <ExpandLess /> : <ExpandMore />}
          </ListItemDefault>
          <Collapse in={mode === 'manage'} timeout='auto' unmountOnExit>
            <List component='div' disablePadding>
              {user.isAdmin || user.isClanAdmin ? (
                <>
                  <ListItem
                    to={`${baseUrl}/admin/`}
                    text={"Dashboard"}
                    Icon={DashboardIcon}
                  />
                  <ListItem
                    to={`${baseUrl}/admin/drops`}
                    text={"Drops"}
                    Icon={ListIcon}
                  />
                  <ListItem
                    to={`${baseUrl}/admin/members`}
                    text={"Members"}
                    Icon={ListIcon}
                  />
                  <ListItem
                    to={`${baseUrl}/admin/events`}
                    text={"Events"}
                    Icon={ListIcon}
                  />
                  <ListItem
                    to={`${baseUrl}/admin/times`}
                    text={"Times"}
                    Icon={ListIcon}
                  />
                  <ListItem
                    to={`${baseUrl}/admin/mentors`}
                    text={"Teaching Points"}
                    Icon={ListIcon}
                  />
                  <ListItem
                    to={`${baseUrl}/admin/TaskCompleted`}
                    text={"Task Completed"}
                    Icon={ListIcon}
                  />
                  <ListItem
                    to={`${baseUrl}/admin/responses`}
                    text={"Application Responses"}
                    Icon={ListIcon}
                  />
                  <ListItem
                    to={`${baseUrl}/admin/strikes`}
                    text={"Strikes"}
                    Icon={ListIcon}
                  />
                  <ListItem
                    to={`${baseUrl}/admin/donations`}
                    text={"Donations"}
                    Icon={ListIcon}
                  />
                  <ListItem
                    to={`${baseUrl}/admin/points`}
                    text={"Points"}
                    Icon={ListIcon}
                  />
                  <ListItem
                    to={`${baseUrl}/admin/PointTransactions`}
                    text={"Point Transactions"}
                    Icon={ListIcon}
                  />
                  <ListItem
                    to={`${baseUrl}/admin/MentorPrograms`}
                    text={"Mentor Programs"}
                    Icon={ListIcon}
                  />
                  <ListItem
                    to={`${baseUrl}/admin/teachers`}
                    text={"Teachers"}
                    Icon={ListIcon}
                  />
                  <ListItem
                    to={`${baseUrl}/admin/students`}
                    text={"Students"}
                    Icon={ListIcon}
                  />
                  <ListItem
                    to={`${baseUrl}/admin/suggestions`}
                    text={"Suggestions"}
                    Icon={ListIcon}
                  />
                </>
              ) : user.isEventStaff && user.teacherPrograms.length > 0 ? (
                <>
                  <ListItem
                    to={`${baseUrl}/admin/events`}
                    text={"Events"}
                    Icon={ListIcon}
                  />
                  <ListItem
                    to={`${baseUrl}/admin/MentorPrograms`}
                    text={"Mentor Programs"}
                    Icon={ListIcon}
                  />
                  <ListItem
                    to={`${baseUrl}/admin/students`}
                    text={"Students"}
                    Icon={ListIcon}
                  />
                </>
              ) : user.isEventStaff ? (
                <>
                  <ListItem
                    to={`${baseUrl}/admin/events`}
                    text={"Events"}
                    Icon={ListIcon}
                  />
                </>
              ) : (
                      user.teacherPrograms.length > 0 && (
                        <>
                          <ListItem
                            to={`${baseUrl}/admin/MentorPrograms`}
                            text={"Mentor Programs"}
                            Icon={ListIcon}
                          />
                          <ListItem
                            to={`${baseUrl}/admin/students`}
                            text={"Students"}
                            Icon={ListIcon}
                          />
                        </>
                      )
                    )}
            </List>
          </Collapse>
          <ListItemDefault button onClick={() => mode !== 'data' ? setMode('data') : setMode('')}>
            <ListItemText primary={t("header.sidebar.adminData")} />
            {mode === 'data' ? <ExpandLess /> : <ExpandMore />}
          </ListItemDefault>
          <Collapse in={mode === 'data'} timeout='auto' unmountOnExit>
            <List component='div' disablePadding>
              {(user.isAdmin || user.isClanAdmin) && (
                <>
                  <ListItem
                    to={`${baseUrl}/admin/bosses`}
                    text={"Bosses"}
                    Icon={ListIcon}
                  />
                  <ListItem
                    to={`${baseUrl}/admin/items`}
                    text={"Items"}
                    Icon={ListIcon}
                  />
                  <ListItem
                    to={`${baseUrl}/admin/records`}
                    text={"Time Categories"}
                    Icon={ListIcon}
                  />
                  <ListItem
                    to={`${baseUrl}/admin/ranks`}
                    text={"Ranks"}
                    Icon={ListIcon}
                  />
                  <ListItem
                    to={`${baseUrl}/admin/scrapes`}
                    text={"Scrapes"}
                    Icon={ListIcon}
                  />
                  <ListItem
                    to={`${baseUrl}/admin/bumps`}
                    text={"Bumps"}
                    Icon={ListIcon}
                  />
                  <ListItem
                    to={`${baseUrl}/admin/badges`}
                    text={"Badges"}
                    Icon={ListIcon}
                  />
                  <ListItem
                    to={`${baseUrl}/admin/checklists`}
                    text={"Checklists"}
                    Icon={ListIcon}
                  />
                  <ListItem
                    to={`${baseUrl}/admin/tasks`}
                    text={"Tasks"}
                    Icon={ListIcon}
                  />
                  <ListItem
                    to={`${baseUrl}/admin/questions`}
                    text={"Application Questions"}
                    Icon={ListIcon}
                  />
                  <ListItem
                    to={`${baseUrl}/admin/PointCategories`}
                    text={"Point Categories"}
                    Icon={ListIcon}
                  />
                  <ListItem
                    to={`${baseUrl}/admin/languages`}
                    text={"Languages"}
                    Icon={ListIcon}
                  />
                  <ListItem
                    to={`${baseUrl}/admin/clans`}
                    text={"Clans"}
                    Icon={ListIcon}
                  />
                  <ListItem
                    to={`${baseUrl}/admin/audits`}
                    text={"Audits"}
                    Icon={ListIcon}
                  />
                </>
              )}
            </List>
          </Collapse>
        </List>
      </Collapse>
    </List>
  );
}
