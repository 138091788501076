import React, { FunctionComponent } from "react";
import Card from "@material-ui/core/Card";
import { CardContent } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { Member } from "../../models";

interface PointsBreakdownProps {
  member: Member;
}

const PointsBreakdown: FunctionComponent<PointsBreakdownProps> = ({
  member,
}) => {
  const { t } = useTranslation();
  return (
    <Grid container justify="center" direction="column" spacing={3}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container>
              <Grid item xs={12}>
                <Typography>{t("lookup.pointsBreakdown.total")}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" component="h2">
                  {member.overallPoints}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container>
              <Grid item xs={12}>
                <Typography>{t("lookup.pointsBreakdown.pvm")}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" component="h2">
                  {member.pvmPoints}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container>
              <Grid item xs={12}>
                <Typography>{t("lookup.pointsBreakdown.community")}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" component="h2">
                  {member.nonPvmPoints}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default PointsBreakdown;
