import React from "react";
import {
  List,
  Filter,
  Pagination,
  Show,
  Create,
  Datagrid,
  TextField,
  TextInput,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
  Edit,
  SimpleForm,
  NumberInput,
  SimpleShowLayout,
} from "react-admin";

const BigPagination = (props) => (
  <Pagination rowsPerPageOptions={[25, 50, 100, 2000]} {...props} />
);

const SearchFilter = (props) => (
  <Filter {...props}>
    <TextInput source='record' alwaysOn />
    <ReferenceInput
      label='Boss'
      source='Boss_id'
      reference='bosses'
      filter={{ Clan_id: props.clan.id }}
      allowEmpty
      alwaysOn
    >
      <AutocompleteInput optionText='name' allowEmpty emptyText='-- none --' />
    </ReferenceInput>
  </Filter>
);

export const RecordList = (props) => (
  <List
    {...props}
    pagination={<BigPagination />}
    perPage={25}
    filters={<SearchFilter {...props} />}
    filter={{ Clan_id: props.clan.id }}
  >
    <Datagrid rowClick='show'>
      <TextField source='record' />
      <ReferenceField label='Boss' source='Boss_id' reference='bosses'>
        <TextField source='name' />
      </ReferenceField>
    </Datagrid>
  </List>
);

export const RecordShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source='record' />
      <ReferenceField label='Boss' source='Boss_id' reference='bosses'>
        <TextField source='name' />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
);

export const RecordEdit = (props) => (
  <Edit {...props} undoable={false}>
    <SimpleForm>
      <TextInput source='record' />
      <ReferenceInput
        label='Boss'
        source='Boss_id'
        reference='bosses'
        filter={{ Clan_id: props.clan.id }}
      >
        <AutocompleteInput optionText='name' />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

export const RecordCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source='record' />
      <ReferenceInput
        label='Boss'
        source='Boss_id'
        reference='bosses'
        filter={{ Clan_id: props.clan.id }}
      >
        <AutocompleteInput optionText='name' />
      </ReferenceInput>
      <NumberInput source='Clan_id' initialValue={props.clan.id} disabled />
    </SimpleForm>
  </Create>
);
