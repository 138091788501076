import React from "react";
import NumberFormat from "react-number-format";

export default function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      suffix=" M"
      decimalScale={2}
      isAllowed={(values) => {
        const { floatValue } = values;
        return floatValue <= 10000;
      }}
    />
  );
}
