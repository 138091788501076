import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import styles from "../../assets/jss/components/headerStyle";

export default function Header({ to, Icon, text, onClick, target }) {
    const useStyles = makeStyles(styles);
    const classes = useStyles();

    return (
        <ListItem dense>
            <Link
                to={to}
                onClick={onClick}
                target={target}
                className={`${classes.navLink} ${classes.userLink}`}
            >
                <ListItemIcon>
                    <Icon />
                </ListItemIcon>
                <ListItemText
                    primaryTypographyProps={{ variant: "button" }}
                    primary={text}
                />
            </Link>
        </ListItem>
    )

}
