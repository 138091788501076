// import React, { useContext } from "react";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import Countdown from "react-countdown";
import { useTranslation } from 'react-i18next';
import Grid from "@material-ui/core/Grid";
// import Button from "@material-ui/core/Button";
// import { UserContext } from "../../contexts/userContext";
// import { SubmissionContext } from "../../contexts/submissionContext";

const useStyles = makeStyles((theme) => ({
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function RecipeReviewCard(props) {
  // const { user } = useContext(UserContext);
  // const { attendEvent } = useContext(SubmissionContext);
  const { t } = useTranslation();
  const classes = useStyles();
  const { event } = props;

  const date = new Date(event.date);

  const renderer = ({ days, hours, minutes, seconds }) => (
    <span>
      In {days} days and {hours}:{minutes}:{seconds} hours ({(days * 14400) + (hours * 600) + (minutes * 100) + (seconds * .6)} ticks)
    </span>
  );

  // const handleClick = () => {
  //   attendEvent(user.id, event.id)
  // }

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <Avatar
            aria-label="event"
            className={classes.avatar}
            src={event.screenshot}
          >
            {event.screenshot ? null : event.name.charAt(0).toUpperCase()}
          </Avatar>
        }
        title={event.name}
        subheader={`${date.toLocaleString("default", {
          month: "long",
        })}  ${date.getDate()}, ${date.getFullYear()}, ${date.getHours()}:${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()} `}
      // action={date > new Date() && !event.attendees.find(attendee => attendee.id === user.id) &&
      //   <Button variant="outlined" onClick={handleClick}>
      //     {t("events.eventCard.attend")}
      //   </Button>
      // }
      />
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            {date > new Date() && (
              <Typography
                variant="subtitle2"
                color="textSecondary"
                component="p"
                gutterBottom
              >
                <Countdown date={date} renderer={renderer} />
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary" component="p">
              {t('events.eventCard.hosted')}: <strong>{event.member.username}</strong>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary" component="p">
              {event.description}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
