import React, { useEffect, useContext, useState } from "react";
import Card from "@material-ui/core/Card";
import { CardContent } from "@material-ui/core";
import { PieChart } from "react-chartkick";
import { makeStyles } from "@material-ui/core/styles";
import LoadingCard from "../Loading/LoadingCard";
import { SubmissionContext } from "../../contexts/submissionContext";

export default function PointWheel({ record }) {
  const { fetchMember } = useContext(SubmissionContext)
  const [member, setMember] = useState(undefined)
  const useStyles = makeStyles(() => ({
    pieCard: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: "24px"
    },
  }));

  const classes = useStyles();
  useEffect(() => {
    const loadMember = async () => setMember(await fetchMember(record.username))
    if (member === undefined && record && record.username) {
      loadMember(record.username)
    }
  }, [member, record, fetchMember])

  return (
    <Card className={classes.pieCard}>
      <CardContent>
        {member ? (
          <PieChart
            data={[
              ["PVM points", member.pvmPoints],
              ["Bump points", member.bumpPoints],
              ["Recruit points", member.recruitPoints],
              ["Event points", member.eventPoints],
              ["Mentor points", member.mentorPoints],
              ["Helper points", member.helperPoints],
              ["Alt points", member.altPoints],
              ["Donation points", member.donationPoints],
            ]}
            width="350px"
            height="350px"
          />
        ) : (
            <LoadingCard />
          )}
      </CardContent>
    </Card>
  );
}
