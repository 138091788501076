import React, { FunctionComponent } from "react";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { CardContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Item } from "../../models";

interface ItemCardProps {
  item: Item;
}

const ItemCard: FunctionComponent<ItemCardProps> = ({ item }) => {
  const useStyles = makeStyles(() => ({
    cardcontent: {
      padding: "0.9375rem 1.875rem",
      flex: "1 1 auto",
      "&:last-child": {
        paddingBottom: "15px",
      },
    },
    imageCard: {
      width: "50px",
      height: "50px",
      backgroundImage: `url(${item.artwork})`,
      backgroundPosition: "center",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
    },
  }));
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container justify="center" spacing={2}>
      <Grid item xs={10} sm={10} md={10}>
        <Card>
          <CardContent className={classes.cardcontent}>
            <Grid container justify="center">
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                justify="center"
                alignItems="center"
                style={{ margin: "auto" }}
              >
                <div
                  className={classes.imageCard}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Typography variant="h5" component="h5">
                  {item.name}
                </Typography>
                <Typography variant="overline" component="p">
                  {t("items.itemCard.attack")}: {item.attack}
                </Typography>
                {item.tank && item.tank > 0 ? (
                  <Typography variant="overline" component="p">
                    {t("items.itemCard.tank")}: {item.tank}
                  </Typography>
                ) : null}
                {item.alt && item.alt > 0 ? (
                  <Typography variant="overline" component="p">
                    {t("items.itemCard.alt")}: {item.alt}
                  </Typography>
                ) : null}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ItemCard;
